import { AuthService } from "@sk/services";
import { EntitySearchInput, SelectInput } from "@sk/uis";
import { memo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

const states = AuthService.getLoggedInEmpWhStates().map((x) => ({
  label: x,
  value: x,
}));

const defaultState = AuthService.getDefaultWhState();

const prepareFilterParams = (type, filter) => {
  let params = {
    select: "name,category,brand",
  };

  const brandIds = filter?.brand?.map((x) => x.value?._id);
  const categoryIds = filter?.category?.map((x) => x.value?._id);

  if (type == "product") {
    params.filter = {
      $or: [],
    };
    if (brandIds?.length > 0) {
      params.filter.$or.push({ brand: { $in: brandIds } });
    }
    if (categoryIds?.length > 0) {
      params.filter.$or.push({ category: { $in: categoryIds } });
    }

    if (params.filter.$or.length == 0) {
      delete params.filter;
    }
  }
  return params;
};

const Filter = ({ callback }) => {
  const {
    control,
    getValues,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      state: defaultState,
      wh: AuthService.getDefaultWhId(),
      category: [],
      brand: [],
    },
  });

  const [warehouses, setWarehouses] = useState(() => {
    return AuthService.getWhOnState(defaultState).map((x) => ({
      label: x.name,
      value: x._id,
    }));
  });

  const onStateChange = (val) => {
    const whs = AuthService.getWhOnState(val).map((x) => ({
      label: x.name,
      value: x._id,
    }));
    const dWh = AuthService.getDefaultWhIdOnState(val);
    if (dWh) {
      setValue("wh", dWh);
    } else {
      setValue("wh", whs.length > 0 ? whs[0].value : "");
    }
    setWarehouses(whs);
    triggerCb();
  };

  const onWhChange = () => {
    triggerCb();
  };

  const triggerCb = () => {
    callback({ ...getValues() });
  };

  const onCategoryChange = (onChange) => {
    return (val) => {
      onChange(val);
      triggerCb();
    };
  };

  const onBrandChange = (onChange) => {
    return (val) => {
      onChange(val);
      triggerCb();
    };
  };

  return (
    <div className="row">
      <div className="col">
        <SelectInput
          label="State"
          name="state"
          register={register}
          error={errors?.state?.message}
          callback={onStateChange}
          options={states}
          gap={0}
        />
      </div>
      <div className="col">
        <SelectInput
          name="wh"
          register={register}
          label="Warehouse"
          error={errors?.wh?.message}
          callback={onWhChange}
          options={warehouses}
          gap={0}
        />
      </div>
      <div className="col">
        <Controller
          control={control}
          name="category"
          render={({ field: { onChange, value } }) => (
            <EntitySearchInput
              type="category"
              label="Category"
              callback={onCategoryChange(onChange)}
              value={value}
              placeholder="Search by category"
              uid="po-category"
              gap={0}
            />
          )}
        />
      </div>
      <div className="col">
        <Controller
          control={control}
          name="brand"
          render={({ field: { onChange, value } }) => (
            <EntitySearchInput
              type="brand"
              label="Brand"
              callback={onBrandChange(onChange)}
              value={value}
              placeholder="Search by brand"
              uid="po-brand"
              gap={0}
            />
          )}
        />
      </div>
      <div className="col">
        <Controller
          control={control}
          name="product"
          render={({ field: { onChange, value } }) => (
            <EntitySearchInput
              type="product"
              label="Product"
              callback={(val) => {
                onChange(val);
                triggerCb();
              }}
              value={value}
              placeholder="Search by product"
              uid="po-product"
              gap={0}
              filterParams={prepareFilterParams("product", getValues())}
            />
          )}
        />
      </div>
    </div>
  );
};

export default memo(Filter);
