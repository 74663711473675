import { ProtectedRoute } from "@sk/uis";
import { memo } from "react";
import { createHashRouter, RouterProvider } from "react-router-dom";

import Layout from "./pages/Layout";
import ListPo from "./pages/list/ListPo";

import GrnList from "./pages/grn/list/GrnList";
import GrnSelectPo from "./pages/grn/manage/selectePo/GrnSelectPo";
import GrnView from "./pages/grn/view/GrnView";
import ManagePoMain from "./pages/manage-po/main/ManagePoMain";
import ManagePoProduct from "./pages/manage-po/product/ManagePoProduct";
import ApprovePosPo from "./pages/pos/approve/ApprovePosPo";
import ListPosPo from "./pages/pos/list/ListPosPo";
import ViewPosPo from "./pages/pos/view/ViewPosPo";
import PoView from "./pages/view/PoView";
import GrnManage from "./pages/grn/manage/GrnManage";

const roles = {
  pos: ["ListThirdPartyPO", "ViewThirdpartyPO","ApproveThirdpartyPO"],
};

const router = createHashRouter([
  {
    path: "/purchase-order",
    element: <Layout />,
    children: [
      {
        path: "list",
        element: (
          <ProtectedRoute roles={["ListPO"]}>
            <ListPo />
          </ProtectedRoute>
        ),
      },
      {
        path: "view",
        element: (
          <ProtectedRoute roles={["ViewPO"]}>
            <PoView />
          </ProtectedRoute>
        ),
      },
      {
        path: "manage/main",
        element: (
          <ProtectedRoute roles={["EditPO", "AddPO"]}>
            <ManagePoMain />
          </ProtectedRoute>
        ),
      },
      {
        path: "manage/product",
        element: (
          <ProtectedRoute roles={["EditPO", "AddPO"]}>
            <ManagePoProduct />
          </ProtectedRoute>
        ),
      },
      {
        path: "pos/list",
        element: (
          <ProtectedRoute roles={roles.pos}>
            <ListPosPo />
          </ProtectedRoute>
        ),
      },
      {
        path: "pos/view",
        element: (
          <ProtectedRoute roles={roles.pos}>
            <ViewPosPo />
          </ProtectedRoute>
        ),
      },
      {
        path: "pos/approve",
        element: (
          <ProtectedRoute roles={roles.pos}>
            <ApprovePosPo />
          </ProtectedRoute>
        ),
      },
      {
        path: "grn/list",
        element: (
          <ProtectedRoute roles={["ViewGRN"]}>
            <GrnList />
          </ProtectedRoute>
        ),
      },
      {
        path: "grn/view",
        element: (
          <ProtectedRoute roles={["ViewGRN"]}>
            <GrnView />
          </ProtectedRoute>
        ),
      },
      {
        path: "grn/select-po",
        element: (
          <ProtectedRoute roles={["ViewGRN"]}>
            <GrnSelectPo />
          </ProtectedRoute>
        ),
      },
      {
        path: "grn/manage",
        element: (
          <ProtectedRoute roles={["ViewGRN"]}>
            <GrnManage />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default memo(Router);
