/* eslint-disable jsx-a11y/label-has-associated-control */
import { yupResolver } from "@hookform/resolvers/yup";
import { CommonService, WarehouseService } from "@sk/services";

import debounce from "lodash/debounce";

import { memo, useCallback, useEffect, useState } from "react";

import { Offcanvas } from "react-bootstrap";

import { Controller, useForm, useWatch } from "react-hook-form";

import {
  Amount,
  DateFormatter,
  FileUpload,
  ImgRender,
  AppTitle,
  DatePickerInput,
  TextInput,
  TextareaInput,
  InputErrorMsg,
  SelectInput,
  TableHeader,
  Toaster,
} from "@sk/uis";
import * as yup from "yup";
import { format, subDays } from "date-fns";

const header = [
  { label: "Sl.No" },
  { label: "Invoice No." },
  { label: "Invoice Date" },
  { label: "Amount" },
  { label: "Dock" },
  { label: "Rack" },
  { label: "Bin" },
  { label: "Asset" },
  { label: "Action" },
];
// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "60%",
  },
};

const invoiceDateRangeConfig = {
  minDate: subDays(new Date(), 6),
  maxDate: new Date(),
};

const defaultFormValues = {
  invoices: [],
  lrNumber: "",
  vhNo: "",
  shippingCost: "",
  remarks: "",

  //  invoice form filed
  invoiceNo: "",
  invoiceDate: "",
  dock: "",
  rack: "",
  bin: "",
  amount: "",
  docType: "",
  image: [],
};

const invoiceModalValidationSchema = yup.object({
  // invoices: yup.array().min(1, "Please add invoice").required(),

  lrNumber: yup.string().trim().required("LR Number is Required"),

  vhNo: yup.string().trim().required("Vehicle number is required"),

  shippingCost: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .moreThan(0, "Shipping Cost must be greater then zero")
    .required("Shipping Cost is required"),

  // );

  // const addInvoiceValidationSchema = yup.object({
  invoiceNo: yup.string().trim().required("Invoice Number is required"),
  invoiceDate: yup.date().required("Invoice Date is Required"),
  dock: yup.string().required("Dock is required"),
  rack: yup.string().required("Rack is required"),
  bin: yup.string().required("Bin is required"),
  amount: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .moreThan(0, "Amount cannot be zero")
    .required("Amount is required"),
  docType: yup.string(),
  image: yup
    .array()
    .min(1, "Invoice image is Required")
    .required("Invoice image is Required"),
});

const GrnVendorInvoiceModal = ({
  show,
  callback,
  warehouse,
  invoiceDetails,
  areaDetailsCb,
}) => {
  const {
    register,
    control,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(invoiceModalValidationSchema),
  });

  const [areaDetails, setAreaDetails] = useState([]);

  const [dockOptions, setDockOptions] = useState([
    {
      label: "No Dock Option Found",
      value: "",
    },
  ]);

  const [rackOptions, setRackOptions] = useState([
    {
      label: "Please Select Dock First",
      value: "",
    },
  ]);

  const [binOptions, setBinOptions] = useState([
    {
      label: "Please select Rack First",
      value: "",
    },
  ]);

  const [dock, rack, image, invoices] = useWatch({
    control,
    name: ["dock", "rack", "image", "invoices"],
  });

  const [editingDetails, setEditingDetails] = useState({
    isEditing: false,
    index: -1,
  });

  useEffect(() => {
    if (show && warehouse) {
      setValue("invoices", invoiceDetails?.invoices ?? []);
      ["lrNumber", "vhNo", "shippingCost"].forEach((key) => {
        setValue(key, invoiceDetails?.vehicleDetails?.[key] ?? "");
      });
    } else {
      callback({ action: "close" });
    }
  }, [show, warehouse, callback, setValue, invoiceDetails]);

  useEffect(() => {
    if (warehouse) {
      loadDockDetails();
    }
  }, [warehouse, loadDockDetails]);

  const triggerCloseModal = useCallback(() => {
    callback({ action: "close" });
  }, [callback]);

  const preparePayload = useCallback((data) => {
    console.log("invoices", data);
    let inv = [
      {
        amount: data.amount,
        docType: "image",
        invoiceNo: data.invoiceNo,
        invoiceDate: data.invoiceDate,
        dock: data.dock,
        dockName:
          areaDetails.filter((e) => {
            return e._id == data.dock;
          })[0]?.name || "",
        rack: data.rack,
        bin: data.bin,
        asset: data.image[0],
      },
    ];
    return {
      invoices: inv,
      image: data.image,
      vehicleDetails: {
        lrNumber: data.lrNumber,
        vhNo: data.vhNo,
        shippingCost: data.shippingCost,
        remarks: data.remarks,
      },
    };
  }, []);

  const onSubmit = useCallback(
    (data) => {
      const formData = preparePayload(data);
      callback({ action: "submitted", formData });
    },
    [callback, preparePayload]
  );

  const onNumberInputChange = useCallback(
    debounce(() => {
      let val = getValues("shippingCost");
      val = CommonService.roundedByDecimalPlace(val, 3);
      if (val <= 0) {
        val = "";
      }
      setValue("shippingCost", val);
    }, 1000),
    [setValue, getValues]
  );

  const onTextInputChange = useCallback((e) => {
    let val = e.target.value;
    e.target.value = val.toUpperCase().trim();
  }, []);

  // const addInvoice = useCallback(async () => {
  //   const currentInvoices = getValues("invoices");

  //   try {
  //     const v = await addInvoiceValidationSchema.validate(getValues());

  //     const isInvoiceExist = currentInvoices.find(
  //       (x) => x.invoiceNo == v.invoiceNo
  //     );

  //     if (isInvoiceExist) {
  //       Toaster.error("Invoice Number already exists");
  //       return;
  //     }

  //     let tempVal = {
  //       amount: v.amount,
  //       docType: "image",
  //       invoiceNo: v.invoiceNo,
  //       invoiceDate: v.invoiceDate,
  //       dock: v.dock,
  //       dockName:
  //         areaDetails.filter((e) => {
  //           return e._id == v.dock;
  //         })[0]?.name || "",
  //       rack: v.rack,
  //       bin: v.bin,
  //       asset: v.image[0],
  //     };

  //     currentInvoices.unshift(tempVal);

  //     setValue("invoices", currentInvoices);

  //     resetInvoiceFormValues();

  //     Toaster.success("Invoice added successfully");
  //   } catch (error) {
  //     Toaster.error(error.message);
  //   }
  // }, [getValues, areaDetails, setValue, resetInvoiceFormValues]);

  const resetInvoiceFormValues = useCallback(() => {
    ["invoiceNo", "invoiceDate", "rack", "dock", "bin", "amount"].forEach(
      (x) => {
        setValue(x, "");
      }
    );
    // Image
    setValue("image", []);

    // resetting Rack and Bin Option
    setRackOptions([{ label: "Please Select Dock first", value: "" }]);
    setBinOptions([{ label: "Please Select Rack first", value: "" }]);
  }, [setValue]);

  const dateChangeCb = (chngFn) => {
    return (val) => {
      if (!val.length) {
        return;
      }
      chngFn(val);
      setValue("invoiceDate", val[0]);
    };
  };

  const removeImage = useCallback(
    async (key, index) => {
      let v = getValues(key);
      v.splice(index, 1);
      setValue(key, v);
    },
    [getValues, setValue]
  );

  const removeImg = useCallback(
    (key) => {
      removeImage(key, 0);
    },
    [removeImage]
  );

  const imagesCb = useCallback(
    (e) => {
      const v = getValues("image") || [];
      setValue("image", [e]);
    },
    [setValue, getValues]
  );

  const loadDockDetails = useCallback(async () => {
    const r = await WarehouseService.getAreaDetailsById(warehouse, {
      filter: { location: "Dock" },
    });

    const d = Array.isArray(r.resp) ? r.resp : [];
    areaDetailsCb(d);
    setAreaDetails(d);
    let dock = d.map((x) => ({ label: x.name, value: x._id }));
    setDockOptions((prev) => [{ label: "Select Dock", value: "" }, ...dock]);
  }, [areaDetailsCb, warehouse]);

  const onDockChange = useCallback(
    async (currentDock) => {
      if (!currentDock) {
        return;
      }

      let r = areaDetails.find((x) => x._id == currentDock).racks || [];
      r = r.map((x) => ({ label: x.name, value: x.name }));

      // if there is no rack for current dock
      if (!r.length) {
        setRackOptions([{ label: "No Rack Options found", value: "" }]);
        return;
      }

      await setRackOptions([{ label: "Select Rack", value: "" }, ...r]);

      setValue("rack", "");
    },
    [areaDetails, setValue]
  );

  const onRackChange = useCallback(async () => {
    let r = "";
    let currentDock = "";

    currentDock = getValues("dock");
    r = getValues("rack");

    let rack = areaDetails.find((x) => x._id == currentDock)?.racks || [];

    let bin = rack.find((x) => x.name == r)?.bins || [];

    bin = bin.map((x) => ({ label: x.name, value: x.name }));

    if (!bin.length) {
      setBinOptions([{ label: "No Bin Options found", value: "" }]);
      return;
    }

    await setBinOptions([{ label: "Select Bin", value: "" }, ...bin]);

    setValue("bin", "");
  }, [areaDetails, getValues, setValue]);

  const onEdit = useCallback(
    (i) => {
      setEditingDetails({
        isEditing: false,
        index: i,
      });
      let d = invoices[i];
      setValue("amount", d.amount);
      setValue("invoiceNo", d.invoiceNo);
      setValue("invoiceDate", d.invoiceDate);
      setValue("dock", d.dock);
      setValue("dockName", d.dockName);
      setValue("image", [d.asset]);
      onDockChange(d.dock);
    },
    [invoices, onDockChange, setValue]
  );

  const onRemove = useCallback((i) => {}, []);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        backdrop="static"
        keyboard={false}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          closeVariant="white"
          style={style.offCanvasHeaderStyle}
        >
          {/* Modal Title */}
          <AppTitle
            title="Invoice Details"
            className="fs-val-lg text-dark px-2"
          />
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <>
            {/* Invoice Details */}
            <div className="p-4 bg-white border-bottom">
              <div className="row">
                <div className="col-12">
                  <div className="fw-semibold fs-val-lg mb-3">
                    Invoice Details
                    <span className="ps-2">
                      <InputErrorMsg msg={errors?.invoices?.message} />
                    </span>
                  </div>
                </div>

                <div className="col-4">
                  <TextInput
                    type="text"
                    name="invoiceNo"
                    label="Invoice Number"
                    register={register}
                    isMandatory={true}
                    placeholder="Please enter invoice number"
                    callback={onTextInputChange}
                    error={errors?.invoiceNo?.message}
                  />
                </div>

                <div className="col-4">
                  <label className=" mb-1 fs-val-md">
                    Invoice Date
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    control={control}
                    name="invoiceDate"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <DatePickerInput
                          placeholder="Choose"
                          value={[value]}
                          inpChange={dateChangeCb(onChange)}
                          config={invoiceDateRangeConfig}
                        />
                      );
                    }}
                  />
                  {errors?.invoiceDate?.message && (
                    <div>
                      <InputErrorMsg msg="Invoice Date is Required" />
                    </div>
                  )}
                </div>

                <div className="col-4">
                  <TextInput
                    type="number"
                    register={register}
                    name="amount"
                    placeholder="Enter Amount"
                    callback={onNumberInputChange}
                    label="Amount"
                    isMandatory={true}
                    error={errors?.amount?.message}
                  />
                </div>

                <div className="col-4">
                  <SelectInput
                    register={register}
                    name="dock"
                    callback={onDockChange}
                    options={dockOptions}
                    label="Dock Area"
                    error={errors?.dock?.message}
                  />
                </div>

                <div className="col-4">
                  <SelectInput
                    register={register}
                    name="rack"
                    disabled={!dock}
                    options={rackOptions}
                    callback={onRackChange}
                    label="Rack"
                    error={errors?.rack?.message}
                  />
                </div>

                <div className="col-4">
                  <SelectInput
                    register={register}
                    name="bin"
                    disabled={!rack}
                    options={binOptions}
                    label="Bin"
                    error={errors?.bin?.message}
                  />
                </div>

                {/* Image */}
                <div className="col-12">
                  <div>
                    <label className="fs-val-md mb-1">
                      Image <span className="text-danger"> * </span>
                    </label>
                  </div>
                  <div className="row">
                    <div className="col-auto">
                      {image.length ? (
                        <div className="uploaded-t1-img-cnt col-auto">
                          <i
                            tabIndex={-1}
                            role="button"
                            className="bi bi-x-circle close"
                            onClick={() => {
                              removeImg("image");
                            }}
                          ></i>
                          <ImgRender
                            assetId={image.length ? image[0]["asset"] : ""}
                            width="100"
                            height="100"
                          />
                        </div>
                      ) : (
                        <FileUpload
                          template={2}
                          callback={imagesCb}
                          maxSize={10}
                        />
                      )}
                    </div>
                  </div>

                  {errors?.image?.message && (
                    <div>
                      <InputErrorMsg msg={errors?.image?.message} />
                    </div>
                  )}
                </div>

                {/* <div className="col-12 text-end">
                  <button
                    className="btn btn-primary fs-val-md"
                    onClick={addInvoice}
                  >
                    Add Invoice
                  </button>
                </div> */}
              </div>
            </div>

            {/* Vehicle Details */}
            <div className="p-4 bg-light border-bottom">
              <div className="row">
                <div className="col-12">
                  <div className="fw-semibold fs-val-lg mb-3">
                    Vehicle Details
                  </div>
                </div>

                <div className="col-4">
                  <TextInput
                    type="text"
                    register={register}
                    name="lrNumber"
                    placeholder="Enter LR number"
                    label="LR Number"
                    callback={onTextInputChange}
                    error={errors?.lrNumber?.message}
                    isMandatory={true}
                  />
                </div>

                <div className="col-4">
                  <TextInput
                    type="text"
                    register={register}
                    name="vhNo"
                    callback={onTextInputChange}
                    placeholder="Enter Vehicle number"
                    label="Vehicle Number"
                    error={errors?.vhNo?.message}
                    isMandatory={true}
                  />
                </div>

                <div className="col-4">
                  <TextInput
                    type="number"
                    register={register}
                    name="shippingCost"
                    placeholder="Enter Shipping Cost"
                    callback={onNumberInputChange}
                    label="Shipping Cost"
                    error={errors?.shippingCost?.message}
                    isMandatory={true}
                  />
                </div>

                <div className="col-12">
                  <TextareaInput
                    rows={2}
                    name="remarks"
                    placeholder="Enter Remarks here"
                    register={register}
                    label="Additional Remarks"
                  />
                </div>
              </div>
            </div>
          </>
        </Offcanvas.Body>

        {/* Footer */}
        <Offcanvas.Header>
          <div className="col-12 text-end">
            <button
              className="btn btn-primary fs-val-md"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </button>
          </div>
        </Offcanvas.Header>
      </Offcanvas>
    </>
  );
};

export default memo(GrnVendorInvoiceModal);
