import { Amount, NoDataFound, TableHeader } from "@sk/uis";
import { Offcanvas } from "react-bootstrap";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "From Bank", width: "15%" },
  { label: "To Bank", width: "15%" },
  { label: "Payment Value", width: "15%", isCentered: true },
  { label: "Payment On", width: "15%", isCentered: true },
  { label: "Payment Status", width: "15%", isCentered: true },
  { label: "Remarks", width: "10%" },
  { label: "Created On", width: "10%", isCentered: true },
];

const offcanvasStyle = { width: "60%" };

const GrnViewPaymentInfoModal = ({ show, callback, details, data = [] }) => {
  const renderSummaryCard = (label, value) => (
    <div className="bg-white px-4 py-3 rounded shadow-sm">
      <div className="fs-val-md mb-1">{label}</div>
      <div className="fs-val-lg">
        <Amount value={value} decimalPlace={2} />
      </div>
    </div>
  );

  return (
    <Offcanvas
      show={show}
      onHide={() => callback({ action: "close" })}
      placement="end"
      style={offcanvasStyle}
    >
      <Offcanvas.Header closeButton className="bg-white">
        <Offcanvas.Title>Payment Information</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg">
        <div className="fs-val-md mb-2 fw-semibold">Payment Summary</div>
        <div className="row mb-3">
          <div className="col-4">
            {renderSummaryCard("Total Invoice Amount", details?.invoiceAmount)}
          </div>
          <div className="col-4">
            {renderSummaryCard("Total GRN Amount", details?.grnAmount)}
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-4">
            {renderSummaryCard(
              "Total Debit",
              details?.debitAmounts?.totalDebitAmount
            )}
          </div>
          <div className="col-4">
            {renderSummaryCard(
              "Auto Debit",
              details?.debitAmounts?.totalAutoDebitAmount
            )}
          </div>
          <div className="col-4">
            {renderSummaryCard(
              "Manual Debit",
              details?.debitAmounts?.totalManualDebitAmount
            )}
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-4">
            {renderSummaryCard("To be Paid", details?.payableAmount)}
          </div>
          <div className="col-4">
            {renderSummaryCard("Paid", details?.grnAmount - details?.payableAmount)}
          </div>
          <div className="col-4">
            {renderSummaryCard(
              "Remaining",
              details?.grnAmount - details?.payableAmount
            )}
          </div>
        </div>

        <div className="mb-3 custom-scrollbar thin-scrollbar rounded border">
          <table className="table table-sm table-hover">
            <TableHeader data={headers} isSticky={true} />
            <tbody>
              {!data.length ? (
                <tr>
                  <td colSpan={headers.length}>
                    <NoDataFound>No Payments found</NoDataFound>
                  </td>
                </tr>
              ) : null}

              {data.map((x, index) => (
                <tr key={x._id} className="fs-val-md">
                  <td>{index + 1}</td>
                  <td>{x.fromBank}</td>
                  <td>{x.toBank}</td>
                  <td className="text-center">{x.paymentValue}</td>
                  <td className="text-center">{x.paymentOn}</td>
                  <td className="text-center">{x.paymentStatus}</td>
                  <td>{x.remarks}</td>
                  <td className="text-center">{x.createdOn}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default GrnViewPaymentInfoModal;
