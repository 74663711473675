const BasicDetails = ({ vendorDetails, franchiseDetails }) => {
  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row">
            {/* Franchise Details */}
            <div className="col-6 border-end">
              <div className="fw-semibold fs-val-lg mb-1"> Smart Store </div>

              <div className="fs-val-md text-primary mb-1">
                {franchiseDetails?.name} - {franchiseDetails?.id}
              </div>

              {/* Address */}
              <div className="mb-1 text-muted">
                {franchiseDetails?.address?.door_no},
                {franchiseDetails?.address?.street}, {franchiseDetails?.town},
                {franchiseDetails?.district},{franchiseDetails?.state}-
                {franchiseDetails?.pincode ?? "N/A"}
              </div>

              <div className="fw-semibold">
                {franchiseDetails.mobile ? (
                  <div className="d-inline-block me-2">
                    <i className="bi bi-telephone-fill"></i>
                    <span className="px-1">{franchiseDetails?.mobile}</span>
                  </div>
                ) : null}

                {franchiseDetails.email ? (
                  <div className="d-inline-block me-2">
                    <i className="bi bi-envelope"></i>
                    <span className="px-1">{franchiseDetails?.email}</span>
                  </div>
                ) : null}

                <div className="d-inline-block me-2">
                  <span>GST No : </span>
                  <span className="px-1">
                    {franchiseDetails?.gstNo || "N/A"}
                  </span>
                </div>
              </div>
            </div>

            {/* Vendor Details */}
            <div className="col-6 ps-4">
              <div className="fw-semibold fs-val-lg mb-1">Vendor Details</div>

              <div className="fs-val-md text-primary mb-1">
                {vendorDetails?.name} - ({vendorDetails?.id})
              </div>

              {/* Address */}
              <div className=" mb-1 text-muted">
                {vendorDetails?.address?.line1},{vendorDetails?.address?.line2},
                {vendorDetails?.town},{vendorDetails.district},
                {vendorDetails?.state}-{vendorDetails?.postcode}
              </div>
              <div className="fw-semibold">
                {/* Contact */}
                <div className="d-inline-block me-2">
                  <i className="bi bi-telephone-fill"></i>
                  <span className="px-1">{vendorDetails?.mobile ?? "N/A"}</span>
                </div>

                {/* Email */}
                {vendorDetails?.email ? (
                  <div className="d-inline-block me-2">
                    <i className="bi bi-envelope"></i>
                    <span className="px-1">
                      {vendorDetails?.email ?? "N/A"}
                    </span>
                  </div>
                ) : null}

                {/* GST No */}
                <div className="d-inline-block me-2">
                  <span> GST No : </span>
                  <span className="px-1">{vendorDetails?.gstNo}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicDetails;
