import { PoItemCard } from "@sk/features";
import { CommonService } from "@sk/services";
import { Amount, HighlightText, KeyVal, TableHeader } from "@sk/uis";
import classNames from "classnames";
import groupBy from "lodash/groupBy";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import styles from "../../../manage-po/ManagePo.module.scss";
import AuditLogModal from "../modal/AuditLogModal";
import ViewRemarkModal from "../modal/ViewRemarkModal";

const headers = [
  { label: "Sl.no", isCentered: true },
  { label: "Name", width: "25%" },
  // { label: "MRP(Rs.)", isCentered: true },
  // { label: "Purchase Price (Rs.)", isCentered: true },
  // { label: "Discount(%)", isCentered: true },
  { label: "Tax", isCentered: true },
  { label: "Pricing" },
  { label: "Stocks(Units)", width: "18%" },
  // { label: "Shelf Life" },
  // { label: "Invoice" },
  { label: "Status", isCentered: true },
  { label: "Total Value", isCentered: true },
];

const statusColors = {
  Approved: "text-success",
  Rejected: "text-danger",
  "Partially Received": "text-primary",
  Received: "text-primary",
};

// Canvas Style
const style = {
  tableHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  tableFooterStyle: {
    backgroundColor: "#e4edff",
  },
  canvasStyle: {
    width: "90%",
  },
};

const ProductList = ({ data, poSummary, status, invoiceCb }) => {
  const [showRemarksModal, setShowRemarks] = useState(false);

  const [showAuditLogModal, setShowAuditLogModal] = useState(false);

  const remarkModalRef = useRef({ product: {} });

  const auditLogRef = useRef({ auditLog: [], data: {} });

  const summary = useMemo(() => {
    if (status == "Approval Pending") {
      return {
        totalPoValue: data.reduce((acc, cur) => acc + cur.totalValue * 1, 0),
        totalPoUnitQuantity: data.reduce((acc, cur) => {
          if (!cur.sellInLoose) {
            return acc + cur.qty * 1;
          }
          return acc;
        }, 0),
        totalPoLooseQuantity: data.reduce((acc, cur) => {
          if (cur.sellInLoose) {
            return acc + cur.qty * 1;
          }
          return acc;
        }, 0),

        looseType: data?.reduce((acc, cur) => {
          if (cur.sellInLoose) {
            return cur._displayUnitType;
          }
          return acc;
        }, ""),
      };
    }

    let stock = 0;
    let price = 0;
    let value = 0;
    let loose = 0;

    (data || []).forEach((e) => {
      price += e.price;
      let currentStock = 0;

      if (e.status == "Received" || e.status == "Partially Received") {
        if (e.sellInLooseQty) {
          let rq = e.receivedQuantity || 0;
          let q = e.unitType == "gm" ? rq / 1000 : rq;
          loose += q;
        } else {
          stock += e.receivedQuantity || 0;
        }

        currentStock = e.receivedQuantity || 0;
      } else {
        if (e.sellInLooseQty) {
          let rq = e.requestedQuantity || 0;
          let q = e.unitType == "gm" ? rq / 1000 : rq;
          loose += q;
        } else {
          stock += e.requestedQuantity || 0;
        }
        currentStock = e.requestedQuantity || 0;
      }

      // e.totalValue = e.price * currentStock;

      value += e.totalValue;
    });
    return {
      stock,
      value,
      price,
      loose: CommonService.roundedByDecimalPlace(loose, 3),
    };
  }, [data, status]);

  const openRemarkModal = (data) => {
    remarkModalRef.current.product = data;
    setShowRemarks(true);
  };

  const remarkModalCb = (payload) => {
    remarkModalRef.current.product = {};
    setShowRemarks(false);
  };

  const openAuditLogModal = useCallback((x) => {
    auditLogRef.current.auditLog = x.auditLog;
    auditLogRef.current.data = x;
    setShowAuditLogModal(true);
  }, []);

  const closeAuditLogModal = useCallback(() => {
    auditLogRef.current = {
      auditLog: [],
      data: {},
    };
    setShowAuditLogModal(false);
  }, []);

  const statusSummary = useMemo(() => {
    let groupedData = groupBy(data, "status");
    return [
      ...Object.keys(groupedData).map((key) => {
        return {
          key: key,
          total: groupedData[key].length,
          color: statusColors[key] || "text-primary",
        };
      }),
    ];
  }, [data]);

  return (
    <>
      <div className="p-3">
        <div className="row mb-2">
          <div className="col-auto fs-val-md me-3">
            <span className="fw-bold "> Total Deals : </span>
            <span className="fw-bold ms-1">{data.length}</span>
          </div>

          <div className="col-auto fs-val-md me-3">
            <span className="fw-bold"> Total PO Value : </span>
            <span className="fw-bold ms-1">
              <Amount value={poSummary.amount} decimalPlace={2} />
            </span>
          </div>

          {statusSummary.map((x) => (
            <div className="me-3 col-auto fs-val-md" key={x.key}>
              <span className={classNames(x.color, "fw-bold")}>{x.key} :</span>
              <span className="fw-bold ms-1">{x.total}</span>
            </div>
          ))}
        </div>

        {status != "Approval Pending" && (
          <>
            <table className="table p-0 table-bordered mb-0 mt-4">
              <TableHeader data={headers} />
              <tbody>
                {data.length == 0 && (
                  <tr>
                    <td
                      colSpan={headers.length}
                      className="text-center fs-val-md"
                    >
                      No Product List Found
                    </td>
                  </tr>
                )}

                {data.length >= 0 &&
                  data.map((x, index) => (
                    <tr className="fs-val-md" key={index}>
                      {/* Serial No */}
                      <td className="text-center">{index + 1}</td>
                      <td>
                        <div className="text-break">{x.name}</div>
                        {/* Deal Id */}
                        <span className="text-muted fs-val-sm me-1">ID :</span>
                        <span className="fs-val-sm me-3">{x.dealId}</span>
                        {/* Deal HSN */}
                        <span className="text-muted fs-val-sm me-1">HSN :</span>
                        <span className="fs-val-sm me-3">{x.hsn}</span>
                        {/* Deal HSN */}
                        <span className="text-muted fs-val-sm me-1">
                          Barcode :
                        </span>
                        <span className="fs-val-sm me-3">
                          {x.barcode || "N/A"}
                        </span>
                        <div className="my-1">
                          <span className="text-muted fs-val-sm me-1">
                            Brand / Category :
                          </span>
                          <span className="fs-val-sm me-3">
                            {x.brand?.name || "N/A"} /{" "}
                            {x.category?.name || "N/A"}
                          </span>
                        </div>
                      </td>
                      {/* MRP */}
                      {/* <td className="text-center">
                        <Amount value={x.mrp} decimalPlace={2} />
                      </td>
                      Purchase Price */}
                      {/* <td className="text-center">
                        <Amount value={x.price} decimalPlace={2} />
                      </td> */}
                      {/* Discount */}
                      {/* <td className="text-center text-success">
                        {CommonService.roundedByDecimalPlace(x.discount, 5) +
                          " %"}
                      </td> */}
                      {/* GST */}
                      <td className="text-center">
                        {CommonService.roundedByDecimalPlace(
                          x?.taxInfo?.gst,
                          5
                        ) + " %"}
                      </td>

                      {/* Pricing */}
                      <td>
                        <div className="mb-1">
                          <KeyVal
                            labelBold=""
                            label="MRP"
                            labelCol="col-8"
                            contentCol="col-4"
                          >
                            <span className=" ">
                              <Amount value={x.mrp} decimalPlace={2} />
                            </span>
                          </KeyVal>
                        </div>
                        <div className="mb-1">
                          <KeyVal
                            labelBold=""
                            label="Margin"
                            labelCol="col-8"
                            contentCol="col-4"
                          >
                            <span className="  text-success">
                              {CommonService.roundedByDecimalPlace(
                                x.discount,
                                5
                              ) + " %"}
                            </span>
                          </KeyVal>
                        </div>

                        <div className="mb-1">
                          <KeyVal
                            labelBold=""
                            label="Purchase Price"
                            labelCol="col-8"
                            contentCol="col-4"
                          >
                            <span className="  text-primary">
                              <Amount value={x.price} decimalPlace={2} />
                            </span>
                          </KeyVal>
                        </div>
                      </td>
                      {/* Stock In (Units) */}
                      <td>
                        <div className="mb-1">
                          <KeyVal
                            labelBold=""
                            label="Requested Qty"
                            labelCol="col-6"
                            contentCol="col-6"
                          >
                            <span className="text-primary ">
                              {x.requestedQuantity} {x._displayUnitType}
                            </span>
                          </KeyVal>
                        </div>
                        {/* <div className="mb-1">
                          <KeyVal
                            labelBold=""
                            label="Invoice Qty`"
                            labelCol="col-6"
                            contentCol="col-6"
                          >
                            <span className="text-primary ">
                              {x.invoiceQty || "N/A"} {x._displayUnitType}
                            </span>
                          </KeyVal>
                        </div> */}
                        {x.status == "Received" ||
                        x.status == "Partially Received" ? (
                          <div className="mb-1">
                            <KeyVal
                              labelBold=""
                              label="Received Qty"
                              labelCol="col-6"
                              contentCol="col-6"
                            >
                              <span className="text-success ">
                                <span>{x.receivedQuantity || 0} </span>
                                {x._displayUnitType}
                              </span>
                            </KeyVal>
                          </div>
                        ) : null}
                        {x.damageQuantity ? (
                          <div className="mb-1">
                            <KeyVal
                              labelBold=""
                              label="Damage Qty"
                              labelCol="col-6"
                              contentCol="col-6"
                            >
                              <span className="text-danger ">
                                {x.damageQuantity}
                                {x._displayUnitType}
                              </span>
                            </KeyVal>
                          </div>
                        ) : null}
                        {x.damageReason ? (
                          <div className="mb-1">
                            <KeyVal
                              labelBold=""
                              label="Damage Reason"
                              labelCol="col-6"
                              contentCol="col-6"
                            >
                              <span className="text-danger ">
                                {x.damageReason}
                              </span>
                            </KeyVal>
                          </div>
                        ) : null}
                        {x.shortageQuantity ? (
                          <div className="mb-1">
                            <KeyVal
                              labelBold=""
                              label="Shortage Qty"
                              labelCol="col-6"
                              contentCol="col-6"
                            >
                              <span className="text-danger ">
                                {x.shortageQuantity} {x._displayUnitType}
                              </span>
                            </KeyVal>
                          </div>
                        ) : null}
                      </td>
                      {/* <td>
                        {x.manufactureDate ? (
                          <>
                            <div className="text-muted fs-val-sm">
                              Manufacture Date{" "}
                            </div>
                            <div className="fs-val-sm mb-2">
                              <DateFormatter
                                date={x?.manufactureDate}
                                format="dd MMM yyyy"
                              />
                            </div>
                          </>
                        ) : (
                          "N/A"
                        )}
                        {x?.expiresOn && (
                          <>
                            <div className="text-muted fs-val-sm">
                              Expires on
                            </div>
                            <div className="fs-val-sm">
                              <DateFormatter
                                date={x?.expiresOn}
                                format="dd MMM yyyy"
                              />
                            </div>
                            <div className="text-danger fs-val-sm">
                              {formatDistance(
                                new Date(x.expiresOn),
                                new Date()
                              ) + " left"}
                            </div>
                          </>
                        )}
                      </td> */}
                      {/* barCode  */}
                      {/* <td className="text-center"> {x?.barcode || "N/A"}</td> */}
                      {/* <td>
                        {x.invoiceDetails?.length
                          ? x.invoiceDetails?.map((x, i) => (
                              <div
                                className="fs-val-sm btn btn-link m-0 p-0 mb-1 text-start"
                                key={i}
                                style={{ display: "block" }}
                                onClick={() => invoiceCb(x.invoiceNo)}
                                role={"button"}
                                tabIndex={-1}
                              >
                                {x.invoiceNo}
                              </div>
                            ))
                          : "N/A"}
                      </td> */}
                      <td className="text-center">
                        <div>
                          <HighlightText status={x.status} isBadge={true} />
                        </div>
                        <div>
                          <button
                            className="btn btn-link fs-val-sm mt-1"
                            onClick={() => openAuditLogModal(x)}
                          >
                            View logs
                          </button>
                        </div>

                        {x.remarks && (
                          <button
                            className="btn btn-link fs-val-sm mt-1"
                            onClick={() => openRemarkModal(x)}
                          >
                            View Remarks
                          </button>
                        )}
                      </td>
                      {/* Total Value  */}
                      <td className="text-center">
                        <Amount value={x.totalValue} decimalPlace={2} />
                      </td>
                    </tr>
                  ))}

                {data.length > 0 && (
                  <tr className="fs-val-md" style={style.tableFooterStyle}>
                    <td colSpan={3} className="fw-semibold">
                      Total Summary
                    </td>
                    <td colSpan={3} className="fw-semibold">
                      Total Stock : {summary.stock}{" "}
                      {summary.loose ? (
                        <span> / {summary.loose} kg</span>
                      ) : null}
                    </td>
                    <td className="fw-semibold text-danger text-center">
                      <Amount value={summary.value} decimalPlace={2} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )}

        {/* When Status is Approval Pending */}
        {status == "Approval Pending" && (
          <>
            {data.length >= 0 &&
              data.map((x, index) => (
                <PoItemCard
                  key={x._id}
                  data={x}
                  index={index}
                  feature="POS-PO"
                  mode="view"
                />
              ))}

            <table
              className={classNames(
                "table  table-bordered table-centered",
                styles.summary
              )}
              style={style.tableHeaderStyle}
            >
              <tbody>
                <tr>
                  <td width="35%">
                    <div
                      className={classNames(styles["summary-lbl"], "fw-bold")}
                    >
                      Total Purchase Order Summary
                    </div>
                  </td>
                  <td width="20%">
                    {statusSummary.map((x) => (
                      <div className="mb-2" key={x.key}>
                        <span
                          className={classNames(
                            styles["summary-lbl"],
                            "fw-bold me-2"
                          )}
                        >
                          {x.key} :
                        </span>
                        <span
                          className={classNames(
                            styles["summary-lbl"],
                            "fw-bold text-primary"
                          )}
                        >
                          {x.total}
                        </span>
                      </div>
                    ))}
                  </td>
                  <td width="20%">
                    <div className="mb-2">
                      <span
                        className={classNames(
                          styles["summary-lbl"],
                          "fw-bold me-2"
                        )}
                      >
                        Total PO Unit Qty:
                      </span>
                      <span
                        className={classNames(
                          styles["summary-lbl"],
                          "fw-bold text-dark"
                        )}
                      >
                        {summary?.totalPoUnitQuantity || 0}
                      </span>
                    </div>

                    {summary?.totalPoLooseQuantity ? (
                      <div className="mb-2">
                        <span
                          className={classNames(
                            styles["summary-lbl"],
                            "fw-bold me-2"
                          )}
                        >
                          Total PO Loose Qty:
                        </span>
                        <span
                          className={classNames(
                            styles["summary-lbl"],
                            "fw-bold text-dark"
                          )}
                        >
                          {summary?.totalPoLooseQuantity} {summary?.looseType}
                        </span>
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <div className={classNames("mb-2", styles["summary-lbl"])}>
                      <span className="fw-bold me-2">Total PO Value:</span>
                      <span className="fw-bold text-warning">
                        <Amount value={summary.totalPoValue} decimalPlace={2} />
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>

      <ViewRemarkModal
        show={showRemarksModal}
        callback={remarkModalCb}
        product={remarkModalRef.current.product}
      />

      <AuditLogModal
        show={showAuditLogModal}
        auditLogs={auditLogRef.current.auditLog}
        dealDetails={auditLogRef.current.data}
        closeModal={closeAuditLogModal}
      />
    </>
  );
};

export default memo(ProductList);
