import { useFetchUrlQueryString } from "@sk/hooks";
import {
  FranchiseService,
  NavService,
  OmsService,
  PoService,
} from "@sk/services";
import {
  BusyLoader,
  NoDataFound,
  PageInfo,
  PageLoader,
  Rbac,
  TabContent,
  Tabs,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import ProductList from "./components/ProductList";

import InvoiceDetails from "./components/InvoiceDetails";

import PaymentDetails from "./components/PaymentDetails";

import BasicDetails from "../approve/components/BasicDetails";

import PosPoBasicInfo from "../components/PosPoBasicInfo";

import ViewDealModal from "./modal/ViewDealModal";

import { produce } from "immer";

import PendingPaymentModal from "../modals/PendingPaymentModal";

import sortBy from "lodash/sortBy";

import BillToShipToDetails from "../approve/components/BillToShipToDetails";

import ClosePoRemarksModal from "../components/ClosePoRemarksModal";

import { cloneDeep } from "lodash";
import PoStatusAuditLog from "./components/PoStatusAuditLog";
import { PosPoPrintTemplate } from "@sk/features";

// These are Feature
const defaultTab = [
  { key: "productList", tabName: "Requested Deals", name: "Deals" },
  { key: "invoiceDetails", tabName: "Inwarded Stock", name: "Invoices" },
  { key: "paymentSettlement", tabName: "Payments", name: "Payments" },
  { key: "statusAuditLog", tabName: "PO Status AuditLog" },
];

// BreadCrumb Config
const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "POS Third party PO",
    link: "/purchase-order/pos/list",
  },
  {
    name: "PO Details",
  },
];

const rbac = {
  closePO: ["ForceClosePO"],
};

const ViewPosPo = () => {
  const [searchParams] = useSearchParams();

  const [display, setDisplay] = useState("loading");

  const [data, setData] = useState({});

  // to Fetch QueryString
  const query = useFetchUrlQueryString(searchParams);

  const [showViewDealModal, setShowViewDealModal] = useState(false);

  const [showPendingPaymentModal, setShowPendingPaymentModal] = useState(false);

  const [busyLoader, setBusyLoader] = useState({ show: false, msg: "" });

  const productList = useMemo(() => {
    if (data.status == "Approval Pending" || data.status == "Draft") {
      return data.productList;
    }
    return data.requestProductList;
  }, [data]);

  const [showRemarksModal, setShowRemarksModal] = useState(false);

  const remarksModalRef = useRef({ data: "", type: "" });

  const viewDealModalRef = useRef({ data: [] });

  const invoiceDetailsRef = useRef({ data: {} });

  const pendingPaymentModalRef = useRef({ poDetails: {} });

  const openViewDealModal = useCallback((x) => {
    viewDealModalRef.current.data = x ?? [];
    setShowViewDealModal(true);
  }, []);

  const closeViewDealModal = useCallback(() => {
    viewDealModalRef.current.data = [];
    setShowViewDealModal(false);
  }, []);

  // get Product Details
  const loadPosPoDetails = useCallback(async () => {
    setDisplay("loading");
    const r = await PoService.getPosPo({
      filter: {
        _id: query.id,
      },
    });
    const d = Array.isArray(r.resp) ? r.resp : [];

    setData(d);

    if (d.length) {
      let tempData = d[0] || {};
      if (tempData.status == "Approval Pending") {
        const r = await attachPrdSalesAndPurchaseDetails(tempData);
        tempData.productList = r.map((x) =>
          PoService.formatPosPoProductResp(x)
        );
      }

      tempData.productList = sortBy(tempData.productList, "name");

      // if any of status we will show product List
      if (
        !(tempData.status == "Approval Pending" || tempData.status == "Draft")
      ) {
        let list = Array.isArray(tempData?.requestProductList)
          ? cloneDeep(tempData?.requestProductList)
          : [];

        tempData.requestProductList = list.map((x) => {
          const curProduct = tempData.productList.find((p) => {
            return p.pid == x.pid;
          });
          return {
            ...x,
            receivedQuantity: curProduct?.totalReceivedQuantity || 0,
            status: curProduct?.status || x.status,
            auditLog: curProduct?.auditLog,
            _displayUnitType: curProduct?._displayUnitType || x.displayUnitType,
          };
        });
      }

      setData(tempData);

      setDisplay("details");
    } else {
      setDisplay("noDataFound");
    }
  }, [query.id, attachPrdSalesAndPurchaseDetails]);

  useEffect(() => {
    if (query.id) {
      loadPosPoDetails(query.id);
    } else {
      setDisplay("noDataFound");
    }
  }, [loadPosPoDetails, query.id]);

  const navigate = useNavigate();

  const tabs = useMemo(() => {
    // const t = [...defaultTab].map((tab) => {
    //   tab.tabName =
    //     tab.name +
    //     (details?.[tab?.key]?.length
    //       ? " (" + details?.[tab?.key]?.length + ")"
    //       : "");
    //   return tab;
    // });

    if (data.status == "Approval Pending" || data.status == "Draft") {
      return [defaultTab[0], defaultTab[3]];
    } else {
      return defaultTab; // conflicted
    }
  }, [data]);

  const [activeTab, setActiveTab] = useState({
    key: defaultTab[0].key,
  });

  // Tab Callback to change Tab
  const tabCb = (data) => {
    if (data.value.key != "invoiceDetails") {
      invoiceDetailsRef.current.data = {};
    }
    setActiveTab({ ...data.value });
  };

  const viewApprovePosPo = (id) => {
    NavService.openInNewTab("/purchase-order/pos/approve", {
      id,
    });
  };

  const attachPrdSalesAndPurchaseDetails = useCallback(async (data) => {
    const dealIds = data.productList.map((x) => x.dealId);
    let productList = data.productList;
    const p = {
      franchiseId: data.franchise.id,
      groupbycondName: "deal",
      groupbycond: "deal",
      filter: {
        _id: data.franchise.id,
      },
      dealFilter: {
        _id: { $in: dealIds },
      },
    };

    const r = await FranchiseService.getFranchiseInventorySummary(p);

    const d = Array.isArray(r.resp) ? r.resp : [];

    productList.forEach((p) => {
      let prd = d.find((x) => p.dealId == x._id);

      p.currentStock = prd?.qty || 0;
      p.purchasedIn = {
        fifteenDays:
          (prd?.thirdPartyPosPurchaseData?.fifteendayQty || 0) +
          (prd?.purchaseData?.fifteendayQty || 0),
        thirtyDays:
          (prd?.thirdPartyPosPurchaseData?.thirtydaysQty || 0) +
          (prd?.purchaseData?.thirtydaysQty || 0),
        sixtyDays:
          (prd?.thirdPartyPosPurchaseData?.sixtydaysQty || 0) +
          (prd?.purchaseData?.sixtydaysQty || 0),
      };

      p.soldIn = {
        thirtyDays: prd?.salesData?.thirtyday?.qty || 0,
        sixtyDays: prd?.salesData?.sixtyday?.qty || 0,
        ninetyDays: prd?.salesData?.ninetyday?.qty || 0,
      };
    });

    return productList;
  }, []);

  const invoiceCb = useCallback((data) => {
    invoiceDetailsRef.current.data = { id: data };
    setActiveTab({
      key: "invoiceDetails",
      tabName: "Invoices",
      name: "Invoices",
    });
  }, []);

  const openPendingPaymentModal = useCallback((x) => {
    pendingPaymentModalRef.current.poDetails = x;
    setShowPendingPaymentModal(true);
  }, []);

  const pendingPaymentModalCb = useCallback(
    (payload) => {
      pendingPaymentModalRef.current.poDetails = {};

      if (payload.action == "submit") {
        setData(
          produce((draft) => {
            draft.status = "Closed";
            draft._statusLbl = "Closed";
            draft.paymentSettlement = payload.data.paymentSettlement;
          })
        );

        setShowPendingPaymentModal(false);
        loadPosPoDetails(query.id);
        return;
      }
      setShowPendingPaymentModal(false);
    },
    [loadPosPoDetails, query.id]
  );

  const openRemarksModal = (item, type) => {
    remarksModalRef.current.data = item;
    remarksModalRef.current.type = type;
    setShowRemarksModal(true);
  };

  const closePo = async (item, remarks) => {
    setBusyLoader({ show: true, msg: "" });

    const p = { remarks, status: "Closed" };

    const r = await OmsService.forceClosePosPo(item._id, p);
    setBusyLoader({ show: false });

    if (r.statusCode != 200) {
      Toaster.error(r.resp.message || "Failed to update");
      return;
    }

    Toaster.success("Updated Successfully");

    loadPosPoDetails(query.id);
  };

  const updateStatusToPaymentPending = async (item, remarks) => {
    setBusyLoader({ show: true, msg: "" });

    const p = { ...item, remarks, status: "Received - Payment Pending" };

    const r = await OmsService.closePosPo(item._id, p);
    setBusyLoader({ show: false });

    if (r.statusCode != 200) {
      Toaster.error(r.resp.message || "Failed to update");
      return;
    }

    Toaster.success("Updated Successfully");

    loadPosPoDetails(query.id);
  };

  const remarkModalCb = (payload) => {
    const { action, remarks } = payload;
    let type = remarksModalRef.current.type;
    if (action == "submit" && type == "close") {
      closePo(remarksModalRef.current.data, remarks);
    }
    if (action == "submit" && type == "update") {
      updateStatusToPaymentPending(remarksModalRef.current.data, remarks);
    }
    remarksModalRef.current.data = {};
    setShowRemarksModal(false);
  };

  const print = async () => {
    setBusyLoader({ show: true });

    const r = await OmsService.getIntakeSummaryList({
      page: 1,
      count: 100,
      filter: { poId: query.id },
    });

    setBusyLoader({ show: false });

    let d = { ...data };

    if (Array.isArray(r.resp) && r.resp.length > 0) {
      let t = [];
      r.resp.forEach((x) => {
        t = t.concat(x.productList);
      });
      d.requestProductList = t;
    }

    PosPoPrintTemplate.doPrint(d);
  };

  return (
    <>
      <div className="row m-0 justify-content-between align-items-center">
        <div className="col-auto">
          <PageInfo
            title={
              query.id
                ? `POS Third party PO #${query.id}`
                : "POS Third party PO"
            }
            breadcrumbs={breadcrumbs}
            navigate={navigate}
          />
        </div>
        <div className="col-auto px-2">
          <button
            className="btn btn-sm btn-warning fs-val-md mb-2 me-2"
            onClick={print}
            // onClick={() => PosPoPrintTemplate.doPrint(data)}
            disabled={display != "details"}
          >
            Print PO
          </button>
          {["Partially Received"].includes(data.status) ? (
            <button
              className="btn btn-sm btn-warning fs-val-md mb-2 me-2"
              onClick={() => openRemarksModal(data, "update")}
            >
              Move to Payment Pending
            </button>
          ) : null}
          <Rbac roles={rbac.closePO}>
            {["Partially Received"].includes(data.status) ? (
              <button
                className="btn  btn-sm btn-danger fs-val-md mb-2"
                onClick={() => openRemarksModal(data, "close")}
              >
                Close PO
              </button>
            ) : null}
          </Rbac>
        </div>
      </div>

      {display == "loading" && <PageLoader />}

      {display == "noDataFound" && <NoDataFound> No Data Found</NoDataFound>}

      {display == "details" && (
        <>
          {data.isBillToStore === false &&
            data.status == "Received - Payment Pending" && (
              <>
                <div className="px-2">
                  <div className="alert alert-primary border-0 row align-items-center">
                    <div className="col-10">
                      <strong>Payment Required </strong>
                      PO Payment is pending please upload payment
                    </div>
                    <div className="col-2 text-end">
                      <button
                        className="btn btn-outline-dark fw-semibold"
                        onClick={() => openPendingPaymentModal(data)}
                      >
                        Upload Payment
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          <PosPoBasicInfo poDetails={data} />

          <div className="mb-3">
            <BasicDetails
              vendorDetails={data.vendorDetails}
              franchiseDetails={data.franchise}
            />
          </div>

          {!data.isBillToStore && (
            <div className="mb-3">
              <BillToShipToDetails billTo={data.billTo} shipTo={data.shipTo} />
            </div>
          )}

          <Tabs data={tabs} activeTab={activeTab} callback={tabCb} />

          <TabContent>
            {activeTab.key == "productList" && (
              <ProductList
                data={productList}
                poSummary={data.poSummary}
                status={data.status}
                invoiceCb={invoiceCb}
              />
            )}
            {activeTab.key == "invoiceDetails" && (
              <InvoiceDetails
                data={data.invoiceDetails}
                openModal={openViewDealModal}
                invoiceDetails={invoiceDetailsRef.current.data}
              />
            )}

            {activeTab.key == "paymentSettlement" && (
              <PaymentDetails
                data={data.paymentSettlement}
                productList={productList}
                openModal={openViewDealModal}
                invoiceCb={invoiceCb}
                poDetails={data}
              />
            )}
            {activeTab.key == "statusAuditLog" && (
              <PoStatusAuditLog auditLogs={data.statusAuditLog} />
            )}
          </TabContent>
        </>
      )}

      <ViewDealModal
        show={showViewDealModal}
        closeModal={closeViewDealModal}
        data={viewDealModalRef.current.data}
      />

      <PendingPaymentModal
        show={showPendingPaymentModal}
        callback={pendingPaymentModalCb}
        poDetails={pendingPaymentModalRef.current.poDetails}
      />

      <ClosePoRemarksModal
        show={showRemarksModal}
        callback={remarkModalCb}
        type={remarksModalRef.current.type}
        detail={remarksModalRef.current.data}
      />

      <BusyLoader show={busyLoader.show} />
    </>
  );
};

export default ViewPosPo;
