import { DateFormatter, KeyVal, TableHeader } from "@sk/uis";
import { Offcanvas } from "react-bootstrap";

// Canvas Style
const style = {
  canvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  canvasStyle: {
    width: "90%",
  },
};

const header = [
  { label: "Sl.no", isCentered: true },
  { label: "Vendor" },
  { label: "Grn Date", isCentered: true },
  { label: "MRP", isCentered: true },
  { label: "Purchase Price", isCentered: true },
  { label: "Margin", isCentered: true },
];

const VendorPriceModal = ({ show, closeModal, data }) => {
  return (
    <Offcanvas
      show={show}
      placement="end"
      onHide={closeModal}
      backdrop="static"
      style={style.canvasStyle}
    >
      <Offcanvas.Header
        closeButton
        style={style.canvasHeaderStyle}
        closeVariant="white"
      >
        <Offcanvas.Title>
          <div>Vendor Price List</div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <table className="table table-bordered">
          <TableHeader data={header} />
          <tbody>
            {data?.data?.length == 0 && (
              <tr className="fs-val-md">
                <td colSpan={header.length} className="text-center">
                  No Data Found
                </td>
              </tr>
            )}

            {data.data.length > 0 &&
              data.data.map((x, i) => (
                <tr className="fs-val-md" key={i}>
                  <td>{i + 1}</td>

                  <td>
                    {x._id.vname}-{x._id.vid}
                  </td>

                  <td className="text-center">
                    <DateFormatter date={x.createdAt} />
                  </td>

                  <td className="text-center">{x.priceDetails.mrp}</td>
                  <td className="text-center">
                    {x.priceDetails.purchasePrice}
                  </td>
                  <td>
                    <KeyVal
                      label={"Base Margin"}
                      labelCol="col-7"
                      contentCol="col-5"
                    >
                      {x.priceDetails.margin.bMargin} %
                    </KeyVal>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default VendorPriceModal;
