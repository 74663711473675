import { AppCard, InfoBlk, PageInfo, TabContent } from "@sk/uis";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import VendorMarginModal from "../../../modals/VendorMarginModal";
import VendorFilterTabs from "../components/VendorFilterTabs";
import Filter from "./components/Filter";
import Table from "./components/Table";
import { AuthService } from "@sk/services";

const tabs = [
  {
    key: "topVendors",
    tabName: "Top Vendors",
    search: [{ type: "vendor", placeholder: "Search by Vendor" }],
  },
  {
    key: "vendor",
    tabName: "Vendor Master",
    search: [{ type: "vendor", placeholder: "Search by Vendor" }],
  },
];

const breadcrumbs = [
  { name: "Home", link: "/auth`/init" },
  {
    name: "PO List",
    link: "/purchase-order/list",
  },
  { name: "Create Purchase Order" },
];

const ManagePoMain = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const [filterData, setFilterData] = useState({
    wh: AuthService.getDefaultWhId(),
  });

  const filterCb = (d) => {
    setFilterData((prev) => ({ ...prev, ...d }));
  };

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <PageInfo
        title="Create Purchase Order"
        breadcrumbs={breadcrumbs}
        navigate={navigate}
      />

      <div className="mb-3">
        <InfoBlk variant="info" size="md">
          This page allows you to create and manage purchase orders. You can
          filter vendors, view their details, and create new purchase orders
          based on the selected vendors information.
        </InfoBlk>
      </div>

      <AppCard>
        <Filter callback={filterCb} />
      </AppCard>

      <AppCard noPad>
        <div className="border-bottom mb-1">
          <VendorFilterTabs
            callback={onTabChange}
            tabs={tabs}
            activeTab={activeTab}
          />
        </div>
        <TabContent>
          <div className="p-3">
            <Table type={activeTab.key} filterData={filterData} />
          </div>
        </TabContent>
      </AppCard>
    </>
  );
};

export default ManagePoMain;
