import { useAttachAdditionalData } from "@sk/hooks"; // Add this import
import { AppCard, DateFormatter, NoDataFound, TableHeader } from "@sk/uis";
import { useEffect, useState } from "react";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "Old Status", width: "15%" },
  { label: "New Status", width: "15%" },
  { label: "Remarks", width: "30%" },
  { label: "Updated On", width: "15%" },
  { label: "Updated By", width: "20%" },
];

const GrnViewStatusLog = ({ data }) => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [statusLogs, setStatusLogs] = useState([]);

  // Add this useEffect to set additional data for updatedBy key
  useEffect(() => {
    if (data.length) {
      let tmp = [];
      setAdditionalData(
        data,
        [
          {
            key: "updatedBy",
            api: "user",
            loadingKey: "userLoading",
            dataKey: "_user",
            filter: (ids) => ({ filter: { _id: { $in: ids } } }),
          },
        ],
        (updatedData) => {
          tmp.push(updatedData);
          if (tmp.length === 1) {
            setStatusLogs([...attachAllData(data, tmp)]);
          }
        }
      );
    }
  }, [data, setAdditionalData, attachAllData]);

  return (
    <AppCard>
      <div className="fs-val-md mb-2">
        Showing {statusLogs.length} status logs
      </div>

      <div className="mb-3 custom-scrollbar thin-scrollbar">
        <table className="table table-sm">
          <TableHeader data={headers} isSticky={true} noBg={true} />
          <tbody>
            {!data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Status Logs found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                <td>{index + 1}</td>
                <td>{x.status}</td>
                <td>{x.newStatus}</td>
                <td>{x.remarks}</td>
                <td>
                  <DateFormatter date={x.updatedOn} />
                </td>
                <td>{x._user?.name || x.updatedBy}</td> {/* Update this line */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AppCard>
  );
};

export default GrnViewStatusLog;
