import { useAttachAdditionalData } from "@sk/hooks";
import { AppCard, DateFormatter, KeyVal } from "@sk/uis";
import { memo, useEffect, useState } from "react";

const attachAdditionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "modifiedBy",
    api: "user",
    loadingKey: "modifiedLoading",
    dataKey: "_lastUpdatedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "verifiedBy",
    api: "user",
    loadingKey: "verifiedLoading",
    dataKey: "_verifiedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

function PoBasicDetails({ data = {} }) {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();
  const [basicData, setBasicData] = useState({});

  useEffect(() => {
    if (data._id) {
      setBasicData({ ...data, _userLoading: true });

      let tmp = [];
      // Attach User Info
      setAdditionalData([{ ...data }], attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          const t = [...attachAllData([{ ...data }], tmp)][0];
          setBasicData((v) => ({ ...v, ...t, _userLoading: false }));
        }
      });
    }
  }, [attachAllData, data, setAdditionalData]);

  return (
    <>
      <AppCard>
        <div className="row">
          <div className="col">
            <KeyVal label="Created At" template="col" noBottomSpace={true}>
              <DateFormatter
                date={basicData.createdAt}
                format={"dd MMM yyyy"}
              />
              ,{" "}
              <span className="text-muted">
                by {basicData._createdBy?.name || basicData.createdBy}
              </span>
            </KeyVal>
          </div>
          <div className="col">
            <KeyVal label="Updated At" template="col" noBottomSpace={true}>
              <DateFormatter date={basicData.lastUpdated} />,{" "}
              <span className="text-muted">
                by {basicData._lastUpdatedBy?.name || basicData.modifiedBy}
              </span>
            </KeyVal>
          </div>
          <div className="col">
            <KeyVal label="Approved By" template="col" noBottomSpace={true}>
              {basicData.verifiedAt ? (
                <>
                  <DateFormatter date={basicData.verifiedAt} />,{" "}
                  <span className="text-muted">
                    by {basicData._verifiedBy?.name || basicData.verifiedBy}
                  </span>
                </>
              ) : (
                "--"
              )}
            </KeyVal>
          </div>
        </div>
      </AppCard>
    </>
  );
}

export default memo(PoBasicDetails);
