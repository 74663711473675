import { useAttachAdditionalData } from "@sk/hooks";
import {
  AppCard,
  BtnLink,
  DateFormatter,
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { useEffect, useState } from "react";
import { GrnService, NavService } from "@sk/services";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "Vendor", width: "20%" },
  { label: "Status", width: "15%" },
  { label: "Created On", width: "20%" },
  { label: "Updated On", width: "20%" },
  { label: "Remarks", width: "20%" },
];

const additionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({ filter: { _id: { $in: ids } }, select: "name" }),
  },
  {
    key: "vendorId",
    api: "vendor",
    loadingKey: "vendorLoading",
    dataKey: "_vendor",
    filter: (ids) => ({ filter: { _id: { $in: ids } }, select: "name" }),
  },
];

const GrnAutoDebitAdjustmentLog = ({ grnId }) => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();
  const [auditLogs, setAuditLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAuditLogs = async () => {
      setLoading(true);
      const response = await GrnService.getGrnAutoDebitAdjustmentAuditLogs({
        filter: { grnId },
        type: "list",
      });
      const data = response.resp || [];
      let tmp = [];
      setAdditionalData(data, additionalDataConfig, (updatedData) => {
        tmp.push(updatedData);
        if (tmp.length === additionalDataConfig.length) {
          setAuditLogs([...attachAllData(data, tmp)]);
        }
      });
      setLoading(false);
    };

    if (grnId) {
      fetchAuditLogs();
    }
  }, [grnId, setAdditionalData, attachAllData]);

  const viewVendor = (vendorId) => {
    NavService.openInNewTab("/vendor/view", {
      id: vendorId,
    });
  };

  return (
    <AppCard>
      <div className="fs-val-md mb-2">
        Showing {auditLogs.length} audit logs
      </div>

      <div className="mb-3 custom-scrollbar thin-scrollbar">
        <table className="table table-sm">
          <TableHeader data={headers} isSticky={true} noBg={true} />
          <tbody>
            {loading ? <TableSkeletonLoader cols={headers.length} /> : null}

            {!loading && !auditLogs.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Audit Logs found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {auditLogs.map((x, index) => (
              <tr key={x.grnId} className="fs-val-md">
                <td>{index + 1}</td>
                <td>
                  <BtnLink
                    className="fs-val-md"
                    onClick={() => viewVendor(x.vendorId)}
                  >
                    {x._vendor?.name}
                  </BtnLink>
                  <div className="fs-val-sm text-muted">ID: {x.vendorId}</div>
                </td>
                <td>{x.status}</td>
                <td>
                  <DateFormatter date={x.createdAt} />
                  <div className="text-muted text-truncate">
                    by {x._createdBy?.name}
                  </div>
                </td>
                <td>
                  <DateFormatter date={x.lastUpdated} />
                </td>
                <td>{x.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AppCard>
  );
};

export default GrnAutoDebitAdjustmentLog;
