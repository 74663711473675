import { AppSlide, AppSwiper, SummaryCard } from "@sk/uis";

const config = {
  slidesPerView: "auto",
  spaceBetween: 10,
  mousewheel: {
    forceToAxis: true,
  },
};

const PoSummary = ({ summary }) => {
  return (
    <AppSwiper config={config}>
      {summary.map((e, k) => (
        <AppSlide key={k} autoWidth={true}>
          <SummaryCard
            template={3}
            title={e.label == "All" ? "Total" : e.label}
            value={e.value || 0}
            valueColor={e.color}
            loading={e.loading}
          />
        </AppSlide>
      ))}
    </AppSwiper>
  );
};

export default PoSummary;
