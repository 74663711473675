import { DateInput, SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import AdvanceFilterModal from "../modal/AdvanceFilterModal";
import { AuthService } from "@sk/services";
import { defaultFormData } from "../grnListConstants";

const GrnFilter = ({ callback, formData }) => {
  const { register, getValues, control, setValue } = useForm({
    defaultValues: {
      ...defaultFormData,
    },
  });

  const [advanceFilterModal, setAdvanceFilterModal] = useState({
    show: false,
    filterData: {
      brand: [],
      category: [],
      product: [],
    },
  });

  const advFilterDataRef = useRef({
    brand: [],
    category: [],
    product: [],
  });

  useEffect(() => {
    Object.keys(defaultFormData).forEach((key) => {
      if (formData[key] !== undefined) {
        setValue(key, formData[key]);
      }
    });
  }, [formData, setValue]);

  const debouncedSearch = useCallback(
    debounce((value) => {
      callback({ formData: value });
    }, 500),
    [callback]
  );

  const onSearch = () => {
    debouncedSearch(getValues());
  };

  const advanceFilterModalCb = (result) => {
    if (result.status === "apply" || result.status === "reset") {
      advFilterDataRef.current = result.data;
      setValue("brand", result.data.brand || []);
      setValue("category", result.data.category || []);
      setValue("product", result.data.product || []);
      callback({ formData: getValues() });
    }

    setAdvanceFilterModal({ show: false, filterData: result.data });
  };

  const onDateChange = (onChange) => (val) => {
    onChange(val);
    callback({ formData: getValues() });
  };

  return (
    <>
      <div className="row">
        <div className="col-3">
          <TextInput
            name="search"
            placeholder="Search on ID/PO ID/Vendor"
            label="Search"
            register={register}
            callback={onSearch}
            gap={0}
            size={"sm"}
          />
        </div>
        <div className="col-3">
          <SelectInput
            label="Warehouse"
            register={register}
            name="wh"
            options={warehouses}
            gap={0}
            callback={onSearch}
            size={"sm"}
          />
        </div>
        <div className="col-auto">
          <SelectInput
            name="status"
            label="Status"
            register={register}
            options={statusOptions}
            callback={onSearch}
            size={"sm"}
            gap={0}
          />
        </div>
        <div className="col-2">
          <Controller
            name="dateRange"
            control={control}
            render={({ field: { value, onChange } }) => (
              <DateInput
                placeholder="Choose"
                value={value}
                label="Inward Date"
                callback={onDateChange(onChange)}
                gap={0}
                size={"sm"}
                template={2}
              />
            )}
          />
        </div>
        <div className="col-auto align-self-end">
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={() => {
              setAdvanceFilterModal({ show: true });
            }}
          >
            More Filters
          </button>
        </div>
      </div>

      <AdvanceFilterModal
        show={advanceFilterModal.show}
        callback={advanceFilterModalCb}
        filterData={advanceFilterModal.filterData}
      />
    </>
  );
};

const warehouses = AuthService.getLoggedInEmpWh().map((e) => ({
  label: e.name,
  value: e._id,
}));
warehouses.unshift({
  label: "All Warehouse",
  value: "",
});

const statusOptions = [
  { label: "All Status", value: "" },
  { label: "Created", value: "Created" },
  { label: "Pending Stock Inward", value: "Pending Stock Inward" },
  { label: "Pending Stock Approval", value: "Pending Stock Approval" },
  { label: "Pending Putaway", value: "Pending Putaway" },
  { label: "Partial Putaway", value: "Partial Putaway" },
  { label: "Pending Bill Matching", value: "Pending Bill Matching" },
  { label: "Payment Pending", value: "Payment Pending" },
  { label: "Rejected", value: "Rejected" },
  { label: "Closed", value: "Closed" },
];

export default GrnFilter;
