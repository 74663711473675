import {
  Amount,
  AppCard,
  DateFormatter,
  HighlightText,
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { useEffect, useState } from "react";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "Ref ID", width: "6%" },
  { label: "Type", width: "5%" },
  { label: "Amount", width: "10%", isCentered: true },
  { label: "Created On", width: "12%" },
  { label: "Modified On", width: "12%" },
  { label: "Status", width: "10%" },
  { label: "Reason", width: "10%" },
  { label: "Remarks", width: "15%" },
  { label: "Document", width: "10%" },
];

const GrnViewDebitAdjustment = ({ data, autoDebitAmounts }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [data]);

  return (
    <AppCard>
      <div className="row g-4">
        <div className="col-auto fs-val-md mb-3 text-black-50">
          Showing <span className="text-dark">{data.length}</span> records
        </div>
        <div className="col-auto">
          <HighlightText type="danger" template={2} isSoft={true}>
            Total Debit
            <span className="px-2">:</span>
            <Amount
              value={autoDebitAmounts.totalDebitAmount}
              decimalPlace={2}
            />
          </HighlightText>
        </div>
        <div className="col-auto">
          <HighlightText type="danger" template={2} isSoft={true}>
            Auto Debit
            <span className="px-2">:</span>
            <Amount
              value={autoDebitAmounts.totalAutoDebitAmount}
              decimalPlace={2}
            />
          </HighlightText>
        </div>
        <div className="col-auto">
          <HighlightText type="danger" template={2} isSoft={true}>
            Manual Debit
            <span className="px-2">:</span>
            <Amount
              value={autoDebitAmounts.totalManualDebitAmount}
              decimalPlace={2}
            />
          </HighlightText>
        </div>
      </div>

      <div className="mb-3 custom-scrollbar thin-scrollbar">
        <table className="table table-sm table-hover">
          <TableHeader data={headers} isSticky={true} noBg={true} />
          <tbody>
            {loading ? (
              <TableSkeletonLoader rows={10} cols={headers.length} />
            ) : null}

            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Adjustments found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {!loading &&
              data.map((x, index) => (
                <tr key={x.id} className="fs-val-md">
                  <td>{index + 1}</td>
                  <td>{x.refId}</td>
                  <td>
                    <HighlightText type="primary" template={2} isSoft={true}>
                      {x.type}
                    </HighlightText>
                  </td>
                  <td className="text-center">
                    <Amount value={x.totalAmount} decimalPlace={2} />
                  </td>
                  <td>
                    <DateFormatter date={x.createdAt} />
                  </td>
                  <td>
                    <DateFormatter date={x.modifiedAt} />
                  </td>
                  <td>{x.status}</td>
                  <td>
                    <HighlightText type="success" template={2} isSoft={true}>
                      {x.debitReason}
                    </HighlightText>
                  </td>
                  <td>{x.remarks}</td>
                  <td>{x.document}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </AppCard>
  );
};

export default GrnViewDebitAdjustment;
