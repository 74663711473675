import { PoDataService, NavService } from "@sk/services";
import {
  Amount,
  HighlightText,
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
  BtnLink,
} from "@sk/uis";
import { useEffect, useState } from "react";

const containerStyle = {
  overflowX: "auto",
  maxHeight: "calc(100vh - 300px)",
};

const tableStyle = {
  minWidth: "2000px", // Updated table width
};

const tableHeaders = [
  { label: "Sl No", width: "3%", isSticky: true, stickyPosition: "left" },
  {
    label: "Product",
    width: "15%",
    isSticky: true,
    stickyPosition: "left",
    style: { left: "4.5%" },
  },
  { label: "PID", width: "5%" },
  { label: "Requested Qty", width: "5%" },
  { label: "Purchase Price", width: "5%" },
  { label: "Sub Total", width: "5%" },
  { label: "Status", width: "5%" },
  { label: "MRP", width: "5%" },
  { label: "B2B Price", width: "5%" },
  { label: "CM1", width: "3%" },
  { label: "Base Margin", width: "5%" },
  { label: "Scheme Margin", width: "5%" },
  { label: "Final Margin", width: "5%" },
  { label: "Category", width: "8%" },
  { label: "Brand", width: "8%" },
];

const stickySerialNumberStyle = {
  left: 0,
};

const stickyProductNameStyle = {
  left: "4.5%",
};

const tdStyle = {
  requestedQty: { backgroundColor: "#f0f8ff" }, // Light blue
  subTotal: { backgroundColor: "#f0fff0" }, // Light green
  cm1: { backgroundColor: "#fff0f5" }, // Light pink
};

const PoProducts = ({ data, whId }) => {
  const [productDetails, setProductDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        setLoading(true);
        const productData = await PoDataService.updateProductDetails(data);
        setProductDetails(productData);
      } catch (error) {
        console.error("Error fetching product details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [data, whId]);

  const getCM1Color = (cm1Value) => {
    const numericCM1 = parseFloat(cm1Value);
    if (numericCM1 > 0) return "text-success";
    if (numericCM1 < 0) return "text-danger";
    return "text-muted";
  };

  const viewProduct = (productId) => {
    NavService.openInNewTab("/cms/product/view", { id: productId });
  };

  return (
    <div>
      <div className="fs-val-sm mb-1 text-muted">
        Showing <span className="text-dark">{data.length}</span> Products
      </div>
      <div
        style={containerStyle}
        className="mb-3 custom-scrollbar thin-scrollbar tbl-scroll-container fixed-width-table"
      >
        <table className="table table-sm" style={tableStyle}>
          <TableHeader data={tableHeaders} isSticky={true} noBg={true} />
          <tbody className="fs-val-md">
            {loading && (
              <TableSkeletonLoader rows={10} cols={tableHeaders.length} />
            )}

            {!loading && !productDetails.length ? (
              <tr>
                <td colSpan={tableHeaders.length}>
                  <NoDataFound>No Products found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {!loading &&
              productDetails.map((item, index) => (
                <tr key={index}>
                  <td
                    className="sticky-column bg-white"
                    style={stickySerialNumberStyle}
                  >
                    {index + 1}
                  </td>
                  <td
                    className="sticky-column bg-white"
                    style={stickyProductNameStyle}
                  >
                    <BtnLink
                      className="fs-val-md text-wrap"
                      onClick={() => viewProduct(item.productId)}
                    >
                      {item._prdDetails?.name || item.name}
                    </BtnLink>
                  </td>
                  <td>{item.productId}</td>
                  <td style={tdStyle.requestedQty}>{item._requestedQty}</td>
                  <td>
                    <Amount value={item._purchasePrice} decimalPlace={2} />
                  </td>
                  <td style={tdStyle.subTotal}>
                    <Amount value={item._subTotal} decimalPlace={2} />
                  </td>
                  <td>
                    <HighlightText
                      type={item._status === "Open" ? "danger" : "success"}
                      template={2}
                      className="text-capitalize"
                    >
                      {item._status}
                    </HighlightText>
                  </td>
                  <td>
                    <Amount value={item._mrp} decimalPlace={2} />
                  </td>
                  <td>
                    <Amount value={item._b2bPrice} decimalPlace={2} />
                  </td>
                  <td style={tdStyle.cm1}>
                    <span className={getCM1Color(item._cm1)}>
                      {item._cm1 || 0}%
                    </span>
                  </td>
                  <td>
                    {item._baseMargin === "%" ? (
                      `${item._baseMargin}%`
                    ) : (
                      <Amount value={item._baseMargin} decimalPlace={2} />
                    )}
                  </td>
                  <td>
                    {item._schemeMarginType === "%" ? (
                      `${item._schemeMargin}%`
                    ) : (
                      <Amount value={item._schemeMargin} decimalPlace={2} />
                    )}
                  </td>
                  <td>
                    {item._finalMarginType === "%" ? (
                      `${item._finalMargin}%`
                    ) : (
                      <Amount value={item._finalMargin} decimalPlace={2} />
                    )}
                  </td>
                  <td>{item._category?.name}</td>
                  <td>{item._brand?.name}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PoProducts;
