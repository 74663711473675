import { AuthService, PoService } from "@sk/services";
import { DateInput, TextInput, SelectInput } from "@sk/uis";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import AdvanceFilterModal from "../modals/AdvanceFilterModal";

const formatWh = (wh) => {
  return wh.map((x) => ({ label: x.name, value: x._id }));
};

const statusOptions = PoService.getPoStatuses();
const createdOnDateConfig = { mode: "range" };
const expectedOnDateConfig = { mode: "single" };

function PoFilter({ callback }) {
  const { control, register, getValues, setValue } = useForm({
    defaultValues: {
      vendor: "",
      createdAt: [],
      expectedAt: [],
      status: "",
    },
  });

  const [moreFilterModal, setMoreFilterModal] = useState({
    show: false,
    filterData: {
      brand: [],
      category: [],
      product: [],
    },
  });

  const [warehouses, setWarehouses] = useState(
    formatWh(AuthService.getWhOnState(AuthService.getDefaultWhState()))
  );

  const onStateChange = (state) => {
    setWarehouses(formatWh(AuthService.getWhOnState(state)));
    setValue("wh", "");
    triggerCallback();
  };

  const debouncedSearch = useCallback(
    debounce(() => {
      triggerCallback();
    }, 500),
    []
  );

  const onSearch = () => {
    debouncedSearch();
  };

  const onWhChange = () => {
    triggerCallback();
  };

  const onDateChange = (onChange) => {
    return (val) => {
      onChange(val);
      triggerCallback();
    };
  };

  const triggerCallback = () => {
    const values = getValues();
    callback({ status: "apply", data: values });
  };

  const moreFilterCb = (result) => {
    if (result.status == "apply" || result.status == "reset") {
      setValue("brand", result.data.brand || []);
      setValue("category", result.data.category || []);
      setValue("product", result.data.product || []);
      triggerCallback();
    }

    setMoreFilterModal({ show: false, filterData: result.data });
  };

  const resetFilter = () => {
    // Reset form to default values
    Object.keys(getValues()).forEach((key) => {
      setValue(key, Array.isArray(getValues(key)) ? [] : "");
    });
    callback({ status: "reset" });
  };

  return (
    <>
      <div className="row">
        {/* Vendor Search */}
        <div className="col">
          <TextInput
            label="Search"
            register={register}
            name="search"
            placeholder="Search by Id/Vendor Id/Vendor Name"
            isMandatory={true}
            gap={0}
            callback={onSearch}
          />
        </div>

        {/* Created On Date Filter */}
        <div className="col">
          <label className="fw-semibold mb-1 fs-val-md">Created On</label>
          <Controller
            name="createdAt"
            control={control}
            render={({ field: { value, onChange } }) => (
              <DateInput
                placeholder="Choose"
                value={value}
                callback={onDateChange(onChange)}
                config={createdOnDateConfig}
                gap={1}
                size="sm"
                template={2}
              />
            )}
          />
        </div>

        {/* Expected On Date Filter */}
        <div className="col">
          <label className="fw-semibold mb-1 fs-val-md">Expected On</label>
          <Controller
            name="expectedAt"
            control={control}
            render={({ field: { value, onChange } }) => (
              <DateInput
                placeholder="Choose"
                value={value}
                callback={onDateChange(onChange)}
                config={expectedOnDateConfig}
                gap={1}
                size="sm"
              />
            )}
          />
        </div>

        {/* Status Dropdown */}
        <div className="col">
          <SelectInput
            type="text"
            label="Status"
            register={register}
            options={statusOptions}
            name="status"
            gap={0}
            callback={() => {
              triggerCallback();
            }}
          />
        </div>
        <div className="col-auto align-self-end">
          <button
            className="btn btn-sm btn-outline-secondary me-2"
            onClick={() => setMoreFilterModal({ show: true })}
          >
            More Filters
          </button>
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={resetFilter}
          >
            Reset
          </button>
        </div>
      </div>

      <AdvanceFilterModal
        show={moreFilterModal.show}
        callback={moreFilterCb}
        filterData={moreFilterModal.filterData}
      />
    </>
  );
}

export default PoFilter;
