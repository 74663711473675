import { Amount } from "@sk/uis";
import { memo } from "react";

const PoItem = ({ data = {}, callback, mode = "edit" }) => {
  const handleQtyChange = (e) => {
    const value = e.target.value;
    callback && callback({ action: "qty", data: { qty: value } });
  };

  const handleSchemeMarginChange = (e) => {
    const value = e.target.value;
    callback &&
      callback({
        action: "schemeMargin",
        data: { additionalMargin: value },
      });
  };

  return (
    <div className="bg-white p-3 rounded-3 mb-3">
      <div className="fs-val-lg fw-semibold mb-2">
        {data?.name} ({data?._id})
      </div>

      <div className="row fs-val-sm mb-3">
        <div className="col-auto">
          <span className="text-muted">Brand: </span>
          <span>{data.brand?.name}</span>
        </div>
        <div className="col-auto">
          <span className="text-muted">Category: </span>
          <span>{data.category?.name}</span>
        </div>
        <div className="col-auto">
          <span className="text-muted">Barcode: </span>
          <span>{data.barcode || "N/A"}</span>
        </div>
        <div className="col-auto">
          <span className="text-muted">HSN: </span>
          <span>{data?.hsnNumber || "N/A"}</span>
        </div>
        <div className="col-auto">
          <span className="text-muted">Tax: </span>
          <span>{data?.tax || 0}%</span>
        </div>
      </div>

      <div className="row">
        <div className="col-auto fs-val-sm">
          <div className="bg-light px-3 py-2 rounded-3 h-100">
            <div className="text-primary mb-2">Sales Details</div>
            <div className="d-flex justify-content-between mb-2 gap-4">
              <span className="text-black-50">30 days</span>
              <span>{data?.sales?.lastThirtyDays || 0}</span>
            </div>
            <div className="d-flex justify-content-between mb-2 gap-4">
              <span className="text-black-50">60 days</span>
              <span>{data?.sales?.lastSixtyDays || 0}</span>
            </div>
            <div className="d-flex justify-content-between mb-2 gap-4">
              <span className="text-black-50">90 days</span>
              <span>{data?.sales?.lastNinetyDays || 0}</span>
            </div>
          </div>
        </div>

        <div className="col-auto fs-val-sm">
          <div className="bg-light px-3 py-2 rounded-3 h-100">
            <div className="text-primary mb-2">Price Details</div>
            <div className="d-flex justify-content-between mb-2 gap-4">
              <span className="text-black-50">MRP</span>
              <span className="text-danger fw-bold">
                <Amount value={data?.mrp || 0} decimalPlace={2} />
              </span>
            </div>
            <div className="d-flex justify-content-between mb-2 gap-4">
              <span className="text-black-50">B2B Price</span>
              <span>
                <Amount value={data?.b2bPrice || 0} decimalPlace={2} />
              </span>
            </div>
            <div className="d-flex justify-content-between mb-2 gap-4">
              <span className="text-black-50">CM1 (%)</span>
              <span>{data?.cm1 || 0}%</span>
            </div>
          </div>
        </div>

        <div className="col-auto fs-val-sm">
          <div className="bg-light px-3 py-2 rounded-3 h-100">
            <div className="text-primary mb-2">Stock Details</div>
            <div className="d-flex justify-content-between gap-4">
              <div>
                <div className="d-flex justify-content-between mb-2 gap-4">
                  <span className="text-black-50">Case Qty</span>
                  <span>{data?.caseQty || 0}</span>
                </div>
                <div className="d-flex justify-content-between mb-2 gap-4">
                  <span className="text-black-50">Inner Case Qty</span>
                  <span>{data?.innerPackQty || 0}</span>
                </div>
                <div className="d-flex justify-content-between mb-2 gap-4">
                  <span className="text-black-50">Pending Orders</span>
                  <span>{data?.pendingStock || 0}</span>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-between mb-2 gap-4">
                  <span className="text-black-50">Current Stock</span>
                  <span className="text-success fw-bold">
                    {data?.currentStock || 0}
                  </span>
                </div>
                <div className="d-flex justify-content-between mb-2 gap-4">
                  <span className="text-black-50">Open PO</span>
                  <span className="text-danger fw-bold">
                    {data?.openPo || 0}
                  </span>
                </div>
                <div className="d-flex justify-content-between mb-2 gap-4">
                  <span className="text-black-50">Suggested</span>
                  <span>{data?.suggestedQuantity || 0}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-auto fs-val-sm">
          <div className="bg-light px-3 py-2 rounded-3 relative">
            <div className="text-primary mb-2">Margin Details</div>

            <div className="d-flex justify-content-between mb-2 gap-4 ">
              <span className="fs-val-sm text-black-50">Base Margin</span>
              <span>{data?.baseMargin || 0}%</span>
            </div>

            <div className="d-flex justify-content-between mb-2 gap-4 align-items-center">
              <span className="fs-val-sm">Scheme Discount</span>
              <input
                className="form-control form-control-sm"
                style={{ width: "100px" }}
                value={data?.additionalMargin || 0}
                onChange={handleSchemeMarginChange}
                disabled={mode !== "edit"}
              />
            </div>

            <div className="d-flex justify-content-between mb-2">
              <span className="fs-val-sm text-black-50">Effective Margin</span>
              <span>{data?.finalMargin || 0}%</span>
            </div>
          </div>
        </div>

        <div className="col-auto fs-val-sm">
          <div className="bg-light px-3 py-2 rounded-3 h-100">
            <div className="text-primary mb-2">Purchase Details</div>

            <div className="d-flex justify-content-between mb-2 gap-4">
              <span className="text-black-50">Purchase Price</span>
              <span>
                <Amount value={data?.purchasePrice || 0} decimalPlace={2} />
              </span>
            </div>

            <div className="d-flex justify-content-between mb-2 gap-4 align-items-center">
              <span>Purchase Qty</span>
              <input
                type="number"
                className="form-control form-control-sm"
                style={{ width: "100px" }}
                placeholder="Enter qty"
                value={data.qty || 0}
                onChange={handleQtyChange}
                disabled={mode !== "edit"}
              />
            </div>

            <div className="d-flex justify-content-between mb-2 gap-4">
              <span className="text-black-50">Last Purchase</span>
              <span>
                <Amount
                  value={data?.previousPurchasePrice || 0}
                  decimalPlace={2}
                />
              </span>
            </div>
          </div>
        </div>

        <div className="col text-center fs-val-sm align-self-center">
          <div>Total Value</div>
          <div className="fs-val-lg fw-bold text-primary">
            <Amount value={data?.totalValue || 0} decimalPlace={2} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PoItem);
