import React, { useState, useEffect } from "react";
import {
  GrnService,
  OmsService,
  ProductService,
  WarehouseService,
  NavService,
} from "@sk/services";
import {
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
  BtnLink,
} from "@sk/uis";

const containerStyle = {
  overflowX: "auto",
  maxHeight: "calc(100vh - 300px)",
};

const tableStyle = {
  minWidth: "1300px",
};

const header = [
  { label: "Sl No", width: "3%", isSticky: true, stickyPosition: "left" },
  {
    label: "Product",
    width: "20%",
    isSticky: true,
    stickyPosition: "left",
    style: { left: "4.5%" },
  },
  { label: "PID", width: "5%" },
  { label: "Case", width: "5%", isCentered: true },
  { label: "Inner Case", width: "5%", isCentered: true },
  { label: "Available Stock", width: "6%", isCentered: true },
  { label: "PO Qty", width: "5%", isCentered: true },
  { label: "Received", width: "5%", isCentered: true },
  { label: "Unfilled", width: "5%", isCentered: true },
  { label: "Damaged", width: "5%", isCentered: true },
  { label: "Shortage", width: "5%", isCentered: true },
  { label: "Last Sold", width: "5%", isCentered: true },
  { label: "Pending Sold", width: "5%", isCentered: true },
];

const stickySerialNumberStyle = {
  left: 0,
};

const stickyProductNameStyle = {
  left: "4.5%",
};

const PoProductQty = ({ products, poId, whId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const updatedProducts = await getData(products, poId, whId);
        console.log(updatedProducts);
        setData(updatedProducts);
      } catch (error) {
        console.error("Error fetching product data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (products && products.length > 0) {
      fetchData();
    }
    console.log(products, poId, whId);
  }, [products, poId, whId]);

  const formatValue = (value) => {
    return value === 0 || value === null ? (
      <span className="text-muted">0</span>
    ) : (
      value
    );
  };

  const viewProduct = (id) => {
    NavService.openInNewTab("/cms/product/view", { id });
  };

  return (
    <div>
      <div className="fs-val-sm mb-1 text-muted">
        Showing <span className="text-dark">{data.length}</span> Products
      </div>
      <div
        style={containerStyle}
        className="mb-3 custom-scrollbar thin-scrollbar tbl-scroll-container fixed-width-table"
      >
        <table className="table table-sm" style={tableStyle}>
          <TableHeader data={header} isSticky={true} noBg={true} />
          <tbody className="fs-val-md">
            {loading && <TableSkeletonLoader rows={10} cols={header.length} />}

            {!loading && !data.length ? (
              <tr>
                <td colSpan={header.length}>
                  <NoDataFound>No Products found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {!loading &&
              data.map((x, i) => (
                <tr key={i}>
                  <td
                    className="sticky-column bg-white"
                    style={stickySerialNumberStyle}
                  >
                    {i + 1}
                  </td>
                  <td
                    className="sticky-column bg-white"
                    style={stickyProductNameStyle}
                  >
                    <BtnLink
                      className="fs-val-md text-wrap"
                      onClick={() => viewProduct(x.productId)}
                    >
                      {x._prdDetails?.name || x.name}
                    </BtnLink>
                  </td>
                  <td>{x.productId}</td>
                  <td className="text-center">{formatValue(x._caseQty)}</td>
                  <td className="text-center">
                    {formatValue(x._innerPackQty)}
                  </td>
                  <td className="text-center">
                    {formatValue(x._availableQty)}
                  </td>
                  <td className="text-center">
                    {formatValue(x._requestedQty)}
                  </td>
                  <td className="text-center">{formatValue(x._receivedQty)}</td>
                  <td className="text-center">{formatValue(x._unfilledQty)}</td>
                  <td className="text-center">{formatValue(x._damagedQty)}</td>
                  <td className="text-center">{formatValue(x._shortageQty)}</td>
                  <td className="text-center">{formatValue(x._lastSoldQty)}</td>
                  <td className="text-center">
                    {formatValue(x._pendingOrders)}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const getData = async (products, poId, whId) => {
  // Fetch GRN data with more detailed filtering
  const grnData = await GrnService.getList({
    page: 1,
    count: 1000,
    filter: { "productDetails.poId": poId },
  });

  // Fetch product details for case and inner pack quantities (bulk call for efficiency)
  const productIds = [...new Set(products.map((p) => p.productId))];
  const productDetails = await ProductService.getList({
    page: 1,
    count: productIds.length,
    filter: { _id: { $in: productIds } },
    select: "caseQty,innerPackQty",
  });

  // Map over products and fetch stock, sales, and last sold data for each product using Promise.all
  const updatedProducts = await Promise.all(
    products.map(async (p) => {
      // Find the matching GRN item by product ID in productDetails
      const matchedGrn = grnData?.resp?.find((grnItem) =>
        grnItem.productDetails.some(
          (detail) => detail.productId === p.productId
        )
      );

      // Get the specific product detail from the matched GRN
      const grnProductDetail = matchedGrn
        ? matchedGrn.productDetails.find(
            (detail) => detail.productId === p.productId
          )
        : null;

      // API calls for individual product using Promise.all
      const [stockResponse, pendingOrderResponse, lastSoldResponse] =
        await Promise.all([
          WarehouseService.getStockInfo(whId, p.productId, {}),
          OmsService.getProductCountById(p.productId, whId),
          OmsService.getOrderList({
            filter: {
              "subOrders.products.id": p.productId,
            },
            select: "createdAt",
          }),
        ]);

      // Match data from bulk responses
      const product = productDetails?.resp?.find(
        (pr) => pr._id === p.productId
      );

      // Extract data from individual API responses
      const stock = stockResponse?.resp || {};
      const pending = pendingOrderResponse?.resp?.[0];
      const lastSold = lastSoldResponse?.resp?.[0];

      // Update product with quantity details
      return {
        ...p,
        _caseQty: product?.caseQty || 0,
        _innerPackQty: product?.innerPackQty || 0,
        _availableQty: stock.avaiableQty || 0,
        _requestedQty: p.quantity?.requested || 0,
        _receivedQty: grnProductDetail?.receivedQuantity || 0,
        _unfilledQty:
          (p.quantity?.requested || 0) -
          (grnProductDetail?.receivedQuantity || 0),
        _damagedQty: grnProductDetail?.damagedQty || 0,
        _shortageQty: grnProductDetail?.shortageQty || 0,
        _lastSoldQty: lastSold?.quantity || 0,
        _pendingOrders: pending?.quantity || 0,
        _nonSellableQty: stock.nonSellableQty || 0,
        _onHoldQty: stock.onHoldQty || 0,
        _totalStock: stock.totalStock || 0,
      };
    })
  );

  return updatedProducts;
};

export default PoProductQty;
