import { ChipsArray, Toaster } from "@sk/uis";
import { produce } from "immer";
import uniqBy from "lodash/uniqBy";
import { memo, useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const BulkPidModal = ({ show, onClose, onSubmit, products = [] }) => {
  const [value, setValue] = useState([]);

  const [existingPids, setExistingPids] = useState([]);

  useEffect(() => {
    if (show) {
      setValue([]);
    }
  }, [show]);

  const onChipAddCb = useCallback(
    (val) => {
      let v = val;

      const isArr = Array.isArray(val) ? true : false;

      setExistingPids([]);

      // check product id already added in the table
      if (isArr) {
        v.forEach((x) => {
          const found =
            products.findIndex((e) => e._id == x.label.trim()) != -1;
          if (found) {
            setExistingPids(
              produce((draft) => {
                draft.push(x.label);
              })
            );
            return;
          }
        });
      } else {
        const found = products.findIndex((e) => e._id === v.label.trim()) != -1;
        if (found) {
          setExistingPids(
            produce((draft) => {
              draft.push(v.label);
            })
          );
          return;
        }
      }

      if (Array.isArray(val)) {
        // pick only unique values
        v = uniqBy([...value, ...val], "label");
      } else {
        // if not an array value - check data already exists
        const d = value.filter((x) => x.label == val.label).length > 0;
        if (d) {
          Toaster.error(v.label + " already exists");
          return;
        }
      }
      setValue(
        produce((draft) => {
          if (Array.isArray(v)) {
            // empty the array and push the data
            draft.length = 0;
            v.forEach((x) => {
              draft.push(x);
            });
          } else {
            draft.push(val);
          }
        })
      );
    },
    [products, value]
  );

  const onChipRemoveCb = useCallback((index) => {
    setValue(
      produce((draft) => {
        draft.splice(index, 1);
      })
    );
  }, []);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">Bulk Product IDs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-1">Enter comma separated Product Ids</div>
        <ChipsArray
          data={value}
          onAdd={onChipAddCb}
          onRemove={onChipRemoveCb}
        />
        <div className="form-text">Only max 20 product ids are allowed</div>
        {existingPids.length > 0 ? (
          <div className="text-danger my-2 fs-7">
            {existingPids.join(",")} already exists on the below table
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onSubmit(value)}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(BulkPidModal);
