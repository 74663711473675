/* eslint-disable jsx-a11y/interactive-supports-focus */
import {
  AjaxService,
  CommonService,
  NavService,
  PoService,
} from "@sk/services";
import { Amount, DateFormatter, Spinner, Toaster } from "@sk/uis";
import classNames from "classnames";
import { useCallback, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "../ManagePo.module.scss";
import Overview from "../product/components/Overview";
import ManageMrpModal from "./ManageMrpModal";

// Canvas Style
const style = {
  canvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  canvasStyle: {
    width: "90%",
  },
};

const PoPreviewModal = ({
  show,
  closeModal,
  vendor,
  warehouseDetails,
  products,
  totalPoQuantity,
  totalB2bPrice,
  totalCm1,
  totalPoValue,
  remarks,
  editPoDetails,
  deliveryDate,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const mrpModalRef = useRef({ data: {}, index: -1 });

  const [showMrpModal, setShowMrpModal] = useState(false);

  const openShowMrpModal = useCallback(() => {
    setShowMrpModal(true);
  }, []);

  const closeMrpModal = useCallback(() => {
    mrpModalRef.current.data = {};
    mrpModalRef.current.index = -1;
    setShowMrpModal(false);
  }, []);

  const productMrpCb = useCallback(
    (index) => {
      mrpModalRef.current.data = products[index];
      mrpModalRef.current.index = index;
      openShowMrpModal();
    },
    [openShowMrpModal, products]
  );

  const navigate = useNavigate();

  const onPoSave = async () => {
    setSubmitting(true);

    let p;
    let r;

    if (editPoDetails._id) {
      p = {
        ...editPoDetails,
        products: prepareProductsPayload(products),
        expectedAt: deliveryDate,
        remarks,
      };
      r = await PoService.updatePo(p);
    } else {
      p = {
        contact: {
          name: vendor._contact.name,
          designation: vendor._contact.designation,
          email: vendor._contact.email,
          isOwner: vendor._contact.isOwner,
          credit_days: vendor.credit_days,
          _id: vendor._contact._id,
          vendorName: vendor.name,
          vendorId: vendor._id,
          address:
            vendor.address.line1 +
            "," +
            vendor.address.line2 +
            "," +
            vendor.district +
            "," +
            vendor.state,
          state: vendor.state,
          vendorGST: vendor.gst_no,
        },
        products: prepareProductsPayload(products),
        expectedAt: deliveryDate,
        remarks: remarks,
        poValue: totalPoValue,
        whId: warehouseDetails._id,
        purchaseType: "NORMAL",
        createdAt: new Date(),
      };
      r = await PoService.createPo(p);
    }

    setSubmitting(false);
    if (r.statusCode != 200) {
      const error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg);
      return;
    }
    let msg = editPoDetails._id
      ? "PO Created Successfully"
      : "PO Updated Successfully";
    Toaster.success(msg);
    closeModal();
    NavService.to(navigate, "/purchase-order/list");
  };

  const prepareProductsPayload = (products) => {
    return products.map((x) => {
      let temp = {
        productId: x._id,
        name: x.name,
        mrp: x.mrp,
        availableStock: x.currentStock,
        dealerPrice: x.dealerPrice,
        openPoQty: x.qty,
        quantity: {
          suggested: x.suggestedQuantity,
          requested: x.qty * 1,
        },
        unitPrice: x.purchasePrice,
        total: x.totalValue,
        dealCount: x?.resp?._dealCount,
        caseQuantity: x.caseQty,
        pendingOrderProductQty: x.pendingStock,
        marginDealerPrice: x.dealerMarginPrice,
        margins: {
          bMargin: x.baseMargin,
          sMargin: x.additionalMargin,
          sMarginType: x.marginType == "percentage" ? "%" : "%",
        },
        orderQty: x.qty,
        order30: x.purchase.lastThirtyDays,
        order60: x.purchase.lastSixtyDays,
        order90: x.purchase.lastThirtyDays,
        categoryId: x.resp.category[0],
        brandId: x.resp.brand[0],
        delivery_chalan: x.resp.delivery_chalan,
        caseQty: x.caseQty,
        innerPackQty: x.innerPackQty,
        barcode: x.barcode,
        HSNNumber: x.hsnNumber,
        tax: x.tax,
        b2bPrice: x.b2bPrice,
        brands: [{ _id: x.resp.brand[0], name: x.resp.brand }],
        categories: [{ _id: x.resp.category[0], name: x.resp.category }],
        previousPurchasePrice: x?.previousPurchasePrice,
        priceToCompute: 1,
        totMargin: "",
        lastRequiredQtyValue: "",
        b2bTotal: "",
        mrpList: [...x.mrpList, x.mrp],
      };

      return temp;
    });
  };

  return (
    <>
      <Offcanvas
        show={show}
        placement="end"
        onHide={closeModal}
        backdrop="static"
        style={style.canvasStyle}
      >
        <Offcanvas.Header
          closeButton
          style={style.canvasHeaderStyle}
          closeVariant="white"
        >
          <Offcanvas.Title>
            <div>Create Purchase Order</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Overview vendor={vendor} warehouse={warehouseDetails} />

          <div className="p-2">
            <div className="font-18 mb-3">Purchase Order Summary</div>

            {products?.length > 0 &&
              products.map((x, index) => (
                <div className="row border mb-2" key={x._id}>
                  <div className="col-auto align-self-center p-4 text-center">
                    1
                  </div>
                  <div className="col-4 border-start border-end p-3">
                    <div className="text-primary mb-3 fs-val-normal">
                      {x.name}
                    </div>

                    <div className="fs-val-md mb-3">
                      <span className="fw-semibold">Brand /Category :</span>
                      <span className=" text-primary">
                        {x.brand.name} / {x.category.name}
                      </span>
                    </div>

                    <div className="fs-val-md p">
                      <div className="row">
                        <div className="col-auto">
                          <span className="text-muted me-1">barcode :</span>
                          <span className="text-dark fw-semibold">
                            {x.barcode}
                          </span>
                        </div>

                        <div className="col-4">
                          <span className="text-muted me-1">HSN No :</span>
                          <span className="text-dark fw-semibold">
                            {x.hsnNumber}
                          </span>
                        </div>

                        <div className="col-auto">
                          <span className="text-muted me-1">Tax :</span>
                          <span className="text-dark fw-semibold">
                            {x.tax} %
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-5 border-end p-3">
                    <div className="row">
                      <div className="col-6">
                        <div className="fs-val-normal mb-3">
                          Pricing Details (Rs.)
                        </div>
                      </div>
                      <div className="col-6">
                        <span className="me-2 mb-1">
                          Previous Purchase Price :
                        </span>
                        <span className="text-primary fw-semibold">
                          <Amount
                            value={x?.previousPurchasePrice || 0}
                            decimalPlace={2}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="row fs-val-md">
                      <div className="col-3 mb-2">
                        <span className="text-muted me-1">MRP :</span>
                        <span className="text-danger fw-semibold">
                          <Amount value={x.mrp} decimalPlace={2} />
                        </span>
                        {x?.mrpList?.length ? (
                          <span
                            className={classNames(
                              "bi bi-eye text-primary cursor-pointer",
                              styles.lbl
                            )}
                            role={"button"}
                            onClick={() => productMrpCb(index)}
                          ></span>
                        ) : null}
                      </div>

                      <div className="col-3 mb-2">
                        <span className="text-muted me-1">B2B Price :</span>
                        <span className="text-warning fw-semibold">
                          <Amount value={x.b2bPrice} decimalPlace={2} />
                        </span>
                      </div>

                      <div className="col-4 mb-2">
                        <span className="text-muted me-1">
                          Purchase Price :
                        </span>
                        <span className="text-dark fw-semibold">
                          <Amount value={x.purchasePrice} decimalPlace={2} />
                        </span>
                      </div>

                      <div className="col-2 mb-2">
                        <span className="text-muted me-1">CM 1 :</span>
                        <span className="text-success fw-semibold">
                          {x.cm1} %
                        </span>
                      </div>

                      <div className="col-3 mb-2">
                        <span className="text-muted me-1">Margins :</span>
                        <span className="text-success fw-semibold">
                          {x.baseMargin} %
                        </span>
                      </div>

                      <div className="col-3 mb-2">
                        <span className="text-muted me-1">Final Margin :</span>
                        <span className="text-success fw-semibold">
                          {x.finalMargin} %
                        </span>
                      </div>

                      <div className="col-auto mb-2">
                        <span className="text-muted me-1">
                          Additional Margins :
                        </span>
                        <span className="text-success fw-semibold">
                          {x.additionalMargin} %
                        </span>
                      </div>

                      <div className="col-12 mb-2">
                        <span className="text-dark me-1 fs-val-normal">
                          Purchase Qty :
                        </span>
                        <span className="text-dark fw-semibold me-1">
                          {x.qty}{" "}
                        </span>
                        <span className="text-muted"> ({x.qtyType}) </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-2 align-self-center">
                    <div className="fs-val-lg text-center">Total Value</div>
                    <div className="fs-val-lg text-center text-warning">
                      <Amount value={x.totalValue} decimalPlace={2} />
                    </div>
                  </div>
                </div>
              ))}

            <div className="row mb-4">
              <div className="col-auto fs-val-md">
                <span className="text-dark me-1 fw-semibold">
                  Expected On :{" "}
                </span>
                <span>
                  <DateFormatter date={deliveryDate} />
                </span>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-4 fw-semibold text-dark fs-val-md text-end">
                    Purchase Order Remarks
                  </div>
                  <div className="col-8">{remarks}</div>
                </div>
              </div>
            </div>

            <div className="row">
              <table
                className={classNames(
                  "table  table-bordered table-centered",
                  styles.summary
                )}
                style={style.canvasHeaderStyle}
              >
                <tbody>
                  <tr>
                    <td width="35%">
                      <div
                        className={classNames(styles["summary-lbl"], "fw-bold")}
                      >
                        Total Purchase Order Summary
                      </div>
                    </td>
                    <td width="20%">
                      <div className="mb-2">
                        <span
                          className={classNames(
                            styles["summary-lbl"],
                            "fw-bold me-2"
                          )}
                        >
                          Total B2B price:
                        </span>
                        <span
                          className={classNames(
                            styles["summary-lbl"],
                            "fw-bold text-primary"
                          )}
                        >
                          <Amount value={totalB2bPrice} decimalPlace={2} />
                        </span>
                      </div>
                      <div>
                        <span
                          className={classNames(
                            styles["summary-lbl"],
                            "fw-bold me-2"
                          )}
                        >
                          Total CM 1:
                        </span>
                        <span
                          className={classNames(
                            styles["summary-lbl"],
                            "fw-bold text-success"
                          )}
                        >
                          {CommonService.roundedByDecimalPlace(totalCm1, 2)} %
                        </span>
                      </div>
                    </td>
                    <td width="20%">
                      <div className="mb-2">
                        <span
                          className={classNames(
                            styles["summary-lbl"],
                            "fw-bold me-2"
                          )}
                        >
                          Total PO Qty:
                        </span>
                        <span
                          className={classNames(
                            styles["summary-lbl"],
                            "fw-bold text-dark"
                          )}
                        >
                          {totalPoQuantity}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="mb-2">
                        <span
                          className={classNames(
                            styles["summary-lbl"],
                            "fw-bold me-2"
                          )}
                        >
                          Total PO Value:
                        </span>
                        <span
                          className={classNames(
                            styles["summary-lbl"],
                            "fw-bold text-warning"
                          )}
                        >
                          <Amount value={totalPoValue} decimalPlace={2} />
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Offcanvas.Body>
        <Offcanvas.Header>
          <div className="col-12 text-end">
            <button className="btn btn-outline-dark me-2" onClick={closeModal}>
              Cancel
            </button>
            <button
              className="btn btn-success me-2"
              onClick={onPoSave}
              disabled={submitting}
            >
              Save {submitting && <Spinner type="dots" />}
            </button>
          </div>
        </Offcanvas.Header>
      </Offcanvas>
      <ManageMrpModal
        closeModal={closeMrpModal}
        show={showMrpModal}
        index={mrpModalRef.current.index}
        mrpList={mrpModalRef.current.data.mrpList}
        pId={mrpModalRef.current.data._id}
        currentMrp={mrpModalRef.current.data.mrp}
        mode="view"
      />
    </>
  );
};

export default PoPreviewModal;
