import { useAttachAdditionalData, useFetchUrlQueryString } from "@sk/hooks";
import { NavService, PoService } from "@sk/services";
import {
  Amount,
  AppCard,
  AuditLogTable,
  BtnLink,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PageInfo,
  PageLoader,
  Tabs,
} from "@sk/uis";
import { each } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PoApproved from "./components/PoApproved";
import PoBasicDetails from "./components/PoBasicDetails";
import PoGrn from "./components/PoGrn";
import PoLockSummary from "./components/PoLockSummary";
import PoProductQty from "./components/PoProductQty";
import PoProducts from "./components/PoProducts";

// BreadCrumb Config
const breadcrumbs = [
  {
    name: "PO List",
    link: "/purchase-order/list",
  },
  {
    name: "PO Details",
  },
];

// These are Feature
const tabs = [
  { key: "productDetails", tabName: "PO Product" },
  { key: "productQtyDetails", tabName: "Product Quantity Details" },
  { key: "grnDetails", tabName: "GRN Details" },
  // { key: "orderDetails", tabName: "Order Details" },
  // { key: "boxDetails", tabName: "Box Details" },
  { key: "poLockSummary", tabName: "PO Lock Summary" },
  { key: "approveSummary", tabName: "Approve Summary" },
  { key: "auditLog", tabName: "Audit Log" },
];

const style = {
  cardStyle: {
    height: "100px",
  },
};

const rbac = {
  printButton: ["AddPO"],
};

const PoView = () => {
  const [display, setDisplay] = useState("loading");

  const [activeTab, setActiveTab] = useState({
    key: tabs[0].key,
  });

  // to Store Complete Data
  const [data, setData] = useState({});

  // const [showRemarksModal, setShowRemarksModal] = useState(false);

  const loadedDetailsRef = useRef(false);
  // to Fetch QueryString

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const query = useFetchUrlQueryString(searchParams);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  useEffect(() => {
    if (loadedDetailsRef.current) {
      return;
    }

    if (query.id) {
      loadPoDetails(query.id);
    } else {
      setDisplay("noDataFound");
    }

    return () => {
      loadedDetailsRef.current = true;
    };
  }, [loadPoDetails, query.id]);

  // get Product Details
  const loadPoDetails = useCallback(async () => {
    setDisplay("loading");

    const r = await PoService.getPoById(query.id);

    let d = { ...(r.resp || {}) };

    if (d._id) {
      // PREPARING THE BASIC DATE REQUIRED TO SHOW IN BASIC DETAIL COMPONENT
      d._basic = {};
      const basicKeys = [
        "_id",
        "poValue",
        "status",
        "createdAt",
        "verifiedAt",
        "verifiedBy",
        "lastUpdated",
        "createdBy",
        "modifiedBy",
      ];
      each(basicKeys, (v) => {
        d._basic[v] = d[v];
      });

      // PREPARING THE APPROVE DATA REQUIRED TO SHOW IN APPROVE SUMMARY COMPONENT
      d._approve = {};
      const approveKeys = [
        "_id",
        "status",
        "poValue",
        "approvalStatus",
        "approvedBy",
        "approvedAt",
        "remarks",
        "verifiedBy",
        "verifiedAt",
        "createdBy",
        "createdAt",
        // Additional keys from PoApproved component
        "isCaseQtyVerified",
        "isStockVerified",
        "isSalesVerified",
        "verifiedRemark",
      ];
      each(approveKeys, (v) => {
        d._approve[v] = d[v];
      });

      setData(d);

      setDisplay("details");
    } else {
      setData({});
      setDisplay("noDataFound");
    }
  }, [attachAllData, query.id, setAdditionalData]);

  // Tab Callback to change Tab
  const tabCb = (data) => {
    setActiveTab({ ...data.value });
  };

  const poBasicDetailsCb = useCallback((data) => {
    if (data.action == "reload") {
      let timer = setTimeout(() => {
        window.location.reload();
        clearTimeout(timer);
      }, 900);
    }
  }, []);

  return (
    <>
      <div className="row justify-content-between align-items-center">
        <div className="col-6">
          <PageInfo
            title="PO Details"
            breadcrumbs={breadcrumbs}
            navigate={navigate}
          />
        </div>

        {/* <div className="col-6 text-end">
          {display == "details" && (
            <>
              <Rbac roles={rbac.printButton}>
                <Dropdown className=" d-inline-block">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <i className="bi bi-printer me-1"></i> Print Purchase Order
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => PoAccountCopyPrintTemplate.doPrint(data)}
                    >
                      Account Copy
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => PoVendorCopyPrintTemplate.doPrint(data)}
                    >
                      Vendor Copy
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Rbac>
            </>
          )}
        </div> */}
      </div>

      {display == "loading" && <PageLoader />}
      {display == "noDataFound" && <NoDataFound> No Data Found</NoDataFound>}
      {display == "details" && (
        <>
          <AppCard>
            <div className="row align-items-center">
              <div className="col">
                <div className="d-flex align-items-center gap-3">
                  <div className="fw-bold fs-val-lg">PO ID: #{data._id}</div>
                  <HighlightText type={data._statusColor} template={2}>
                    {data.status}
                  </HighlightText>
                </div>
                <div className="fs-val-sm">
                  Expected on{" "}
                  <span className="fw-bold text-danger">
                    <DateFormatter
                      date={data.expectedAt}
                      format={"dd MMM yyyy"}
                    />
                  </span>
                </div>
              </div>
              <div className="col-auto">
                <div className="fw-bold fs-val-lg">
                  PO Value:{" "}
                  <span className="text-danger">
                    <Amount value={data.poValue} />
                  </span>
                </div>
              </div>
            </div>
          </AppCard>

          <PoBasicDetails data={data._basic} />

          <div className="row mb-3">
            <div className="col-6">
              <AppCard className="h-100">
                <div className="fw-bold fs-val-md mb-2">Vendor Details</div>
                <div className="mb-1 fw-semibold fs-val-md">
                  <BtnLink
                    className="fs-val-md fw-semibold"
                    onClick={() =>
                      NavService.openInNewTab("/vendor/view", {
                        id: data.contact.vendorId,
                      })
                    }
                  >
                    {data.contact.vendorName} - {data.contact.vendorId}
                  </BtnLink>
                </div>
                <div className="fs-val-sm mb-2">{data.contact.address}</div>
                <div className="d-flex justify-content-between fs-val-sm">
                  <span className="mb-0">GST No: {data.contact.vendorGST}</span>
                  <span className="mb-0">Email: {data.contact.email}</span>
                </div>
              </AppCard>
            </div>
            <div className="col-6">
              <AppCard className="h-100">
                <div className="fw-bold fs-val-md mb-2">Warehouse Details</div>
                <div className="mb-1 fw-semibold fs-val-md">
                  <div className="fs-val-md fw-semibold">
                    {data.delivery.name}
                  </div>
                </div>
                <div className="fs-val-sm mb-2">
                  {data.delivery.town}, {data.delivery.district},{" "}
                  {data.delivery.state} - {data.delivery.pincode}
                </div>
                <div className="d-flex justify-content-between fs-val-sm">
                  <span className="mb-0">GST No: {data.delivery.gstNo}</span>
                </div>
              </AppCard>
            </div>
          </div>

          <div className="mb-3">
            <Tabs
              data={tabs}
              activeTab={activeTab}
              callback={tabCb}
              template={4}
              shadow={true}
            />
          </div>

          {activeTab.key == "productDetails" && (
            <AppCard>
              <PoProducts data={data.products} whId={data.delivery.whId} />
            </AppCard>
          )}
          {activeTab.key == "productQtyDetails" && (
            <AppCard>
              <PoProductQty
                poId={data._id}
                whId={data.delivery.whId}
                products={data.products}
              />
            </AppCard>
          )}
          {activeTab.key == "grnDetails" && (
            <PoGrn poId={data._id} whId={data.delivery.whId} />
          )}

          {activeTab.key == "poLockSummary" && (
            <AppCard>
              <PoLockSummary logs={data.LockStatusUpdateLog} />
            </AppCard>
          )}

          {activeTab.key == "approveSummary" && (
            <AppCard>
              <PoApproved data={data._approve} />
            </AppCard>
          )}

          {activeTab.key == "auditLog" && (
            <AppCard>
              <AuditLogTable entityType="Purchase Order" entityId={data._id} />
            </AppCard>
          )}
        </>
      )}
    </>
  );
};

export default PoView;
