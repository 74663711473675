import { NavService, PoService } from "@sk/services";
import { BusyLoader, PageInfo, Toaster, Amount } from "@sk/uis";
import { produce } from "immer";
import { useCallback, useMemo, useRef, useState } from "react";
import GrnVendorInvoiceModal from "../modals/GrnVendorInvoiceModal";
import { useNavigate } from "react-router-dom";
import GrnSelectPoForm from "../components/GrnSelectPoForm";
import GrnSelectPoTable from "../components/GrnSelectPoTable";
import GrnSelectedPoCard from "./components/GrnSelectedPoCard";

const paginationOpt = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "GRN List",
    link: "/purchase-order/grn/list",
  },
  {
    name: "Create GRN",
  },
];

const GrnSelectPo = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [showLoadMore, setShowLoadMore] = useState(true);

  const [showVendorInvoiceModal, setShowVendorInvoiceModal] = useState(false);

  const selectedPo = useMemo(() => data.filter((x) => x._checked), [data]);

  const [busyLoader, setBusyLoader] = useState(false);

  const [showSelectAllCheckbox, setShowSelectAllCheckbox] = useState(false);

  const [isLoadMore, setIsLoadMore] = useState(false);

  const filterDataRef = useRef({});

  const paginationRef = useRef({ ...paginationOpt });

  const areaDetailsRef = useRef([]);

  const selectedPoStyles = {};

  const validatePO = useCallback(
    (currentPo) => {
      const alreadyExist = data.filter((x) => x._id == currentPo.value._id);
      let msg = "";
      let currentVendor = filterDataRef.current.vendor;
      let v = currentVendor?.[0]?.value;
      let warehouse = filterDataRef.current.warehouse;

      if (alreadyExist.length) {
        msg = "PO Already exists in list";
      } else if (currentPo.value.whId != warehouse) {
        msg = "Entered PO ID is not from same different warehouse";
      } else if (
        currentVendor.length &&
        v?._id != currentPo?.value?.contact?.vendorId
      ) {
        msg = "Enter Po not belong to " + v?.name;
      }

      return {
        msg,
        valid: !msg,
      };
    },
    [data]
  );

  const loadPoList = useCallback(
    async (type, id, isLoadMore = true) => {
      const params = {
        page: paginationRef.current.activePage,
        count: paginationRef.current.rowsPerPage,
        filter: {
          status: { $in: ["Open", "Partially Inwarded"] },
          whId: filterDataRef.current.warehouse,
        },
      };

      setIsLoadMore(isLoadMore);
      if (type == "vendor" && id) {
        params.filter["contact.vendorId"] = id;
      }

      if (type == "po" && id) {
        params.filter._id = id;
      }

      if (isLoadMore) {
        setBusyLoader(true);
      } else {
        setLoading(true);
      }

      const r = await PoService.getList(params);

      const d = Array.isArray(r.resp)
        ? r.resp.map((x) => {
            let poQty = 0;
            x.products.forEach((x) => {
              poQty += x.quantity.requested - x.quantity.filled ?? 0;
            });
            x._checked = false;
            x._totalPoQty = poQty;
            x._isPoSelectedOne = type == "po" ? true : false;
            return x;
          })
        : [];

      const unlockedData = Array.isArray(d)
        ? d.filter((e) => {
            return !e.isLocked;
          })
        : [];

      if (!isLoadMore) {
        if (unlockedData.length) {
          setShowSelectAllCheckbox(true);
        } else {
          setShowSelectAllCheckbox(false);
        }
      }

      setShowLoadMore(d.length > 0 ? true : false);

      if (!d.length) {
        let msg =
          paginationRef.current.activePage > 1
            ? "No more records to display"
            : "No Po Found";

        Toaster.error(msg);

        if (isLoadMore) {
          setBusyLoader(false);
        } else {
          setLoading(false);
        }

        return;
      }

      if (isLoadMore) {
        if (data?.length > 0) {
          let x = [...data, ...d];
          let fltData = x.filter(
            (obj, index, array) =>
              array.findIndex((o) => o._id === obj._id) === index
          );
          setData(fltData);
        } else {
          setData((prev) => [...prev, ...d]);
        }
      } else if (!isLoadMore && type == "vendor") {
        setData(d);
      }

      if (type == "po") {
        setData((prev) => [...d, ...prev]);
      }

      if (isLoadMore) {
        setBusyLoader(false);
      } else {
        setLoading(false);
      }
    },
    [data]
  );

  const onCheckboxChangeCb = (index, checked) => {
    setData(
      produce((draft) => {
        draft[index]._checked = checked;
      })
    );
  };

  const formCb = useCallback(
    (d) => {
      filterDataRef.current = d.formData;
      paginationRef.current = { ...paginationOpt };
      if (d.type == "po") {
        if (!data.length) {
          paginationRef.current = { ...paginationOpt };
        }
        let val = filterDataRef.current.po;
        if (val?.length) {
          const validate = validatePO(val[0]);

          if (!validate.valid) {
            Toaster.error(validate.msg);
            return;
          }
          loadPoList("po", val[0].value._id, false);
        }
      } else if (d.type == "vendor") {
        setData([]);
        setShowSelectAllCheckbox(false);
        let val = filterDataRef.current.vendor;
        if (val?.length) {
          loadPoList("vendor", val[0].value._id, false);
        }
        filterDataRef.current.po = [];
      } else if (d.type == "warehouse") {
        setData([]);
        loadPoList();
        filterDataRef.current.vendor = [];
        filterDataRef.current.po = [];
      }
    },
    [data.length, loadPoList, validatePO]
  );

  const onSubmit = useCallback(() => {
    if (!selectedPo.length) {
      Toaster.error("Please select  PO first");
      return;
    }
    const ids = selectedPo.map((x) => x._id);

    NavService.to(navigate, "/purchase-order/grn/manage", {
      id: ids.toString(),
    });

    // setShowVendorInvoiceModal(true);
  }, [selectedPo, navigate]);

  const loadMore = () => {
    paginationRef.current.activePage += 1;
    loadPoList("vendor", filterDataRef.current.vendor[0].value._id, true);
  };

  const grnVendorInvoiceModalCb = useCallback((payload) => {
    setShowVendorInvoiceModal(false);
  }, []);

  const onSelectAllChangeCb = useCallback((v) => {
    setData(
      produce((draft) => {
        draft.forEach((x) => {
          if (!x.isLocked) {
            x._checked = v;
          }
        });
      })
    );
  }, []);

  const areaDetailsCb = useCallback((d) => {
    areaDetailsRef.current = d;
  }, []);

  return (
    <>
      <div className="mt-2">
        <PageInfo
          title="Create GRN"
          navigate={navigate}
          breadcrumbs={breadcrumbs}
        />
      </div>

      <div className="py-3">
        <div className="fw-semibold fs-val-lg mb-1">
          Select vendor or PO ID to create GRN
        </div>
        <GrnSelectPoForm callback={formCb} data={filterDataRef.current} />
      </div>

      <div className="p-2 mt-2 bg-white mt-1">
        <GrnSelectedPoCard
          data={data}
          loading={loading}
          onCheckboxChangeCb={onCheckboxChangeCb}
          filterData={filterDataRef.current}
        />
      </div>

      {/* LOAD MORE */}
      {data.length >= 50 && showLoadMore ? (
        <div className="text-center bg-white p-4 p-2">
          <button onClick={loadMore} className="btn btn-outline-primary btn-sm">
            Load More
          </button>
        </div>
      ) : null}

      {data.length ? (
        <div className="position-sticky bottom-0 p-3 bg-success mt-3 border rounded-2">
          <div className="row align-items-center">
            <div className="col-6 fs-4 fw-semibold text-white">
              <span> Selected PO : </span>
              <span>{selectedPo.length}</span>
            </div>
            <div className="col-6 text-end">
              <button className="btn btn-light" onClick={onSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      ) : null}

      <GrnVendorInvoiceModal
        show={showVendorInvoiceModal}
        callback={grnVendorInvoiceModalCb}
        warehouse={filterDataRef.current.warehouse}
        areaDetailsCb={areaDetailsCb}
      />

      <BusyLoader show={busyLoader} />
    </>
  );
};

export default GrnSelectPo;

{
  /* <div className="py-3">
        <GrnSelectPoTable
          data={data}
          showSelectAllCheckbox={showSelectAllCheckbox}
          onSelectAllChangeCb={onSelectAllChangeCb}
          loading={loading}
          onCheckboxChangeCb={onCheckboxChangeCb}
          filterData={filterDataRef.current}
          isLoadMore={isLoadMore}
        />
      </div> */
}
