import React from "react";

const VendorDetails = ({ vendor = {} }) => {
  return (
    <div className="border rounded-3 p-3 bg-white">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="fs-val-lg fw-semibold">Vendor Details</div>
        <div className="badge bg-primary-subtle text-primary fs-val-xs">
          ID: {vendor._id}
        </div>
      </div>

      <div className="mb-3">
        <div className="fs-val-md fw-semibold text-primary mb-2">
          {vendor.name}
        </div>
        <div className="text-muted fs-val-sm">
          <div className="mb-1">{vendor.address?.line1}</div>
          {vendor.address?.line2 && <div>{vendor.address.line2}</div>}
        </div>
      </div>

      <div className="contact-info d-flex gap-3 fs-val-sm">
        {vendor._contact?.mobile && (
          <div className="d-flex align-items-center">
            <i className="bi bi-telephone-fill text-primary"></i>
            <span className="px-2">{vendor._contact.mobile}</span>
          </div>
        )}
        {vendor._contact?.email && (
          <div className="d-flex align-items-center">
            <i className="bi bi-envelope text-primary"></i>
            <span className="px-2">{vendor._contact.email}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default VendorDetails;
