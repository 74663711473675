import { GrnService, NavService } from "@sk/services";
import {
  Amount,
  AppCard,
  AppSlide,
  AppSwiper,
  BtnLink,
  DateFormatter,
  HighlightText,
  NoDataFound,
  SummaryCard,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";

const containerStyle = {
  overflowX: "auto",
  maxHeight: "calc(100vh - 300px)",
};

const tableStyle = {
  minWidth: "1400px",
};

const swiperConfig = {
  slidesPerView: "auto",
  spaceBetween: 10,
  mousewheel: {
    forceToAxis: true,
  },
};

const header = [
  {
    label: "Sl.no",
    width: "3%",
    isSticky: true,
    stickyPosition: "left",
    style: { left: 0 },
  },
  {
    label: "GRN ID",
    width: "10%",
    isSticky: true,
    stickyPosition: "left",
    style: { left: "4.5%" },
  },
  { label: "Created On", width: "10%" },
  { label: "Items", width: "5%" },
  { label: "GRN Status", width: "10%" },
  { label: "Invoice Qty", width: "8%", isCentered: true },
  { label: "Received Qty", width: "8%", isCentered: true },
  { label: "Shortage Qty", width: "8%", isCentered: true },
  { label: "Damage Qty", width: "8%", isCentered: true },
  { label: "Total GRN Value", width: "10%" },
];

const stickySerialNumberStyle = {
  position: "sticky",
  left: 0,
  zIndex: 1,
};

const stickyGrnIdStyle = {
  position: "sticky",
  left: "4.5%",
  zIndex: 1,
};

const defaultSummaryData = {
  totalInvoiceQty: 0,
  totalReceivedQty: 0,
  totalShortageQty: 0,
  totalDamageQty: 0,
  totalGrnValue: 0,
};

const PoGrn = ({ poId, whId }) => {
  const [grnData, setGrnData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState({ ...defaultSummaryData });

  useEffect(() => {
    if (poId && whId) {
      loadGrnData(poId, whId);
    }
  }, [poId, whId]);

  const loadGrnData = useCallback(async (poId, whId) => {
    let p = {
      filter: {
        "productDetails.poId": poId,
        whId: whId,
      },
    };

    setLoading(true);
    const r = await GrnService.getList(p);

    const d = Array.isArray(r.resp) ? r.resp : [];

    if (d.length) {
      d.forEach((x) => {
        x._receivedQty = 0;
        x._invoiceQty = 0;
        x._shortageQty = 0;
        x._damagedQty = 0;
        (x.productDetails || []).forEach((cur) => {
          x._receivedQty += cur.receivedQuantity ?? 0;
          x._invoiceQty += cur.invoiceQuantity ?? 0;
          x._shortageQty += cur.shortageQty ?? 0;
          x._damagedQty += cur.damagedQty ?? 0;
        });
      });

      let summaryData = { ...defaultSummaryData };

      d.forEach((x) => {
        summaryData.totalInvoiceQty += x._invoiceQty;
        summaryData.totalReceivedQty += x._receivedQty;
        summaryData.totalShortageQty += x._shortageQty;
        summaryData.totalDamageQty += x._damagedQty;
        summaryData.totalGrnValue += x.amount ?? 0;
      });

      setSummary(summaryData);
    }

    setGrnData(d);
    setLoading(false);
  }, []);

  const prepareSummaryCards = () => {
    return [
      {
        label: "Invoice Qty",
        value: summary.totalInvoiceQty,
        color: "text-primary",
      },
      {
        label: "Received Qty",
        value: summary.totalReceivedQty,
        color: "text-success",
      },
      {
        label: "Shortage Qty",
        value: summary.totalShortageQty,
        color: "text-danger",
      },
      {
        label: "Damage Qty",
        value: summary.totalDamageQty,
        color: "text-warning",
      },
      {
        label: "Total GRN Value",
        value: summary.totalGrnValue,
        color: "text-info",
        isAmount: true,
      },
    ];
  };

  return (
    <>
      {!loading && grnData.length > 0 && (
        <AppSwiper config={swiperConfig}>
          {prepareSummaryCards().map((e, k) => (
            <AppSlide key={k} autoWidth={true}>
              <SummaryCard
                template={3}
                title={e.label}
                value={e.value || 0}
                valueColor={e.color}
                isValueAmt={e.isAmount}
              />
            </AppSlide>
          ))}
        </AppSwiper>
      )}

      <AppCard>
        <div className="fs-val-sm mb-1 text-muted">
          Showing <span className="text-dark">{grnData.length}</span> records
        </div>

        <div
          style={containerStyle}
          className="mb-3 custom-scrollbar thin-scrollbar tbl-scroll-container fixed-width-table"
        >
          <table className="table table-sm" style={tableStyle}>
            <TableHeader data={header} isSticky={true} noBg={true} />
            <tbody className="fs-val-md">
              {loading && (
                <TableSkeletonLoader rows={10} cols={header.length} />
              )}

              {!loading && grnData.length === 0 && (
                <tr>
                  <td colSpan={header.length}>
                    <NoDataFound>No GRN Details Found</NoDataFound>
                  </td>
                </tr>
              )}

              {!loading &&
                grnData.map((x, i) => (
                  <tr key={i}>
                    <td
                      className="sticky-column bg-white"
                      style={{
                        ...stickySerialNumberStyle,
                        backgroundColor: "white",
                      }}
                    >
                      {i + 1}
                    </td>
                    <td
                      className="sticky-column bg-white"
                      style={{
                        ...stickyGrnIdStyle,
                        backgroundColor: "white",
                      }}
                    >
                      <BtnLink
                        className="fs-val-md"
                        onClick={(e) => {
                          e.stopPropagation();
                          NavService.openInNewTab("/purchase-order/grn/view", {
                            id: x._id,
                          });
                        }}
                      >
                        {x._id}
                      </BtnLink>
                    </td>
                    <td>
                      <DateFormatter date={x.createdAt} />
                    </td>
                    <td>{x.productDetails.length}</td>
                    <td>
                      <HighlightText status={x.status} />
                    </td>
                    <td className="text-center">{x._invoiceQty}</td>
                    <td className="text-center">{x._receivedQty}</td>
                    <td className="text-center">{x._shortageQty}</td>
                    <td className="text-center">{x._damagedQty}</td>
                    <td>
                      <Amount value={x.amount ?? 0} decimalPlace={2} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </AppCard>
    </>
  );
};

export default memo(PoGrn);
