import { CommonService } from "@sk/services";
import { Amount, AppTitle, DateFormatter, KeyVal, TableHeader } from "@sk/uis";
import { formatDistance } from "date-fns";
import { memo, useMemo } from "react";
import { Offcanvas } from "react-bootstrap";
// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "75%",
  },
};

const headers = [
  { label: "Name", width: "30%" },
  { label: "Price" },
  { label: "Stock" },
  { label: "Shelf Life" },
  { label: "Total Value" },
];

const invoiceSummaryKeys = {
  amount: {
    label: "Invoice Value",
    key: "amount",
    priority: 2,
    formatter: "amount",
  },
  refno: { label: "Invoice No", key: "refno", priority: 1 },
  productList: { label: "Total Deals", key: "productList", priority: 3 },
  invoiceDate: {
    label: "Invoice Date",
    key: "invoiceDate",
    priority: 4,
    formatter: "date",
  },
};

const formatter = (key, value) => {
  if (key == "amount") {
    return <Amount value={value} decimalPlace={2} />;
  }
  if (key == "date") {
    return <DateFormatter date={value} format="dd MMM yyyy" />;
  }
  return value;
};

const ViewDealModal = ({ show, closeModal, data }) => {
  const productList = (data?.productList || []).filter(
    (x) => x.status != "Rejected" && data.refno == x.invoiceNo
  );

  const summaryData = useMemo(() => {
    const tempList = [];
    Object.keys(data).forEach((key) => {
      if (key in invoiceSummaryKeys) {
        if (key == "productList") {
          tempList.push({
            ...invoiceSummaryKeys[key],
            value: productList?.length,
          });
        } else {
          tempList.push({ ...invoiceSummaryKeys[key], value: data[key] });
        }
      }
    });

    return tempList.sort((a, b) => a.priority - b.priority);
  }, [data, productList]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          closeVariant="white"
          style={style.offCanvasHeaderStyle}
        >
          {/* Modal Title */}
          <AppTitle
            title="Invoice Deals"
            className="fs-val-lg text-dark px-2"
          />
        </Offcanvas.Header>

        <Offcanvas.Body className="p-0 bg-light">
          <div className="py-4 px-2">
            {summaryData.map((x, i) => (
              <div
                className="card px-3 py-2  me-3 d-inline-block bg-white"
                key={i}
              >
                <span className="text-primary fw-semibold me-1 fs-val-lg">
                  {x.label} :
                </span>
                <span className="fw-semibold fs-val-lg">
                  {formatter(x.formatter, x.value)}
                </span>
              </div>
            ))}
          </div>
          <div className="fs-val-lg px-2"> Deal List </div>
          <table className="table table-bordered bg-white">
            <TableHeader data={headers} />
            <tbody>
              {!productList?.length ? (
                <tr>
                  <td colSpan={headers.length} className="text-center">
                    No Deal Found
                  </td>
                </tr>
              ) : (
                productList?.map((x, i) => (
                  <tr key={i} className="fs-val-md">
                    <td className="text-break fw-semibold">{x.name}</td>
                    <td>
                      <div className="mb-1">
                        <KeyVal label="MRP" labelCol="col-8" contentCol="col-4">
                          <span className=" fw-semibold">
                            <Amount value={x.mrp} decimalPlace={2} />
                          </span>
                        </KeyVal>
                      </div>
                      <div className="mb-1">
                        <KeyVal
                          label="Margin"
                          labelCol="col-8"
                          contentCol="col-4"
                        >
                          <span className=" fw-semibold text-success">
                            {CommonService.roundedByDecimalPlace(
                              x.discount,
                              5
                            ) + " %"}
                          </span>
                        </KeyVal>
                      </div>

                      <div className="mb-1">
                        <KeyVal
                          label="Purchase Price"
                          labelCol="col-8"
                          contentCol="col-4"
                        >
                          <span className=" fw-semibold text-primary">
                            <Amount value={x.price} decimalPlace={2} />
                          </span>
                        </KeyVal>
                      </div>
                    </td>

                    <td>
                      <div className="mb-1">
                        <KeyVal
                          label="Invoice Qty"
                          labelCol="col-6"
                          contentCol="col-6"
                        >
                          <span className="text-primary fw-semibold">
                            {x.invoiceQty} {x._displayUnitType}
                          </span>
                        </KeyVal>
                        <KeyVal
                          label="Requested Qty"
                          labelCol="col-6"
                          contentCol="col-6"
                        >
                          <span className="text-primary fw-semibold">
                            {x.requestedQuantity} {x._displayUnitType}
                          </span>
                        </KeyVal>
                      </div>
                      {x.status == "Received" ||
                      x.status == "Partially Received" ? (
                        <div className="mb-1">
                          <KeyVal
                            label="Received Qty"
                            labelCol="col-6"
                            contentCol="col-6"
                          >
                            <span className="text-success fw-semibold">
                              {x.receivedQuantity || 0} {x._displayUnitType}
                            </span>
                          </KeyVal>
                        </div>
                      ) : null}
                      {x.damageQuantity ? (
                        <div className="mb-1">
                          <KeyVal
                            label="Damage Qty"
                            labelCol="col-6"
                            contentCol="col-6"
                          >
                            <span className="text-danger fw-semibold">
                              {x.damageQuantity} {x._displayUnitType}
                            </span>
                          </KeyVal>
                        </div>
                      ) : null}
                      {x.damageReason ? (
                        <div className="mb-1">
                          <KeyVal
                            label="Damage Reason"
                            labelCol="col-6"
                            contentCol="col-6"
                          >
                            <span className="text-danger fw-semibold">
                              {x.damageReason}
                            </span>
                          </KeyVal>
                        </div>
                      ) : null}
                      {x.shortageQuantity ? (
                        <div className="mb-1">
                          <KeyVal
                            label="Shortage Qty"
                            labelCol="col-6"
                            contentCol="col-6"
                          >
                            <span className="text-danger fw-semibold">
                              {x.shortageQuantity} {x._displayUnitType}
                            </span>
                          </KeyVal>
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {x.manufactureDate ? (
                        <>
                          <div className="text-muted fs-val-sm">
                            Manufacture Date
                          </div>
                          <div className="fs-val-sm mb-2">
                            <DateFormatter
                              date={x?.manufactureDate}
                              format="dd MMM yyyy"
                            />
                          </div>
                        </>
                      ) : (
                        "N/A"
                      )}
                      {x?.expiresOn && (
                        <>
                          <div className="text-muted fs-val-sm">Expires on</div>
                          <div className="fs-val-sm">
                            <DateFormatter
                              date={x?.expiresOn}
                              format="dd MMM yyyy"
                            />
                          </div>
                          <div className="text-danger fs-val-sm">
                            {formatDistance(new Date(x.expiresOn), new Date()) +
                              " left"}
                          </div>
                        </>
                      )}
                    </td>
                    <td>
                      <Amount
                        value={x.totalValue} // old value => x.price * x.receivedQuantity
                        decimalPlace={2}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ViewDealModal);
