import { Tabs } from "@sk/uis";
import React, { memo, useCallback } from "react";

const VendorFilterTabs = ({ callback, tabs, activeTab }) => {
  const tabCb = useCallback(
    (data) => {
      callback({ ...data.value });
    },
    [callback]
  );

  return (
    <Tabs template={4} data={tabs} activeTab={activeTab} callback={tabCb} />
  );
};

export default memo(VendorFilterTabs);
