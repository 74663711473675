import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  CommonService,
  OmsService,
  UtilityService,
  VendorService,
} from "@sk/services";
import {
  Amount,
  AppTitle,
  AutoCompleteInput,
  DatePickerInput,
  FileUpload,
  ImgRender,
  InputErrorMsg,
  KeyVal,
  PageLoader,
  SelectInput,
  Spinner,
  TextInput,
  TextareaInput,
  Toaster,
} from "@sk/uis";
import { cloneDeep } from "lodash";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
const defaultFormValues = {
  refNo: "",
  remarks: "",
  paymentDate: "",
  paymentMethod: "",
  proofs: [],
  skBank: [],
  invoiceNo: "",
  amount: "",
  vendorBank: "",
};

const paymentMethodOptions = [
  { label: "Select Payment Mode", value: "" },
  { label: "Cheque", value: "Cheque" },
  { label: "IMPS", value: "IMPS" },
  { label: "NEFT", value: "NEFT" },
  { label: "RTGS", value: "RTGS" },
  { label: "UPI", value: "UPI" },
];

// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "35%",
  },
  alertStyle: {
    zIndex: 2000,
  },
};

const validationSchema = yup.object({
  refNo: yup.string().trim().required("Reference Number is Required"),

  remarks: yup.string().trim(),
  invoiceNo: yup.string().required("Invoice Number is Required"),

  paymentDate: yup
    .date()
    .typeError("Payment date is required")
    .required("Payment Date is required"),
  proofs: yup.array().min(1, "Payment Receipt is required"),

  paymentMethod: yup.string().required("Payment Method is required"),
  skBank: yup.array().min(1, "Please Select SK Bank"),
  vendorBank: yup.string().required("Please Select Vendor Bank"),
});

function PendingPaymentModal({ show, callback, poDetails = {} }) {
  const {
    register,
    control,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
  });

  const [submitting, setSubmitting] = useState(false);

  const [pageLoading, setPageLoading] = useState(true);

  const [vendorDetails, setVendorDetails] = useState({});

  const [vendorSelectedBank, setVendorSelectedBank] = useState({});

  const proofs = useWatch({ name: "proofs", control });

  const invoiceOptions = useMemo(() => {
    let opt = [{ label: "Select Invoice", value: "" }];
    poDetails?.invoiceDetails?.forEach((curInvoice) => {
      const payedInvoice = poDetails?.paymentSettlement.find(
        (x) => x.invoiceNo == curInvoice.refno
      );
      if (!payedInvoice) {
        opt.push({
          label: curInvoice.refno,
          value: curInvoice.refno,
          amount: curInvoice.amount,
        });
      }
    });
    return opt;
  }, [poDetails]);

  const skBank = useWatch({
    name: "skBank",
    control,
  })?.[0]?.value;

  const amount = useWatch({
    name: "amount",
    control,
  });

  const bankOptions = useMemo(
    () => [
      { label: "Select Vendor Bank", value: "" },
      ...(vendorDetails.bankDetails || [])
        .filter((x) => x.isActive)
        .map((x) => ({
          label: `${x.bankName} - ${x.accountNumber}`,
          value: x.bankId,
        })),
    ],
    [vendorDetails.bankDetails]
  );

  const onVendorBankChange = useCallback(
    (bandId) => {
      const v = vendorDetails?.bankDetails?.find((x) => x.bankId == bandId);
      setVendorSelectedBank(v || {});
    },
    [vendorDetails?.bankDetails]
  );

  const dateChangeCb = (chngFn) => {
    return (val) => {
      if (!val.length) {
        return;
      }

      chngFn(val);

      setValue("paymentDate", val[0]);
    };
  };

  useEffect(() => {
    if (show && poDetails._id) {
      reset(defaultFormValues);

      const getVendorBankDetails = async () => {
        setPageLoading(true);
        let vId = poDetails.vendorDetails.id;
        let params = {
          select: "bankDetails,name",
        };
        const r = await VendorService.getVendor(vId, params);
        if (!r.resp._id) {
          setPageLoading(false);
          onHideCb();
          return;
        }
        setVendorDetails(r.resp);
        setPageLoading(false);
      };
      getVendorBankDetails();
    } else {
      onHideCb();
    }
  }, [show, reset, poDetails, onHideCb]);

  const proofsCb = async (e) => {
    const v = getValues("proofs") || [];
    setValue("proofs", [e]);
  };

  const removeImage = useCallback(
    (key, index) => {
      let v = getValues(key);
      v.splice(index, 1);
      setValue(key, v);
    },
    [getValues, setValue]
  );

  const onRefNumberChange = useCallback((e) => {
    let val = e?.target?.value?.trim()?.toUpperCase();
    e.target.value = val;
  }, []);

  const onSubmit = async (data) => {
    setSubmitting(true);
    let p = {};

    p.invoiceNo = data.invoiceNo;
    p.invoiceAmount = data.amount;
    p.proofs = data.proofs.map((x) => x.asset);
    p.refNo = data.refNo + "";
    p.paymentDate = data.paymentDate;
    p.paymentMethod = data.paymentMethod;
    p.remarks = data.remarks;
    p.fromBankAccountNo = skBank.acNumber;
    p.fromBankId = skBank._id;
    p.fromBankName = skBank.folderName;
    p.toBankAccountNo = vendorSelectedBank.accountNumber;
    p.toBankBranch = vendorSelectedBank.branchName;
    p.toBankId = vendorSelectedBank.bankId;
    p.toBankName = vendorSelectedBank.bankName;
    p.toBankIfscCode = vendorSelectedBank.ifsc;
    p.toBankUtr = data.refNo + "";

    let paymentSettlement = cloneDeep(poDetails?.paymentSettlement) || [];
    paymentSettlement.push(p);

    const payload = {
      ...poDetails,
      paymentSettlement: paymentSettlement,
      status:
        paymentSettlement.length != poDetails.invoiceDetails.length
          ? poDetails.status
          : "Closed",
    };

    const r = await OmsService.closePosPo(poDetails._id, payload);

    if (r.statusCode != 200) {
      Toaster.error(r.resp.message);
      callback({ action: "error" });
    } else {
      callback({ action: "submit", data: r.resp.message });
    }

    setSubmitting(false);
  };

  const onHideCb = useCallback(() => {
    if (pageLoading || submitting) {
      return;
    }
    callback({ action: "close" });
  }, [callback, pageLoading, submitting]);

  const onBankChange = async (val, callback) => {
    let params = {
      filter: {
        skBank: true,
      },
    };

    const search = val.trim();
    const searchString = CommonService.sanitizeRegex(search);
    const searchRegex = { $regex: searchString, $options: "gi" };
    if (search) {
      params.filter.$or = [{ _id: searchRegex }, { name: searchRegex }];
    }

    const r = await UtilityService.getBankList(params);
    callback(
      (r.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      }))
    );
  };

  const onInvoiceChange = (val) => {
    const curInv = invoiceOptions.find((x) => x.value == val);
    setValue("amount", curInv?.amount || "");
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={onHideCb}
        backdrop="static"
        keyboard={false}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          closeVariant="white"
          style={style.offCanvasHeaderStyle}
        >
          <AppTitle
            title={
              poDetails?._id
                ? "Payment Details" + `- ${poDetails?._id}`
                : "Payment Details"
            }
            className="fs-val-lg text-dark px-2"
          />
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0 m-0">
          {pageLoading ? (
            <PageLoader />
          ) : (
            <>
              <div className="row p-4 pb-2 bg-light border-bottom">
                <div className="col-12 ">
                  <div className="fw-semibold fs-val-lg mb-2">
                    Vendor Details
                  </div>
                  <div className="col-12 fs-val-lg text-primary mb-2">
                    {vendorDetails.name} - {vendorDetails._id}
                  </div>

                  <div className="col-12 mb-2">
                    <SelectInput
                      register={register}
                      name="vendorBank"
                      callback={onVendorBankChange}
                      isMandatory={true}
                      options={bankOptions}
                      label="Vendor Bank"
                      error={errors?.vendorBank?.message}
                    />
                  </div>
                  {vendorSelectedBank?.bankId && (
                    <>
                      <div className="row my-2">
                        <div className="col-4">
                          <KeyVal template="col" label="Account Number">
                            <span className="fs-val-sm">
                              {vendorSelectedBank.accountNumber}
                            </span>
                          </KeyVal>
                        </div>
                        <div className="col-4">
                          <KeyVal template="col" label="Branch Name">
                            <span className="fs-val-sm">
                              {vendorSelectedBank.branchName}
                            </span>
                          </KeyVal>
                        </div>

                        <div className="col-4">
                          <KeyVal template="col" label="IFSC Code">
                            <span className="fs-val-sm">
                              {vendorSelectedBank.ifsc}
                            </span>
                          </KeyVal>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="row p-4 bg-white">
                <div className="col-12 mb-2">
                  <Controller
                    control={control}
                    name="skBank"
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteInput
                        label="Search for StoreKing's Bank"
                        placeholder="Search By Name /ID "
                        value={value}
                        onSearch={onBankChange}
                        callback={onChange}
                        uid="product-list-search"
                        isMultiple={false}
                        emptyLabel="No Bank Found"
                        error={errors?.skBank?.message}
                        isMandatory={true}
                        gap={0}
                      />
                    )}
                  />
                  {skBank?._id && (
                    <div className="row my-2">
                      <div className="col-4">
                        <KeyVal template="col" label="Account Number">
                          <span className="fs-val-sm">{skBank.acNumber}</span>
                        </KeyVal>
                      </div>

                      <div className="col-4">
                        <KeyVal template="col" label="Branch Name">
                          <span className="fs-val-sm">{skBank.branchName}</span>
                        </KeyVal>
                      </div>

                      <div className="col-4">
                        <KeyVal template="col" label="IFSC Code">
                          <span className="fs-val-sm">{skBank.ifsc}</span>
                        </KeyVal>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-6 mb-2">
                  <SelectInput
                    label="Payment Method"
                    register={register}
                    name="paymentMethod"
                    error={errors?.paymentMethod?.message}
                    options={paymentMethodOptions}
                    isMandatory={true}
                  />
                </div>

                <div className="col-6 mb-2">
                  <TextInput
                    register={register}
                    name="refNo"
                    type="text"
                    maxLength={22}
                    callback={onRefNumberChange}
                    placeholder="Enter Reference No here "
                    label="UTR Number"
                    isMandatory={true}
                    error={errors?.refNo?.message}
                    note="UTR number cannot be greater than 22 characters"
                  />
                </div>

                <div className="col-6 mb-2">
                  <label className=" mb-0 fs-val-md">
                    Payment Date
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    control={control}
                    name="paymentDate"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <DatePickerInput
                          placeholder="Choose"
                          value={[value]}
                          inpChange={dateChangeCb(onChange)}
                        />
                      );
                    }}
                  />
                  <InputErrorMsg msg={errors?.paymentDate?.message} />
                </div>

                <div className="col-6">
                  <SelectInput
                    label="Select Invoice"
                    name="invoiceNo"
                    error={errors?.invoiceNo?.message}
                    register={register}
                    callback={onInvoiceChange}
                    isMandatory={true}
                    options={invoiceOptions}
                  />
                </div>
                {amount ? (
                  <div className="alert alert-success col-12" role="alert">
                    <span className="fs-val-md fw-bold">
                      Amount to pay for selected invoice is{" "}
                      <span className="fs-val-lg">
                        <Amount value={amount} decimalPlace={2} />
                      </span>
                    </span>
                  </div>
                ) : null}

                <div className="col-12 mb-2">
                  <TextareaInput
                    register={register}
                    name="remarks"
                    label="Remarks"
                    rows={2}
                    placeholder="Enter Remarks"
                    error={errors?.remarks?.message}
                    note="Maximum allowed Character 250"
                  />
                </div>

                <div className="col-12">
                  <div className="mb-2">
                    <div className="row">
                      {/* Image Rendering */}
                      <div className="col-auto">
                        {!proofs.length ? (
                          <div className="upload-placeholder-t1">
                            <i className="bi bi-image"></i>
                          </div>
                        ) : (
                          <div className="uploaded-t1-img-cnt">
                            <i
                              tabIndex={-1}
                              role="button"
                              className="bi bi-x-circle close"
                              onClick={() => {
                                removeImage("proofs");
                              }}
                            ></i>
                            <ImgRender
                              assetId={proofs.length ? proofs[0]["asset"] : ""}
                              width="100"
                              height="100"
                            />
                          </div>
                        )}
                      </div>

                      {/* Action button  */}
                      <div className="col col-ms-auto align-self-end">
                        <label className="fs-val-md">
                          Upload Payment Receipt
                          <span className="text-danger">*</span>
                        </label>
                        <div>
                          <FileUpload template={2} callback={proofsCb} />
                        </div>
                      </div>
                    </div>

                    {errors?.proofs?.message && (
                      <div className="col-12">
                        <InputErrorMsg msg={errors?.proofs?.message} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </Offcanvas.Body>
        <Offcanvas.Header>
          <div className="col-12 text-end">
            <button
              className="btn btn-primary"
              onClick={handleSubmit(onSubmit)}
              disabled={submitting}
            >
              Submit {submitting ? <Spinner isSmall={true} /> : null}
            </button>
          </div>
        </Offcanvas.Header>
      </Offcanvas>
    </>
  );
}

export default memo(PendingPaymentModal);
