import { NavService } from "@sk/services";
import { DateFormatter, FileUpload, ImgSlider } from "@sk/uis";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { BtnLink } from "@sk/uis";

const imgStyle = { width: "400px" };

const swiperConfig = {
  slidesPerView: 3.5,
  spaceBetween: 20,
};

const isCaseQtyOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

const damageOptions = [
  { label: "Select reason", value: "" },
  { label: "Courier Damage", value: "CourierDamage" },
  { label: "Vendor Damage", value: "VendorDamage" },
  { label: "Warehouse Damage", value: "WarehouseDamage" },
];

const vendorTaxOptions = [
  { label: "Choose", value: "" },
  { label: "0%", value: "0" },
  { label: "5%", value: "5" },
  { label: "12%", value: "12" },
  { label: "18%", value: "18" },
  { label: "28%", value: "28" },
  { label: "33% = 28 GST + 5 Cess", value: "33" },
  { label: "40% = 28 GST + 12 Cess", value: "40" },
  { label: "64% = 28 GST + 36 Cess", value: "64" },
];

const maxManufactureDateConfig = new Date().toISOString().split("T")[0];

function GrnInvoiceItem({
  data,
  callback,
  index,
  productDetails,
  splitCb,
  onCancelSplitCb,
  onCancelScanCb,
  barcode,
  onAssignBarcodeCb,
}) {
  const viewProduct = () => {
    let id = data.product.productId;
    NavService.openInNewTab("/cms/product/view", { id });
  };

  const [showImgPreviewModal, setShowImgPreviewModal] = useState(false);

  const [mrpOptions, setMrpOptions] = useState([]);

  useEffect(() => {
    if (productDetails?.mrpList?.length) {
      let d = productDetails.mrpList.map((e) => {
        return { value: e + "", label: e };
      });
      d.push({ label: "Other", value: "Other" });
      d.unshift({ label: "Choose MRP", value: "" });
      setMrpOptions(d);
    }
  }, [productDetails, setMrpOptions]);

  const handleCloseImgModal = async (e) => {
    setShowImgPreviewModal(false);
  };

  const viewProofModal = async (e) => {
    setShowImgPreviewModal(true);
  };

  const imgCallback = useCallback(
    (key, val, extraKey = {}) => {
      callback({
        action: "formUpdate",
        key: key,
        value: val,
        index,
        extraKey,
      });
    },
    [callback, index]
  );

  const imageRemove = useCallback(
    async (key, e, index) => {
      imgCallback(key, index, {
        action: "removeImg",
      });
    },
    [imgCallback]
  );

  const onClickSplit = () => {
    splitCb({ index: index });
  };

  const onCancelSplit = () => {
    onCancelSplitCb({ index: index });
  };

  const onCancelScan = () => {
    onCancelScanCb({ index: index });
  };

  const onClickAssignBarcode = () => {
    onAssignBarcodeCb({ index: index });
  };

  return (
    <>
      <table className="table fs-val-md bg-white rounded">
        <tbody>
          {/* <tr>
            <td colSpan={4}>
              <div className="row">
                <div className="col-12">
                  <div className="fw-semibold mb-1 fs-val-lg text-primary">
                    {data.product.name}
                  </div>
                </div>

                <div className="col-auto">
                  <div
                    className="me-4 fs-val-sm text-primary"
                    role="button"
                    tabIndex={-1}
                    onClick={viewProduct}
                  >
                    Product ID : {data.product.productId}
                  </div>
                </div>

                <div className="col-auto ">
                  <div className="me-4 fs-val-sm">
                    <span className="text-muted">PO ID: </span>
                    <span>{data?.product?.poId.join(",")}</span>
                  </div>
                </div>

                <div className="col-auto fs-val-sm">
                  <span className="text-muted">PO QTY: </span>
                  <span>{data?.product.quantity.requested}</span>
                </div>

                {data?.barcode?.length ? (
                  <div className="col-auto fs-val-sm ms-4">
                    <span className="text-muted">Barcode: </span>
                    <span className="text-semibold">{data.barcode}</span>
                  </div>
                ) : null}
              </div>
            </td>
          </tr> */}
          <tr>
            {/* Product Details */}
            <td width="16%" className="align-top">
              <div>
                <div className="fw-semibold mb-1 fs-val-md text-primary">
                  <BtnLink
                    className="fs-val-md"
                    onClick={(e) => {
                      e.stopPropagation();
                      viewProduct();
                    }}
                  >
                    {data.product.name}
                  </BtnLink>
                </div>
                <div className="mt-3 fs-val-md">
                  Product ID :
                  <BtnLink
                    className="fs-val-md"
                    onClick={(e) => {
                      e.stopPropagation();
                      viewProduct();
                    }}
                  >
                    {data.product.productId}
                  </BtnLink>
                </div>

                <div className="mt-3 fs-val-sm">
                  <span className="text-muted">PO ID: </span>
                  {data?.product?.poId.map((poId, index) => (
                    <span key={index}>
                      {index > 0 && ", "}
                      <BtnLink
                        className="fs-val-md"
                        onClick={(e) => {
                          e.stopPropagation();
                          NavService.openInNewTab("/purchase-order/view", {
                            id: poId,
                          });
                        }}
                      >
                        {poId}
                      </BtnLink>
                    </span>
                  ))}
                </div>

                <div className="mt-3 fs-val-sm">
                  <span className="text-muted">PO QTY: </span>
                  <span>{data?.product.quantity.requested}</span>
                </div>

                {data?.barcode?.length ? (
                  <div className="mt-3 fs-val-sm">
                    <span className="text-muted">Barcode: </span>
                    <span className="text-semibold">{data.barcode}</span>
                  </div>
                ) : null}
              </div>
            </td>

            {/* Case Qty & Offer code */}
            <td width="18%" className="align-top">
              <div className="fw-semibold mb-2">Case Qty Details</div>
              <div className="row mb-2">
                <div className="col">Case Qty</div>
                <div className="col-auto fw-bold">
                  {productDetails.caseQty} units
                </div>
              </div>

              {/* <div className="row mb-4">
                <div className="col">Is Case Qty Changed ?</div>
                <div className="col-auto">
                  <span>
                    <input
                      disabled={data.inputDisabled}
                      type="radio"
                      name="isCaseQtyChanged"
                      value={data.isCaseQtyChanged}
                      onChange={(e) =>
                        callback({
                          action: "formUpdate",
                          key: "isCaseQtyChanged",
                          value: "yes",
                          checked:
                            data.isCaseQtyChanged == "yes" ? true : false,
                          index,
                        })
                      }
                      options={isCaseQtyOptions}
                    />
                    <span className="fs-val-sm ms-2">Yes</span>
                  </span>

                  <span className="ps-3">
                    <input
                      disabled={data.inputDisabled}
                      type="radio"
                      name="isCaseQtyChanged"
                      value={data.isCaseQtyChanged}
                      onChange={(e) =>
                        callback({
                          action: "formUpdate",
                          key: "isCaseQtyChanged",
                          value: "no",
                          checked: data.isCaseQtyChanged == "no" ? true : false,
                          index,
                        })
                      }
                    />
                    <span className="fs-val-sm ms-2">No</span>
                  </span>
                </div>
              </div> */}

              {/* is Case Qty Changed checkbox */}
              <div className="col-12 mb-3">
                <input
                  disabled={data.inputDisabled}
                  type="checkbox"
                  checked={data.isCaseQtyChanged}
                  onChange={(e) =>
                    callback({
                      action: "formUpdate",
                      key: "isCaseQtyChanged",
                      value: e.target.checked,
                      index,
                    })
                  }
                />
                <span className="fs-val-sm ps-2"> Is Case Qty Changed ?</span>
              </div>

              {data.isCaseQtyChanged ? (
                <div className="row">
                  <div className="col-5 me-4">
                    <div className="mb-2">
                      <div className="fs-val-sm">
                        Product Case Qty<span className="text-danger">*</span>
                      </div>
                      <input
                        disabled={data.inputDisabled}
                        className="form-control form-control-sm"
                        value={data.productCaseQty}
                        type="number"
                        onChange={(e) =>
                          callback({
                            action: "formUpdate",
                            key: "productCaseQty",
                            value: e.target.value,
                            index,
                          })
                        }
                      />
                    </div>
                  </div>

                  {/* Upload Proofs */}
                  {!data?.caseQtyProof?.length ||
                  data?.caseQtyProof?.length < 5 ? (
                    <div className="col-2 pt-2">
                      <FileUpload
                        template={4}
                        callback={(d) => imgCallback("caseQtyProof", d)}
                        maxSize={10}
                        title="Upload Proofs"
                      />
                    </div>
                  ) : null}

                  {data?.caseQtyProof?.length > 0 ? (
                    <div className="col-12">
                      <label className="col-form-label" htmlFor="image">
                        Uploaded Proof's
                      </label>
                      <div style={imgStyle}>
                        <ImgSlider
                          assets={data.caseQtyProof}
                          removeType="icon"
                          onRemove={(e) =>
                            imageRemove("caseQtyProof", e, index)
                          }
                          swiperConfig={swiperConfig}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}

              {/* Offer Code Checkbox */}
              <div className="col-12 mb-3">
                <input
                  disabled={data.inputDisabled}
                  type="checkbox"
                  checked={data.hasOfferCode}
                  onChange={(e) =>
                    callback({
                      action: "formUpdate",
                      key: "hasOfferCode",
                      value: e.target.checked,
                      index,
                    })
                  }
                />
                <span className="fs-val-sm ps-2"> Has Offer ?</span>
              </div>

              {data.hasOfferCode ? (
                <div className="mt-3">
                  <div className="fs-val-sm">Offer Code</div>
                  <input
                    disabled={data.inputDisabled}
                    className="form-control form-control-sm"
                    type="text"
                    value={data.offerCode}
                    onChange={(e) =>
                      callback({
                        action: "formUpdate",
                        key: "offerCode",
                        value: e.target.value,
                        index,
                      })
                    }
                  />
                </div>
              ) : null}
            </td>

            {/* Quantity Details */}
            <td width="22%" className="align-top">
              <div className="fw-semibold mb-2">Quantity Details</div>
              <div className="row">
                {/* Invoice Qty */}
                <div className="col-4">
                  <div className="mb-3">
                    <div className="fs-val-sm">
                      Invoiced Qty <span className="text-danger">*</span>
                    </div>
                    <input
                      disabled={data.inputDisabled}
                      className="form-control form-control-sm"
                      type="number"
                      value={data.invoicedQty}
                      onChange={(e) =>
                        callback({
                          action: "formUpdate",
                          key: "invoicedQty",
                          value: e.target.value,
                          index,
                        })
                      }
                    />
                  </div>
                </div>

                {/*  Received Qty */}
                <div className="col-4">
                  <div className="mb-3">
                    <div className="fs-val-sm">
                      Received Qty <span className="text-danger">*</span>
                    </div>
                    <input
                      disabled={data.inputDisabled}
                      className="form-control form-control-sm"
                      type="number"
                      value={data.receivedQty}
                      onChange={(e) =>
                        callback({
                          action: "formUpdate",
                          key: "receivedQty",
                          value: e.target.value,
                          index,
                        })
                      }
                    />
                  </div>
                </div>

                {/* Shortage Qty */}
                <div className="col-4">
                  <div className="mb-3">
                    <div className="fs-val-sm">
                      Shortage Qty <span className="text-danger">*</span>
                    </div>
                    <input
                      disabled={data.inputDisabled}
                      className="form-control form-control-sm"
                      type="number"
                      value={data.shortageQty}
                      onChange={(e) =>
                        callback({
                          action: "formUpdate",
                          key: "shortageQty",
                          value: e.target.value,
                          index,
                        })
                      }
                    />
                  </div>
                </div>

                {/* isDamaged */}
                <div className="col-12 mb-3">
                  <input
                    disabled={data.inputDisabled}
                    type="checkbox"
                    checked={data.isDamaged}
                    onChange={(e) =>
                      callback({
                        action: "formUpdate",
                        key: "isDamaged",
                        value: e.target.checked,
                        index,
                      })
                    }
                  />
                  <span className="fs-val-sm"> is Damaged ?</span>
                </div>

                {/* If damaged is true then only we will will capture qty and reason  */}
                {data.isDamaged ? (
                  <>
                    {/* Damaged Reason */}
                    <div className="col-6">
                      <div className="mb-3">
                        <div className="fs-val-sm">
                          Damaged Reason <span className="text-danger">*</span>
                        </div>

                        <select
                          className=" form-select form-select-sm"
                          value={data.damagedReason}
                          onChange={(e) =>
                            callback({
                              action: "formUpdate",
                              key: "damagedReason",
                              value: e.target.value,
                              index,
                            })
                          }
                        >
                          {damageOptions.map((x) => (
                            <option value={x.value} key={x.label}>
                              {x.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* Damaged Qty  */}
                    <div className="col-4">
                      <div className="mb-3">
                        <div className="fs-val-sm">
                          Damaged Qty <span className="text-danger">*</span>
                        </div>
                        <input
                          disabled={data.inputDisabled}
                          className="form-control form-control-sm"
                          type="number"
                          value={data.damagedQty}
                          onChange={(e) =>
                            callback({
                              action: "formUpdate",
                              key: "damagedQty",
                              value: e.target.value,
                              index,
                            })
                          }
                        />
                      </div>
                    </div>

                    {/* Upload Proofs */}
                    {!data?.damageProof?.length ||
                    data?.damageProof?.length < 5 ? (
                      <div className="col-2 pt-2">
                        <FileUpload
                          template={4}
                          callback={(d) => imgCallback("damageProof", d)}
                          maxSize={10}
                          title="Upload Proofs"
                        />
                      </div>
                    ) : null}

                    {data?.damageProof?.length > 0 ? (
                      <div className="col-12">
                        <label className="col-form-label" htmlFor="image">
                          Uploaded {data?.damageProof?.length} Proof's
                        </label>
                        <div style={imgStyle}>
                          <ImgSlider
                            assets={data.damageProof}
                            removeType="icon"
                            onRemove={(e) =>
                              imageRemove("damageProof", e, index)
                            }
                            swiperConfig={swiperConfig}
                          />
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
            </td>

            {/* Pricing Details  */}
            <td width="20%" className="align-top">
              <div className="fw-semibold mb-2">Pricing Details</div>
              <div className="row">
                <div className="col-6">
                  <div className="mb-3">
                    <div className="fs-val-sm">
                      MRP <span className="text-danger">*</span>
                    </div>
                    <select
                      disabled={data.inputDisabled}
                      className=" form-select form-select-sm"
                      value={data.mrp}
                      onChange={(e) =>
                        callback({
                          action: "formUpdate",
                          key: "mrp",
                          value: e.target.value,
                          index,
                        })
                      }
                    >
                      {mrpOptions.map((x) => (
                        <option value={x.value} key={x.label}>
                          {x.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {data.mrp == "Other" ? (
                  <>
                    <div className="col-4">
                      <div className="mb-3">
                        <div className="fs-val-sm">
                          Other MRP <span className="text-danger">*</span>
                        </div>
                        <input
                          disabled={data.inputDisabled}
                          className="form-control form-control-sm"
                          value={data.otherMrp}
                          onChange={(e) =>
                            callback({
                              action: "formUpdate",
                              key: "otherMrp",
                              value: e.target.value,
                              index,
                            })
                          }
                        />
                      </div>
                    </div>

                    {/* Upload Proofs */}
                    {!data?.mrpProof?.length || data?.mrpProof?.length < 5 ? (
                      <div className="col-2 pt-2">
                        <FileUpload
                          template={4}
                          callback={(d) => imgCallback("mrpProof", d)}
                          maxSize={10}
                          title="Upload Proofs"
                        />
                      </div>
                    ) : null}

                    {data?.mrpProof?.length > 0 ? (
                      <div className="col-12 mb-3">
                        <label className="col-form-label" htmlFor="image">
                          Uploaded MRP Proof's
                        </label>
                        <div style={imgStyle}>
                          <ImgSlider
                            assets={data?.mrpProof}
                            removeType="icon"
                            onRemove={(e) => imageRemove("mrpProof", e, index)}
                            swiperConfig={swiperConfig}
                          />
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>

              <div className="fw-semibold mb-2">
                Enter HSN/TAX details from vendor Invoice
              </div>
              <div className="row mb-3">
                <div className="col-6">
                  <div className="mb-3">
                    <div className="fs-val-sm">
                      Vendor HSN <span className="text-danger">*</span>
                    </div>
                    <input
                      disabled={data.inputDisabled}
                      className="form-control form-control-sm"
                      value={data.vendorHsn}
                      onChange={(e) =>
                        callback({
                          action: "formUpdate",
                          key: "vendorHsn",
                          value: e.target.value,
                          index,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <div className="fs-val-sm">
                      Vendor Tax <span className="text-danger">*</span>
                    </div>

                    <select
                      disabled={data.inputDisabled}
                      className=" form-select form-select-sm"
                      value={data.vendorTax}
                      onChange={(e) =>
                        callback({
                          action: "formUpdate",
                          key: "vendorTax",
                          value: e.target.value,
                          index,
                        })
                      }
                    >
                      {vendorTaxOptions.map((x) => (
                        <option value={x.value} key={x.label}>
                          {x.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </td>

            {/* Manufacture Details */}
            <td width="18%" className="align-top">
              <div className="fw-semibold mb-2">Shelf Life</div>
              <div className="row">
                <div className="col-6">
                  <div className="fs-val-sm">
                    Manufacture Date <span className="text-danger">*</span>
                  </div>
                  <input
                    disabled={data.inputDisabled}
                    className="form-control form-control-sm"
                    type="date"
                    value={data.manufactureDate}
                    max={maxManufactureDateConfig}
                    onChange={(e) =>
                      callback({
                        action: "formUpdate",
                        key: "manufactureDate",
                        value: e.target.value,
                        index,
                      })
                    }
                  />
                </div>

                <div className="col-12  mt-3 fw-semibold mb-2">Expiry Date</div>

                <div className="col-6">
                  <div className="mb-3">
                    <div className="fs-val-sm">
                      Month <span className="text-danger">*</span>
                    </div>
                    <input
                      disabled={data.inputDisabled || !data.manufactureDate}
                      className="form-control form-control-sm"
                      value={data.month}
                      type="number"
                      onChange={(e) =>
                        callback({
                          action: "formUpdate",
                          key: "month",
                          value: e.target.value,
                          index,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="mb-3">
                    <div className="fs-val-sm">
                      Days <span className="text-danger">*</span>
                    </div>
                    <input
                      disabled={data.inputDisabled || !data.manufactureDate}
                      className="form-control form-control-sm"
                      value={data.days}
                      type="number"
                      onChange={(e) =>
                        callback({
                          action: "formUpdate",
                          key: "days",
                          value: e.target.value,
                          index,
                        })
                      }
                    />
                  </div>
                </div>

                {data?.month || data?.days ? (
                  <div className="col-12 mb-3">
                    <div className="fs-val-md text-danger">
                      <span>Expires On : </span>
                      <DateFormatter
                        date={data.expiryDate}
                        format="dd MMM yyyy"
                      />
                    </div>

                    <div className="fs-val-sm fw-bold text-muted">
                      Remaining Shelf Life : {data.shelfLifeDays} Days ,
                      <span>
                        {data.shelfLifePercentage > 100
                          ? 100
                          : data.shelfLifePercentage}
                      </span>
                      %
                    </div>
                  </div>
                ) : null}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span
                className={classNames(
                  "badge",
                  data.isScanned ? "bg-success" : "bg-danger"
                )}
              >
                {data.isScanned ? "Scanned" : "Not Scanned"}
              </span>

              {/* <span class="badge bg-danger">Not Scanned</span> */}

              {/* <div className="fw-semibold text-success">Scanned</div>
            <div className="fw-semibold text-danger">Not Scanned</div> */}
            </td>
            <td colSpan={4}>
              {barcode?.data?.length > 0} {barcode?.invalidBarcode}
              <div className="text-end">
                {barcode?.data?.length > 0 &&
                barcode?.invalidBarcode &&
                !data.isScanned ? (
                  <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={onClickAssignBarcode}
                  >
                    Assign Barcode
                  </button>
                ) : null}

                {data.isScanned ? (
                  <button
                    className="btn btn-sm btn-outline-danger ms-2"
                    onClick={onCancelScan}
                  >
                    Cancel Scan
                  </button>
                ) : null}

                <button
                  className="btn btn-sm btn-primary ms-2"
                  onClick={onClickSplit}
                >
                  Split
                </button>
                {productDetails?.hasBeenSplit ? (
                  <button
                    className="btn btn-sm btn-outline-danger ms-2"
                    onClick={onCancelSplit}
                  >
                    Cancel Split
                  </button>
                ) : null}

                <span className="ms-2">
                  Total :
                  <span className="text-semibold ps-1">
                    {data?.receivedQty *
                      (data?.mrp == "Other"
                        ? data?.otherMrp * 1
                        : data?.mrp * 1)}
                  </span>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default GrnInvoiceItem;
