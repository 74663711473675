import { Tabs } from "@sk/uis";
import { useState } from "react";
import GrnViewDebitAdjustment from "./GrnViewDebitAdjustment";
import GrnViewDebitAdjustmentLog from "./GrnViewDebitAdjustmentLog";

const GrnViewDebitAdjustmentTabLayout = ({ data, autoDebitAmounts, grnId }) => {
  const [activeTab, setActiveTab] = useState({ key: "details" });

  const tabs = [
    { key: "details", tabName: "Details" },
    { key: "log", tabName: "Audit Log" },
  ];

  return (
    <>
      <div className="mb-3">
        <Tabs
          data={tabs}
          callback={({ value }) => setActiveTab(value)}
          activeTab={activeTab}
          template={3}
        />
      </div>

      {activeTab.key === "details" && (
        <GrnViewDebitAdjustment
          data={data}
          autoDebitAmounts={autoDebitAmounts}
        />
      )}

      {activeTab.key === "log" && <GrnViewDebitAdjustmentLog grnId={grnId} />}
    </>
  );
};

export default GrnViewDebitAdjustmentTabLayout;
