import {
  BrandService,
  CommonService,
  PoService,
  ProductService,
} from "@sk/services";
import {
  Amount,
  AppCard,
  AppTitle,
  NoDataFound,
  PageLoader,
  SelectInput,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
  Toaster,
  Tabs,
} from "@sk/uis";
import { produce } from "immer";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";

// Canvas Style
const CANVAS_STYLE = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "85%",
  },
};

const TABLE_STYLE = {
  height: "calc(100vh - 200px)",
};

const TABLE_HEADER = [
  { label: "" },
  { label: "Sl.no" },
  { label: "Name", width: "30%" },
  { label: "Deals", isCentered: true },
  { label: "MRP (Rs.)", isCentered: true },
  { label: "Stock", isCentered: true },
  { label: "Suggested Qty", isCentered: true },
  { label: "PO Qty", width: "10%", isCentered: true },
  { label: "Pending Order Qty", isCentered: true },
  { label: "Orders [30 days]", isCentered: true },
];

const getBrandIdsFromMargin = (margins) =>
  (margins || []).filter((x) => x.brand).map((x) => x.brand);

const TAB_OPTIONS = [
  { key: "most_purchased", tabName: "Most Purchased" },
  { key: "top_moving", tabName: "Top Moving" },
  { key: "slow_moving", tabName: "Slow Moving" },
];

const LoadByBrandModal = ({
  show,
  margins,
  wh,
  callback,
  existingProductsIds = [],
}) => {
  const { register, control, setValue, getValues } = useForm({
    defaultValues: { brands: [], selectedBrand: "" },
  });
  const [display, setDisplay] = useState("loading");
  const [products, setProducts] = useState([]);
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [isLoadingCount, setIsLoadingCount] = useState(true);
  const [activeTab, setActiveTab] = useState(TAB_OPTIONS[0]);

  const apiAbortCtrl = useRef(null);
  const offsetRef = useRef(0);
  const pagination = useRef({ rowsPerPage: 10, totalRecords: 0 });
  const brands = useWatch({ control, name: "brands" });

  const fetchBrands = useCallback(async () => {
    const brandIds = getBrandIdsFromMargin(margins);
    if (!brandIds.length) return [];

    const response = await BrandService.getList({
      page: 1,
      count: brandIds.length,
      filter: { _id: { $in: brandIds } },
      select: "name",
    });
    return response.resp || [];
  }, [margins]);

  const initialize = useCallback(async () => {
    try {
      setDisplay("loading");
      const brands = await fetchBrands();
      const brandOptions = brands.map((b) => ({ label: b.name, value: b._id }));

      setValue("brands", brandOptions);
      if (!brandOptions.length) {
        setDisplay("no-brands");
        return;
      }

      const initialBrand = brandOptions[0].value;
      setValue("selectedBrand", initialBrand);
      await loadProducts(initialBrand);
      await loadProductCount(initialBrand);
    } catch (error) {
      Toaster.error("Failed to initialize brands");
    }
  }, [fetchBrands, setValue]);

  const loadProducts = useCallback(async (brandId, offset = 0) => {
    if (apiAbortCtrl.current) {
      apiAbortCtrl.current.abort();
      setTimeout(() => loadProducts(brandId, offset), 300);
      return;
    }

    try {
      apiAbortCtrl.current = new AbortController();
      setIsLoadingProduct(true);

      const response = await ProductService.getList(
        {
          page: offset / pagination.current.rowsPerPage + 1,
          count: pagination.current.rowsPerPage,
          sort: { name: 1 },
          select: "name, brand, category, mrp",
          filter: { is_consumable: false, brand: brandId, is_active: true },
        },
        { signal: apiAbortCtrl.current.signal }
      );

      const newProducts = (response.resp || []).map((product) => ({
        ...product,
        loadingMoreData: false,
        _checked: false,
        _enteredQty: "",
      }));

      setProducts((prev) =>
        offset === 0 ? newProducts : [...prev, ...newProducts]
      );
      setDisplay("products");

      await enrichProducts(newProducts);
    } catch (error) {
      if (error.name !== "AbortError") Toaster.error("Failed to load products");
    } finally {
      setIsLoadingProduct(false);
      apiAbortCtrl.current = null;
    }
  }, []);

  const enrichProducts = useCallback(
    async (products) => {
      const enriched = await Promise.all(
        products.map(async (product) => {
          if (apiAbortCtrl.current?.signal.aborted) return product;

          try {
            const data = await PoService.prepareProductData(
              product,
              margins,
              wh,
              {
                apiConfig: { signal: apiAbortCtrl.current?.signal },
              }
            );
            return { ...product, ...data, loadingMoreData: false };
          } catch (error) {
            return product;
          }
        })
      );

      setProducts((prev) =>
        prev.map((p) => enriched.find((e) => e._id === p._id) || p)
      );
    },
    [margins, wh]
  );

  const loadProductCount = useCallback(async (brandId) => {
    try {
      setIsLoadingCount(true);
      const response = await ProductService.getCount({
        filter: { is_consumable: false, brand: brandId, is_active: true },
      });
      pagination.current.totalRecords = response.resp || 0;
    } finally {
      setIsLoadingCount(false);
    }
  }, []);

  const handleBrandChange = useCallback(
    async (brandId) => {
      offsetRef.current = 0;
      await loadProducts(brandId);
      await loadProductCount(brandId);
    },
    [loadProducts, loadProductCount]
  );

  const handleCheckboxChange = useCallback((index) => {
    setProducts(
      produce((draft) => {
        draft[index]._checked = !draft[index]._checked;
        if (!draft[index]._checked) draft[index]._enteredQty = "";
      })
    );
  }, []);

  const handleQtyChange = useCallback((index, value) => {
    const qty = value ? CommonService.roundedByDecimalPlace(value, 0) : "";
    setProducts(
      produce((draft) => {
        draft[index]._enteredQty = qty;
        draft[index]._checked = !!qty;
      })
    );
  }, []);

  const handleSubmit = () => {
    const selected = products.filter((p) => p._checked && p._enteredQty);
    if (!selected.length) {
      Toaster.error("Please select at least one product");
      return;
    }

    callback({
      action: "submitted",
      data: selected.map((p) =>
        PoService.formatPoProductResp({ ...p, _form: { qty: p._enteredQty } })
      ),
    });
  };

  const handleTabChange = useCallback(
    ({ value }) => {
      setActiveTab(value);
      offsetRef.current = 0;
      loadProducts(getValues("selectedBrand"), 0);
    },
    [loadProducts, getValues]
  );

  useEffect(() => {
    if (show) initialize();
    return () => apiAbortCtrl.current?.abort();
  }, [show, initialize]);

  const selectedCount = products.filter(
    (p) => p._checked && p._enteredQty
  ).length;
  const canLoadMore =
    offsetRef.current + pagination.current.rowsPerPage <
    pagination.current.totalRecords;

  return (
    <Offcanvas
      show={show}
      onHide={() => callback({ action: "close" })}
      backdrop="static"
      placement="end"
      style={CANVAS_STYLE.offCanvasStyle}
    >
      <Offcanvas.Header
        className="bg-light"
        closeButton
        style={CANVAS_STYLE.offCanvasHeaderStyle}
      >
        <AppTitle
          title="Product Selection"
          className="fs-val-lg text-dark px-2"
        />
      </Offcanvas.Header>

      <Offcanvas.Body className="modal-bg">
        {display === "loading" && <PageLoader />}

        {display === "products" && (
          <>
            <div className="row g-0 mb-3">
              <div className="col-3">
                <SelectInput
                  name="selectedBrand"
                  label="Select Brand"
                  register={register}
                  options={brands}
                  callback={handleBrandChange}
                />
              </div>
            </div>

            <AppCard noPad={true}>
              <div className="border-bottom">
                <Tabs
                  data={TAB_OPTIONS}
                  activeTab={activeTab}
                  callback={handleTabChange}
                  template={4}
                />
              </div>
              <div
                className="tbl-scroll-container custom-scrollbar thin-scrollbar p-4"
                style={TABLE_STYLE}
              >
                <table className="table table-sm">
                  <TableHeader data={TABLE_HEADER} noBg isSticky={true} />
                  <tbody className="fs-val-sm">
                    {isLoadingProduct ? (
                      <TableSkeletonLoader
                        cols={TABLE_HEADER.length}
                        rows={pagination.current.rowsPerPage}
                        height={55}
                      />
                    ) : products.length === 0 ? (
                      <tr>
                        <td colSpan={TABLE_HEADER.length}>
                          <NoDataFound>No products found</NoDataFound>
                        </td>
                      </tr>
                    ) : (
                      products.map((product, index) => (
                        <tr key={product._id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={product._checked}
                              onChange={() => handleCheckboxChange(index)}
                              disabled={product.loadingMoreData}
                            />
                          </td>
                          <td className="text-center">{index + 1}</td>
                          <td>
                            <div>
                              {product.name} ({product._id})
                            </div>
                            {existingProductsIds.includes(product._id) && (
                              <div className="fs-val-sm text-warning fw-semibold mt-1">
                                **Already in PO**
                              </div>
                            )}
                          </td>
                          <td className="text-center">
                            {product.loadingMoreData ? (
                              <Spinner />
                            ) : (
                              product._dealCount || 0
                            )}
                          </td>
                          <td className="text-center">
                            <Amount value={product.mrp} decimalPlace={2} />
                          </td>
                          <td className="text-center">
                            {product.loadingMoreData ? (
                              <Spinner />
                            ) : (
                              product._stock?.avaiableQty || 0
                            )}
                          </td>
                          <td className="text-center">
                            {product.loadingMoreData ? (
                              <Spinner />
                            ) : (
                              product._suggestedQuantity || 0
                            )}
                          </td>
                          <td>
                            <input
                              className="form-control"
                              placeholder="Enter Qty"
                              value={product._enteredQty}
                              onChange={(e) =>
                                handleQtyChange(index, e.target.value)
                              }
                              disabled={product.loadingMoreData}
                            />
                          </td>
                          <td className="text-center">
                            {product.loadingMoreData ? (
                              <Spinner />
                            ) : (
                              product._pendingOrder || 0
                            )}
                          </td>
                          <td className="text-center">
                            {product.loadingMoreData ? (
                              <Spinner />
                            ) : (
                              product._sales?.last30 || 0
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>

              {canLoadMore && (
                <div className="text-center my-3">
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      offsetRef.current += pagination.current.rowsPerPage;
                      loadProducts(
                        getValues("selectedBrand"),
                        offsetRef.current
                      );
                    }}
                    disabled={isLoadingProduct}
                  >
                    {isLoadingProduct ? "Loading..." : "Load More"}
                  </button>
                </div>
              )}
            </AppCard>
          </>
        )}
      </Offcanvas.Body>

      <Offcanvas.Header>
        <div className="col-12 text-end">
          <button className="btn btn-primary" onClick={handleSubmit}>
            Submit ({selectedCount})
          </button>
        </div>
      </Offcanvas.Header>
    </Offcanvas>
  );
};

export default memo(LoadByBrandModal);
