import { useAttachAdditionalData } from "@sk/hooks";
import { AppTitle, DateFormatter, PaginationBlock, TableHeader } from "@sk/uis";
import { cloneDeep } from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";

const tableHeaders = [
  {
    label: "Sl No",
    width: "5%",
    key: "slNo",
    isCentered: true,
  },
  {
    label: "Key",
    width: "10%",
    key: "key",
  },
  {
    label: "Old Value",
    width: "10%",
    key: "oldValue",
  },
  {
    label: "New Value",
    width: "10%",
    key: "newValue",
  },
  {
    label: "Remarks",
    width: "10%",
    key: "remarks",
  },
  {
    label: "Created On",
    width: "10%",
    key: "createdOn",
  },
];

const attachAdditionalDataConfig = [
  {
    key: "loggedBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_user",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "75%",
  },
};

export const AuditLogModal = ({ auditLogs, show, closeModal, dealDetails }) => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [data, setData] = useState([]);

  const paginationRef = useRef({
    ...defaultPagination,
    totalRecords: auditLogs.length,
  });

  useEffect(() => {
    if (show) {
      const list = cloneDeep(
        auditLogs.slice(0, 10).map((x) => {
          x.userLoading = true;
          return x;
        })
      );
      setData(list);
      let tmp = [];
      setAdditionalData(list, attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          setData([...attachAllData(list, tmp)]);
        }
      });
    }
  }, [show, attachAllData, setAdditionalData, auditLogs]);

  const paginationCb = useCallback(
    (r) => {
      paginationRef.current.startSlNo = r.startSlNo;
      paginationRef.current.endSlNo = r.endSlNo;
      paginationRef.current.activePage = r.activePage;
      const d = auditLogs.slice(
        paginationRef.current.startSlNo - 1,
        paginationRef.current.endSlNo
      );
      setData(d);
      let tmp = [];
      setAdditionalData(d, attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          setData([...attachAllData(d, tmp)]);
        }
      });
    },
    [attachAllData, auditLogs, setAdditionalData]
  );

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          closeVariant="white"
          style={style.offCanvasHeaderStyle}
        >
          {/* Modal Title */}
          <AppTitle title="Audit Logs" className="fs-val-lg text-dark px-2" />
        </Offcanvas.Header>

        <Offcanvas.Body className="p-0 bg-light">
          <div className="p-4 bg-white border-bottom">
            <div className="row">
              <div className="col-12">
                <div className="text-muted fs-val-sm">Deal Name</div>
                <div className="text-primary fs-val-lg class-name fw-semibold">
                  {dealDetails.name} - {dealDetails.dealId}
                </div>
              </div>
            </div>
          </div>

          <table className="table table-bordered">
            <TableHeader data={tableHeaders} />
            <tbody className="fs-val-sm">
              {data.length == 0 ? (
                <tr>
                  <td
                    colSpan={tableHeaders.length}
                    className="text-center py-2"
                  >
                    No Audit logs found
                  </td>
                </tr>
              ) : null}
              {data.map((x, k) => (
                <tr key={x._id}>
                  <td className="text-center">
                    {paginationRef.current.startSlNo + k}
                  </td>
                  <td>{x.paramName}</td>
                  <td>{x.oldData}</td>
                  <td>{x.newData}</td>
                  <td>{x.message}</td>
                  <td>
                    <DateFormatter date={x.loggedAt} />
                    <div>by {x._user?.name || x.loggedBy}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <PaginationBlock
            paginationConfig={paginationRef.current}
            paginationCb={paginationCb}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(AuditLogModal);
/*


*/
