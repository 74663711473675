import classNames from "classnames";
import { useFetchUrlQueryString } from "@sk/hooks";
import { OmsService, appConfigs } from "@sk/services";
import {
  Amount,
  DateFormatter,
  ImgPreviewModal,
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "../../PosPo.module.scss";
const headers = [
  { label: "Sl.no", isCentered: true },
  { label: "Invoice Ref ID", isCentered: true },
  { label: "Invoice On", isCentered: true },
  { label: "Deals" },
  { label: "Invoice value" },
  { label: "Remarks" },
  { label: "Attachment", width: "15%" },
];

const getData = async (id) => {
  const r = await OmsService.getIntakeSummaryList({
    filter: {
      poId: id,
    },
  });

  return Array.isArray(r.resp) ? r.resp : [];
};

const InvoiceDetails = ({ data, openModal, invoiceDetails }) => {
  const [showImgPreviewModal, setShowImgPreviewModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const [invoiceData, setInvoiceData] = useState([]);

  const imgModalRef = useRef({
    imgIndex: -1,
    invoiceIndex: -1,
  });

  // const invoiceData = useMemo(
  //   () =>
  //     data.map((x) => {
  //       // updating asset
  //       x.assets = x.documentAssetIds.map((asset) => {
  //         return {
  //           id: asset,
  //           isDownload: true,
  //         };
  //       });

  //       return x;
  //     }),
  //   [data]
  // );

  const handleCloseImgModal = useCallback(() => {
    imgModalRef.current = {
      imgIndex: -1,
      invoiceIndex: -1,
    };
    setShowImgPreviewModal(false);
  }, []);

  const openImgPreviewModal = useCallback((imgIndex, invoiceIndex) => {
    imgModalRef.current = { imgIndex, invoiceIndex };
    setShowImgPreviewModal(true);
  }, []);

  // const downloadImage = async (assetId) => {
  //   const url = appConfigs.ASSET + "/" + assetId;

  //   window.open(url, "_blank");
  // };

  const [searchParams] = useSearchParams();

  const query = useFetchUrlQueryString(searchParams);

  useEffect(() => {
    if (query.id) {
      loadInvoiceDetails();
    }
  }, [query.id, loadInvoiceDetails]);

  const loadInvoiceDetails = useCallback(async () => {
    setLoading(true);

    const resp = await getData(query.id);

    const list = data.map((x) => {
      x.assets = x.documentAssetIds.map((asset) => {
        return {
          id: asset,
          isDownload: true,
        };
      });

      const tempList = resp.filter((r, i) => {
        return x.refno == r.productList?.[0]?.invoiceNo;
      });

      x.productList = tempList?.[0]?.productList ?? [];

      return x;
    });

    setInvoiceData(list);

    setLoading(false);
  }, [data, query.id]);

  const getInwardProductCount = (productList, refno) => {
    return productList.filter(
      (x) => x.status != "Rejected" && refno == x.invoiceNo
    ).length;
  };

  return (
    <div className="p-3">
      <table className="table table-bordered">
        <TableHeader data={headers} />
        <tbody>
          {!loading && !invoiceData.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound> No invoice data found </NoDataFound>
              </td>
            </tr>
          ) : null}

          {loading ? (
            <TableSkeletonLoader rows={5} cols={headers.length} />
          ) : null}

          {!loading &&
            invoiceData.map((x, index) => (
              <tr
                key={index}
                className={classNames("fs-val-md", {
                  [styles.blinkRow]: invoiceDetails?.id == x.refno,
                })}
              >
                <td className="text-center">{index + 1}</td>
                <td className="text-center"> {x.refno}</td>

                <td className="text-center">
                  <DateFormatter date={x.invoiceDate} format="dd MMM yyyy" />
                </td>

                <td>
                  {getInwardProductCount(x.productList, x.refno)}
                  {x.productList.length ? (
                    <button
                      className="btn btn-link m-0 p-0 ms-1"
                      onClick={() => openModal(x)}
                    >
                      view
                    </button>
                  ) : null}
                </td>

                <td className="text-danger fw-semibold">
                  <Amount value={x.amount} decimalPlace={2} />
                </td>

                <td> {x.remarks || "N/A"} </td>
                <td>
                  {x.assets.map((asset, i) => (
                    <div key={asset.id}>
                      <button
                        className="btn btn-link btn-sm me-2 fs-val-sm"
                        onClick={() => openImgPreviewModal(i, index)}
                      >
                        Attachment {i + 1}
                      </button>
                      {asset.isDownload && (
                        <a
                          href={appConfigs.ASSET + "/" + asset.id}
                          className="btn btn-link btn-sm fs-val-sm"
                        >
                          Download
                        </a>
                      )}
                    </div>
                  ))}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <ImgPreviewModal
        show={showImgPreviewModal}
        handleClose={handleCloseImgModal}
        assets={invoiceData[imgModalRef.current.invoiceIndex]?.assets || []}
        imageIndex={imgModalRef.current.imgIndex}
      />
    </div>
  );
};

export default memo(InvoiceDetails);
