import { CommonService, ProductService } from "@sk/services";
import { Amount, KeyVal, PageLoader, Toaster } from "@sk/uis";
import { produce } from "immer";
import { debounce } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const ManageMrpModal = ({
  show = false,
  currentMrp,
  mrpList,
  closeModal,
  mode = "edit",
  callback,
  index,
  pId,
}) => {
  const [amount, setAmount] = useState("");

  const [data, setData] = useState([]);

  const [error, setError] = useState("");

  const [productDetails, setProductDetails] = useState({});

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (show) {
      setLoading(true);
      loadProductDetails();
      setData(mrpList);
    }
  }, [mrpList, show, loadProductDetails]);

  const onAdd = () => {
    const a = Number(amount);
    let isMrpGreater = a >= currentMrp / 2 + currentMrp;
    let isMrpLesser = a <= currentMrp / 2;
    let msg = "";
    if (isNaN(a) || !a) {
      msg = "Please provide valid MRP";
    } else if (isMrpGreater) {
      msg = "MRP cannot be greater than 50% of current MRP";
    } else if (isMrpLesser) {
      msg = "MRP cannot be lesser than 50% of current MRP";
    } else if (a == currentMrp) {
      msg = "Duplicate MRP";
    } else if (data.includes(a)) {
      msg = "MRP already in list";
    }

    if (msg) {
      setError(msg);
      return;
    } else {
      setError("");
    }

    setData(
      produce((draft) => {
        draft.push(a);
      })
    );
    setAmount("");
  };

  const onRemove = (index) => {
    setData(
      produce((draft) => {
        draft.splice(index, 1);
      })
    );
  };

  const onSubmit = () => {
    if (!data.length) {
      Toaster.error("Please add at least one MRP before submit");
      return;
    }
    callback({ mrpList: data, index: index });
    closeModal();
  };

  const debounceAmount = useCallback(
    debounce(() => {
      let val = amount;
      val = CommonService.roundedByDecimalPlace(val, 2);
      if (val) {
        if (val < 0) {
          setAmount("");
        }
        setAmount(val);
      }
    }, 700),
    [amount]
  );

  const loadProductDetails = useCallback(async () => {
    setLoading(true);
    const r = await ProductService.getProduct(pId);
    if (r.statusCode !== 200) {
      setProductDetails({});
      Toaster.error("Unable to fetch, Please try later");
      closeModal();
    } else {
      setProductDetails(r.resp);
    }
    setLoading(false);
  }, [pId, closeModal]);

  useEffect(() => {
    debounceAmount();
    return () => debounceAmount.cancel();
  }, [debounceAmount]);

  return (
    <Modal show={show} onHide={closeModal} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title className="pb-0 mb-0">
          {mode == "edit" ? "Add MRP " : "MRP Details"}{" "}
        </Modal.Title>
      </Modal.Header>
      {show && (
        <Modal.Body>
          {loading ? (
            <PageLoader />
          ) : (
            <>
              <div className="row mb-2">
                <div className="col-12">
                  <KeyVal template="col" label="Product Name">
                    <div className="fw-semibolld">
                      {productDetails?.name} -{productDetails._id}
                    </div>
                  </KeyVal>
                </div>
              </div>
              <div className="mb-2">
                <span>Current MRP: </span>
                <span className="fw-bold text-primary">
                  <Amount value={currentMrp} decimalPlace={2} />
                </span>
              </div>
              {mode == "edit" && (
                <div className="row mb-3">
                  <div className="col">
                    <input
                      className="form-control"
                      placeholder="Enter MRP"
                      type="number"
                      onChange={(e) => setAmount(e.target.value)}
                      value={amount}
                    />
                  </div>
                  <div className="col-auto">
                    <button className="btn btn-primary" onClick={onAdd}>
                      ADD
                    </button>
                  </div>
                  {error && (
                    <div className="col-12 text-danger fs-val-sm mb-2">
                      {error}
                    </div>
                  )}
                  {data.length > 0 && (
                    <div className="col-12 text-warning fs-val-sm">
                      {" "}
                      There is variance on the existing MRP. Max variance
                      allowed is 50%.
                    </div>
                  )}
                </div>
              )}
              <div>
                <table className="table table-condensed table-bordered">
                  <thead>
                    <tr>
                      <th width="15%" className="text-center">
                        Sl No.
                      </th>
                      <th className="text-center">MRP</th>
                      {mode == "edit" && (
                        <th width="15%" className="text-center">
                          Action
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {data.length == 0 && (
                      <tr className="fs-val-md">
                        <td
                          className="text-center"
                          colSpan={mode == "edit" ? 3 : 2}
                        >
                          No MRP Found
                        </td>
                      </tr>
                    )}

                    {data.length > 0 &&
                      data.map((x, index) => (
                        <tr className="fs-val-md" key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">
                            <Amount value={x} decimalPlace={2} />
                          </td>
                          {mode == "edit" && (
                            <td className="text-center">
                              <button
                                className="btn btn-link text-danger fs-val-md"
                                onClick={onRemove}
                              >
                                Remove
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </Modal.Body>
      )}
      {mode == "edit" && !loading && (
        <Modal.Footer>
          <div>
            <button className="btn btn-success" onClick={onSubmit}>
              Submit
            </button>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default memo(ManageMrpModal);
