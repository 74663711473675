import { useAttachAdditionalData } from "@sk/hooks";
import { DateFormatter, HighlightText, KeyVal, Spinner } from "@sk/uis";
import { memo, useEffect, useState } from "react";

const attachAdditionalDataConfig = [
  {
    key: "verifiedBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_verifiedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const PoApproved = ({ data }) => {
  const [details, setDetails] = useState(() => ({
    ...data,
    userLoading: true,
  }));
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  useEffect(() => {
    if (data._id) {
      let tmp = [];
      setAdditionalData([{ ...data }], attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          const t = [...attachAllData([{ ...data }], tmp)][0];
          setDetails((v) => ({ ...v, ...t }));
        }
      });
    }
  }, [attachAllData, data, setAdditionalData]);

  console.log(details);

  return (
    <div className="row">
      <div className="col-3 mb-3">
        <KeyVal label="Is Case" template="col">
          <HighlightText status={details.isCaseQtyVerified ? "Yes" : "No"} />
        </KeyVal>
      </div>
      <div className="col-3 mb-3">
        <KeyVal label="Stock Verified" template="col">
          <HighlightText status={details.isStockVerified ? "Yes" : "No"} />
        </KeyVal>
      </div>
      <div className="col-3 mb-3">
        <KeyVal label="Sale Verified" template="col">
          <HighlightText status={details.isSalesVerified ? "Yes" : "No"} />
        </KeyVal>
      </div>
      <div className="col-3 mb-3">
        <KeyVal label="Approved On" template="col">
          {details.verifiedAt ? (
            <DateFormatter date={details.verifiedAt} />
          ) : (
            "--"
          )}
        </KeyVal>
      </div>
      <div className="col-3 mb-3">
        <KeyVal label="Approved By" template="col">
          {details.userLoading ? (
            <Spinner type="dots" />
          ) : details?._verifiedBy?.name ? (
            details?._verifiedBy?.name
          ) : (
            "--"
          )}
        </KeyVal>
      </div>
      <div className="col-9 mb-3">
        <KeyVal label="Approved Remarks" template="col">
          {data.verifiedRemark || "--"}
        </KeyVal>
      </div>
    </div>
  );
};

export default memo(PoApproved);
