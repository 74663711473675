import activeImg from "../../assets/imgs/active.svg";
import notLinkedImg from "../../assets/imgs/not-linked.svg";
import totalImg from "../../assets/imgs/total.svg";

const listView = {
  breadcrumbs: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "GRN List",
    },
  ],
  pagination: {
    totalRecords: 0,
    rowsPerPage: 50,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 50,
  },

  summaryCard: [
    {
      label: "Total GRNs",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Created",
      value: 0,
      loading: true,
      filter: { status: "Created" },
      color: "success",
      img: activeImg,
    },
    {
      label: "Pending Stock Inward",
      value: 0,
      loading: true,
      filter: { status: "Pending Stock Inward" },
      color: "primary",
      img: notLinkedImg,
    },
    {
      label: "Pending Inward Approval",
      value: 0,
      loading: true,
      color: "danger",
      filter: { status: "Pending Inward Approval" },
      img: notLinkedImg,
    },
    {
      label: "Pending Putaway",
      value: 0,
      loading: true,
      color: "success",
      filter: { status: "Pending Putaway" },
      img: activeImg,
    },
    {
      label: "Partial Putaway",
      value: 0,
      loading: true,
      color: "success",
      filter: { status: "Partial Putaway" },
      img: activeImg,
    },
    {
      label: "Pending Bill Matching",
      value: 0,
      loading: true,
      filter: { status: "Pending Bill Matching" },
      color: "danger",
      img: notLinkedImg,
    },
    {
      label: "Payment Pending",
      value: 0,
      loading: true,
      filter: { status: "Payment Pending" },
      color: "danger",
      img: notLinkedImg,
    },
    {
      label: "Rejected",
      value: 0,
      loading: true,
      filter: { status: "Rejected" },
      color: "danger",
      img: notLinkedImg,
    },
    {
      label: "Closed",
      value: 0,
      loading: true,
      filter: { status: "Closed" },
      color: "danger",
      img: notLinkedImg,
    },
  ],
  formLabels: {
    vendor: {
      label: "Vendor",
      valuePath: "[0].value.name",
    },
    brand: {
      label: "Brand",
      valuePath: "[0].value.name",
    },
    category: {
      label: "Category",
      valuePath: "[0].value.name",
    },
    product: {
      label: "Product",
      valuePath: "[0].value.name",
    },
    dateRange: {
      label: "Inwarded On",
      type: "dateRange",
    },
    type: {
      label: "PO Type",
    },
    lockedPo: {
      label: "Locked PO",
    },
    wh: {
      label: "Warehouse",
    },
    status: {
      label: "Status",
    },
  },
  attachAdditionalConfig: [
    {
      key: "whId",
      api: "franchise",
      loadingKey: "whLoading",
      dataKey: "_wh",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],
};

export default listView;
