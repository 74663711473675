import { NoDataFound, TableHeader } from "@sk/uis";
import { memo, useMemo } from "react";
import { Offcanvas } from "react-bootstrap";
// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "85%",
  },
};

const tableHeader = [
  { label: "Sl.no" },
  { label: "From Bank" },
  { label: "To Bank" },
  { label: "To Bank IFSC " },
  { label: "To Bank UTR" },
  { label: "To Bank Branch" },
  { label: "Invoice ID" },
  { label: "Payment Amount" },
  { label: "Payment Mode" },
];

const PaymentDetailsModal = ({ show, closeModal, data }) => {
  const paymentDetails = useMemo(
    () => (Array.isArray(data) ? data : [data]),
    [data]
  );
  return (
    <Offcanvas
      show={show}
      backdrop="static"
      onHide={closeModal}
      placement="end"
      style={style.offCanvasStyle}
    >
      <Offcanvas.Header
        closeButton
        style={style.offCanvasHeaderStyle}
        closeVariant="white"
      >
        <Offcanvas.Title className="mb-0 pb-0">
          Transaction Details #{data.invoiceNo}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0">
        <div className="py-4">
          <table className="table table-bordered">
            <TableHeader data={tableHeader} />
            <tbody>
              {!paymentDetails.length ? (
                <tr className="fs-val-md">
                  <td colSpan={9}>
                    <NoDataFound> No Transaction Details Found </NoDataFound>
                  </td>
                </tr>
              ) : null}

              {paymentDetails.length
                ? paymentDetails.map((x, i) => (
                    <tr className="fs-val-md" key={i}>
                      <td className="text-center">{i + 1}</td>
                      <td>
                        <div className="text-primary fw-semibold">
                          {x.fromBankName}
                        </div>
                        <span className="fs-val-sm fw-bold me-1">
                          Account No
                        </span>
                        <span className="fs-val-sm  me-1">
                          {x.fromBankAccountNo}
                        </span>
                      </td>
                      <td>
                        <div className="text-primary fw-semibold">
                          {x.toBankName}
                        </div>
                        <span className="fs-val-sm fw-bold  me-1">
                          Account No
                        </span>
                        <span className="fs-val-sm me-1">
                          {x.toBankAccountNo}
                        </span>
                      </td>
                      <td> {x.toBankIfscCode} </td>

                      <td>{x.toBankUtr}</td>

                      <td>{x.toBankBranch}</td>

                      <td> {x.invoiceNo}</td>

                      <td>{x.invoiceAmount}</td>
                      <td>{x.paymentMethod}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
        {/* <div className="row">
          <div className="col-3 mb-2">
            <KeyVal label="From Bank" template="col">
              <span className="fw-semibold">{data.fromBankName}</span>
            </KeyVal>
          </div>
          <div className="col-3 mb-2">
            <KeyVal label="From Bank Account No" template="col">
              <span className="fw-semibold">{data.fromBankAccountNo}</span>
            </KeyVal>
          </div>
          <div className="col-3 mb-2">
            <KeyVal label="To Bank" template="col">
              <span className="fw-semibold">{data.toBankName}</span>
            </KeyVal>
          </div>
          <div className="col-3 mb-2">
            <KeyVal label="To Bank No" template="col">
              <span className="fw-semibold">{data.toBankAccountNo}</span>
            </KeyVal>
          </div>
          <div className="col-3 mb-2">
            <KeyVal label="To Bank IFSC Code" template="col">
              <span className="fw-semibold"> {data.toBankIfscCode}</span>
            </KeyVal>
          </div>
          <div className="col-3 mb-2">
            <KeyVal label="To Bank UTR Code" template="col">
              <span className="fw-semibold">{data.toBankUtr}</span>
            </KeyVal>
          </div>

          <div className="col-3 mb-2">
            <KeyVal label="To Bank Branch" template="col">
              <span className="fw-semibold">{data.toBankBranch}</span>
            </KeyVal>
          </div>

          <div className="col-3 mb-2">
            <KeyVal label="Invoice Id" template="col">
              <span className="fw-semibold">
                <Amount value={data.invoiceNo} decimalPlace={2} />
              </span>
            </KeyVal>
          </div>

          <div className="col-3 mb-2">
            <KeyVal label="Payment Value" template="col">
              <span className="fw-semibold">
                <Amount value={data.invoiceAmount} decimalPlace={2} />
              </span>
            </KeyVal>
          </div>

          <div className="col-3 mb-2">
            <KeyVal label="Payment Mode" template="col">
              <span className="fw-semibold">{data.paymentMethod}</span>
            </KeyVal>
          </div>
        </div> */}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default memo(PaymentDetailsModal);
