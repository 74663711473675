// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ugRPAjJltBBTy6So724i{animation:pkivHIHS2sIHPRLS7eXi 2s ease-in-out 2}.orZtD0We8XeRxcw__m0a{animation:djXpHN7uzvJSrX7l_aTy 2s ease-in-out 2}@keyframes pkivHIHS2sIHPRLS7eXi{0%{background-color:#fff}50%{background-color:#add8e6}100%{background-color:#fff}}@keyframes djXpHN7uzvJSrX7l_aTy{0%{color:red}50%{color:#07316e}100%{color:red}}", "",{"version":3,"sources":["webpack://./src/pages/pos/PosPo.module.scss"],"names":[],"mappings":"AAAA,sBACE,+CAAA,CAGF,sBACE,+CAAA,CAGF,gCACE,GACE,qBAAA,CAEF,IACE,wBAAA,CAEF,KACE,qBAAA,CAAA,CAIJ,gCACE,GACE,SAAA,CAEF,IACE,aAAA,CAEF,KACE,SAAA,CAAA","sourcesContent":[".blinkRow {\n  animation: blink 2s ease-in-out 2;\n}\n\n.blinkText {\n  animation: blinktext 2s ease-in-out 2;\n}\n\n@keyframes blink {\n  0% {\n    background-color: white;\n  }\n  50% {\n    background-color: lightblue;\n  }\n  100% {\n    background-color: white;\n  }\n}\n\n@keyframes blinktext {\n  0% {\n    color: red;\n  }\n  50% {\n    color: rgb(7, 49, 110);\n  }\n  100% {\n    color: red;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blinkRow": "ugRPAjJltBBTy6So724i",
	"blink": "pkivHIHS2sIHPRLS7eXi",
	"blinkText": "orZtD0We8XeRxcw__m0a",
	"blinktext": "djXpHN7uzvJSrX7l_aTy"
};
export default ___CSS_LOADER_EXPORT___;
