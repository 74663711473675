import React from "react";

const WarehouseDetails = ({ warehouse = {} }) => {
  return (
    <div className="border rounded-3 p-3 bg-white">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="fs-val-lg fw-semibold">Warehouse Details</div>
        <div className="badge bg-primary-subtle text-primary fs-val-xs">
          ID: {warehouse._id}
        </div>
      </div>

      <div className="mb-3">
        <div className="fs-val-md fw-semibold text-primary mb-2">
          {warehouse.name}
        </div>
        <div className="text-muted fs-val-sm">
          <div className="mb-1">
            {warehouse.address?.door_no} {warehouse.address?.street}
          </div>
          <div>
            {warehouse.district} - {warehouse.pincode}
          </div>
        </div>
      </div>

      <div className="d-flex flex-wrap gap-3 fs-val-sm">
        {warehouse._contact?.mobile && (
          <div className="d-flex align-items-center">
            <i className="bi bi-telephone-fill text-primary"></i>
            <span className="px-2">{warehouse._contact.mobile}</span>
          </div>
        )}
        {warehouse._contact?.email && (
          <div className="d-flex align-items-center">
            <i className="bi bi-envelope text-primary"></i>
            <span className="px-2">{warehouse._contact.email}</span>
          </div>
        )}
        <div className="d-flex align-items-center">
          <i className="bi bi-building text-primary"></i>
          <span className="px-2">
            GST: {warehouse.finance_details?.gstNo || "N/A"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default WarehouseDetails;
