import {
  AuthService,
  CommonService,
  GrnService,
  NavService,
} from "@sk/services";
import {
  AppCard,
  AppliedFilterLabel,
  HighlightText,
  PageInfo,
  PaginationSummary,
} from "@sk/uis";
import { set } from "date-fns";
import { produce } from "immer";
import { debounce, merge } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import listView from "../constantService";
import GrnFilter from "./components/GrnFilter";
import Table from "./components/Table";
import { defaultFormData, filterLabelsObj } from "./grnListConstants";

const breadcrumbs = listView.breadcrumbs;

const paginationOpt = listView.pagination;

const defaultSummaryCard = listView.summaryCard;

const getCount = async (params) => {
  try {
    const r = await GrnService.getCount(params);
    return { count: r.resp || 0 };
  } catch (error) {
    return { count: 0 };
  }
};

const getData = async (params) => {
  const r = await GrnService.getList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const prepareFilterParams = (filter = {}, pagination = {}) => {
  const p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      productDetails: {
        $elemMatch: {},
      },
    },
  };

  const search = filter?.search?.trim();
  const searchRegex = { $regex: search, $options: "gi" };

  if (search) {
    p.filter.$or = [
      { _id: search },
      {
        "vendor.name": searchRegex,
      },
      {
        vendorId: search,
      },
      {
        productDetails: {
          $elemMatch: {
            poId: searchRegex,
          },
        },
      },
    ];
  }

  if (filter.wh) {
    p.filter.whId = { $in: [filter.wh] };
  }

  if (filter.status) {
    p.filter.status = filter?.status;
  }

  if (filter.dateRange && filter.dateRange.length === 2) {
    p.filter.createdAt = {
      $gte: set(filter.dateRange[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (filter.brand && filter.brand.length) {
    p.filter.productDetails = {
      $elemMatch: {
        ...p.filter.productDetails.$elemMatch,
        brandId: { $in: filter.brand.map((b) => b.value._id) },
      },
    };
  }
  if (filter.product && filter.product.length) {
    p.filter.productDetails = {
      $elemMatch: {
        ...p.filter.productDetails.$elemMatch,
        productId: { $in: filter.product.map((p) => p.value._id) },
      },
    };
  }

  if (filter.category && filter.category.length) {
    p.filter.productDetails = {
      $elemMatch: {
        ...p.filter.productDetails.$elemMatch,
        categoryId: { $in: filter.category.map((c) => c.value._id) },
      },
    };
  }
  if (filter.vendor && filter.vendor.length) {
    p.filter.vendorId = filter.vendor[0].value._id;
  }
  if (!Object.keys(p.filter.productDetails.$elemMatch).length) {
    delete p.filter.productDetails;
  }
  return p;
};

const GrnList = () => {
  const { register, getValues } = useForm();

  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [summaryCard, setSummaryCard] = useState([...defaultSummaryCard]);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [showAdvanceFilterModal, setShowAdvanceFilterModal] = useState(false);

  const [filterLabels, setFilterLabels] = useState([]);

  const paginationRef = useRef({ ...paginationOpt });

  const filterRef = useRef({
    ...defaultFormData,
  });

  const advFilterDataRef = useRef({
    state: AuthService.getDefaultWhState(),
    wh: AuthService.getDefaultWhId(),
  });

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(() => {
    applyFilter(true);
  }, [applyFilter]);

  const applyFilter = useCallback(
    (isSummaryRequired = false) => {
      paginationRef.current = { ...paginationOpt };

      if (isSummaryRequired) {
        loadSummary();
      }

      loadCount();
      loadList();
      prepareFilterLabels();
    },
    [loadCount, loadList, loadSummary, prepareFilterLabels]
  );

  const loadSummary = useCallback(async () => {
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );

    const filterParams = getFilterParams();
    delete filterParams.page;
    delete filterParams.count;

    let promises = [];
    summaryCard.forEach((e) => {
      promises.push(getCount(merge({}, filterParams, { filter: e.filter })));
    });
    const r = await Promise.all(promises);
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k].count || 0;
        });
      })
    );
  }, [getFilterParams, summaryCard]);

  const loadList = useCallback(async () => {
    setLoadingData(true);
    const d = await getData(getFilterParams());
    setData(d);
    setLoadingData(false);
  }, [getFilterParams]);

  const loadCount = useCallback(async () => {
    setLoadingTotalRecords(true);
    const p = { ...getFilterParams() };
    delete p.count;
    delete p.page;
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getFilterParams]);

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      filterRef.current,
      paginationRef.current,
      getValues()
    );
  }, [getValues]);

  const openAdvanceFilterModal = useCallback(() => {
    setShowAdvanceFilterModal(true);
  }, []);

  const prepareFilterLabels = useCallback(() => {
    const v = { ...filterRef.current };
    const l = CommonService.prepareAppliedFilterLabels(filterLabelsObj, v);
    setFilterLabels(l);
  }, []);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const onSearch = useCallback(
    debounce(() => {
      applyFilter();
    }, 700),
    [applyFilter]
  );

  const advanceFilterModalCb = useCallback(
    (r) => {
      setShowAdvanceFilterModal(false);
      if (r.status == "apply" || r.status == "reset") {
        advFilterDataRef.current = r.data;
        applyFilter();
      }
    },
    [applyFilter]
  );

  const NavToSelectPO = () => {
    NavService.to(navigate, "/purchase-order/grn/select-po");
  };

  const onFilterCb = ({ formData }) => {
    filterRef.current = {
      ...formData,
    };
    applyFilter();
  };

  const onRemoveFilterLabel = ({ action, data }) => {
    if (action == "remove") {
      const label = filterLabelsObj[data.key];
      filterRef.current = {
        ...filterRef.current,
        [data.key]: label.resetVal || "",
      };
      applyFilter();
    }
  };

  return (
    <>
      <div className="mt-2">
        <PageInfo
          title="GRN List"
          navigate={navigate}
          breadcrumbs={breadcrumbs}
        />
      </div>

      {/* Page title Info */}
      <div className="row align-self-center mb-2">
        {summaryCard.map((e, k) => (
          <div className="col-auto mb-2" key={k}>
            <HighlightText type={e.color} template={2}>
              {e.label}: {e.value?.toLocaleString()}
            </HighlightText>
          </div>
        ))}
      </div>

      <AppCard>
        <GrnFilter callback={onFilterCb} formData={filterRef.current} />
      </AppCard>

      <AppCard>
        {filterLabels.length > 0 ? (
          <div className="mb-3">
            <AppliedFilterLabel
              labels={filterLabels}
              callback={onRemoveFilterLabel}
            />
          </div>
        ) : null}

        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
          className="mb-3"
        />

        <Table
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
        />
      </AppCard>
    </>
  );
};

export default GrnList;
