// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tjSgJSamnUulJ7hJnbG6{position:relative;text-align:right}.s4k9jo2e46m9NJfaKSJu{margin-top:-14px;position:absolute;margin-left:-15px;border-radius:50%;padding:0px 2px !important}", "",{"version":3,"sources":["webpack://./src/pages/grn/manage/selectePo/selectPoStyles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,gBAAA,CAGF,sBACE,gBAAA,CACA,iBAAA,CACA,iBAAA,CACA,iBAAA,CACA,0BAAA","sourcesContent":[".selectedCard {\n  position: relative;\n  text-align: right;\n}\n\n.selectedIcon {\n  margin-top: -14px;\n  position: absolute;\n  margin-left: -15px;\n  border-radius: 50%;\n  padding: 0px 2px !important;\n}\n\n.poCard {\n  // width: 188px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectedCard": "tjSgJSamnUulJ7hJnbG6",
	"selectedIcon": "s4k9jo2e46m9NJfaKSJu"
};
export default ___CSS_LOADER_EXPORT___;
