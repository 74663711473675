import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchUrlQueryString, useAttachAdditionalData } from "@sk/hooks";
import {
  CommonService,
  FranchiseService,
  PoService,
  ProductService,
  VendorService,
} from "@sk/services";
import {
  Alert,
  Amount,
  AppCard,
  BusyLoader,
  DatePickerInput,
  PageInfo,
  PageLoader,
  RadioInput,
  SelectInput,
  TextareaInput,
  Toaster,
} from "@sk/uis";
import classNames from "classnames";
import { produce } from "immer";
import get from "lodash/get";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
// import ProductCard from "../components/ProductCard";
import { PoItemCard } from "@sk/features";

import styles from "../ManagePo.module.scss";
import SearchProduct from "../components/SearchProduct";
import BulkPidModal from "../modals/BulkPidModal";
import LoadByBrandModal from "../modals/LoadByBrandModal";
import ManageMrpModal from "../modals/ManageMrpModal";
import PoPreviewModal from "../modals/PoPreviewModal";
import VendorPriceModal from "../modals/VendorPriceModal";
import Overview from "./components/Overview";
import { each } from "lodash";
import { differenceInMinutes } from "date-fns";
import PoItem from "./components/PoItem";

const tabs = [
  { key: "all", tabName: "All" },
  { key: "reserved", tabName: "Reserve Order" },
  { key: "fast", tabName: "Fast Moving" },
  { key: "slow", tabName: "Slow Moving" },
  { key: "quarantine", tabName: "Quarantine" },
];

const browseOptions = [
  { label: "Search By Product", value: "product" },
  { label: "Search By Product ID's", value: "bulkProduct" },
];

const breadcrumbs = [
  { name: "Home", link: "/auth/init" },
  {
    name: "PO List",
    link: "/purchase-order/list",
  },
  { name: "Purchase Order" },
];

const validationScheme = yup.object({
  browse: yup.string().required(),
  pids: yup.string().when("browse", {
    is: "bulkProduct",
    then: (schema) => schema.required().label("Bulk Product IDs"),
  }),
});

let expectedDateConfig = {
  minDate: new Date(),
};
const attachAdditionalDataConfig = [
  {
    key: "categoryId",
    api: "category",
    loadingKey: "categoryLoading",
    dataKey: "category",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "brandId",
    api: "brand",
    loadingKey: "categoryLoading",
    dataKey: "brand",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const ManagePoProduct = () => {
  const {
    control,
    setValue,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationScheme),
    defaultValues: {
      browse: "product",
      remarks: "",
      deliveryDate: "",
    },
  });
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [loading, setLoading] = useState(true);

  const [vendor, setVendor] = useState({});

  const [warehouseDetails, setWarehouseDetail] = useState({});

  const [activeTab, setActiveTab] = useState({
    key: "all",
  });

  const [busyLoader, setBusyLoader] = useState({ loading: false, message: "" });

  const [products, setProducts] = useState([]);

  const [editPoDetails, setEditPoDetails] = useState({});

  const [showBulkModal, setShowBulkModal] = useState(false);

  const [showLoadByBrandModal, setShowLoadByBrandModal] = useState(false);

  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const [showVendorPriceModal, setShowVendorPriceModal] = useState(false);

  const [showMrpModal, setShowMrpModal] = useState(false);

  const vendorPriceModalRef = useRef({ data: [] });

  const mrpModalRef = useRef({ data: {}, index: -1 });

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const query = useFetchUrlQueryString(searchParams);

  const existingProductsIds = useMemo(() => {
    return products.map((x) => x._id);
  }, [products]);

  const summary = useMemo(() => {
    return {
      totalB2bPrice: products.reduce((acc, cur) => acc + cur.b2bPrice * 1, 0),
      totalPoValue: products.reduce((acc, cur) => acc + cur.totalValue * 1, 0),
      totalPoQuantity: products.reduce((acc, cur) => acc + cur.qty * 1, 0),
      totalCm1: products.reduce((acc, cur) => acc + cur.cm1, 0),
    };
  }, [products]);

  const deliveryDate = useWatch({
    control,
    name: "deliveryDate",
  });

  const remarks = useWatch({
    control,
    name: "remarks",
  });

  const browseType = useWatch({
    control,
    name: "browse",
  });

  useEffect(() => {
    init();
  }, [init]);

  // This is use Effect is of No use
  // useEffect(() => {
  //   let total = 0;
  //   let qty = 0;
  //   let cm1 = 0;
  //   let b2bPrice = 0;
  //   (products || []).forEach((e) => {
  //     total += e._total;
  //     qty += PoService.getQtyVal(e);
  //     cm1 += e._cm1;
  //   });
  // }, [products]);

  // To Open Load By Brand Modal
  const openLoadByBrandModal = () => setShowLoadByBrandModal(true);

  // To close Load By Brand Modal
  const closeLoadByBrandModal = () => setShowLoadByBrandModal(false);

  // To Open Po Preview Modal
  const openPreviewModal = () => setShowPreviewModal(true);

  // To Close Po Preview Modal
  const closePreviewModal = () => setShowPreviewModal(false);

  // To close Bulk Upload Product Ids
  const closeBulkPidModal = () => setShowBulkModal(false);

  const init = useCallback(async () => {
    const id = query.id;
    let pr;
    if (!id) {
      return;
    }

    setLoading(true);

    const vr = await VendorService.getVendor(query.id, {
      select:
        "name, address, contact, gst_no, state, district, margins,communication,credit_days,city",
    });
    const wr = await FranchiseService.getFranchise(query.wh, {
      select:
        "name, address, contact_details, finance_details, pincode, OwnerMobileNo, state, district",
    });

    if (query.poId) {
      pr = await PoService.getPoById(query.poId);
    }

    const v = vr?.resp || {};
    const w = wr?.resp || {};
    const p = pr?.resp || {};

    if (v._id) {
      v._contact = (v.contact || []).find((x) => x.isOwner) || {};
    }

    if (w._id) {
      w._contact = (w.contact_details || []).find((x) => x.isOwner) || {};
    }

    if (query.poId && p._id) {
      loadEditData(p);
    }

    setVendor(v);
    setWarehouseDetail(w);
    setLoading(false);
  }, [query.id, query.wh, query.poId, loadEditData]);

  const tabCb = (data) => {
    const newActiveTab = tabs.find((tab) => tab.key === data.value);
    setActiveTab(newActiveTab);
  };

  // const calulateMargin = (prd) => {
  //   let p = getPriceVal(prd);
  //   return ((p - calculateUnitPrice(prd)) / p) * 100;
  // };

  /*
  This Function will help to add product in PO List it will internally call all required API 
  and it will take 2 params product and callback , here callback will give the status weather the 
  data is fetch successfully or not
  */
  const handleProductAddFlow = useCallback(
    async (product, callback) => {
      // check product already exists
      const f = products.filter((x) => x._id === product._id);
      if (f.length > 0) {
        Toaster.error(product.name + " already exits");
        if (callback) {
          callback({ status: "error" });
        }
        return;
      }
      const m = VendorService.attachStatusToMargins(
        vendor.margins || []
      ).filter((x) => x.status == "Active");
      if (!m.length) {
        Toaster.error("Margin is not defined");
        if (callback) {
          callback({ status: "error" });
        }
        return;
      }

      const r = await PoService.prepareProductData(product, m, query.wh);

      if (r?.status == "error") {
        Toaster.error(r.msg);
        if (callback) {
          callback({ status: "error" });
        }
        return;
      }

      const resp = PoService.formatPoProductResp(r);

      setProducts(
        produce((draft) => {
          draft.unshift(resp);
        })
      );

      if (callback) {
        callback({ status: "success" });
      }
    },
    [products, query.wh, vendor.margins]
  );

  /*
  This function will run when we will select any product from Auto complete and pass
  the selected Product to handleProductAddFlow
  */

  const onProductSelect = useCallback(
    async (e) => {
      const prd = get(e, "value[0].value", {});
      if (!prd._id) {
        return;
      }
      setBusyLoader(
        produce((draft) => {
          draft.loading = true;
        })
      );
      handleProductAddFlow(prd, () => {
        setBusyLoader(
          produce((draft) => {
            draft.loading = false;
          })
        );
      });
    },
    [handleProductAddFlow]
  );

  // const removeProduct = (index) => {
  //   setProducts(
  //     produce((draft) => {
  //       draft.splice(index, 1);
  //     })
  //   );
  // };

  // To Handle Scheme margin change in PoItemCard
  const onSchemeMarginChange = useCallback((val, index) => {
    setProducts(
      produce((draft) => {
        const i = index;
        let v = val;
        if (draft[i].marginType === "percentage" && v > 100) {
          v = 100;
        }
        draft[i].additionalMargin = parseFloat(v) || 0;

        draft[i].purchasePrice = PoService.calculateUnitPrice({
          dealerPrice: draft[i].dealerPrice,
          dealerMarginPrice: draft[i].dealerMarginPrice,
          baseMargin: draft[i].baseMargin,
          purchasePrice: draft[i].purchasePrice,
          additionalMargin: parseFloat(v) || 0,
          mrp: draft[i].mrp,
          marginType: draft[i].marginType,
        });
        draft[i].finalMargin = PoService.calculateFinalMargin({
          dealerPrice: draft[i].dealerPrice,
          dealerMarginPrice: draft[i].dealerMarginPrice,
          purchasePrice: draft[i].purchasePrice,
          mrp: draft[i].mrp,
        });
        draft[i].cm1 = PoService.calculateCM1({
          b2bPrice: draft[i].b2bPrice,
          purchasePrice: draft[i].purchasePrice,
        });
        draft[i].totalValue = PoService.calculateTotal({
          qtyType: draft[i].qtyType,
          qty: draft[i].qty,
          caseQty: draft[i].caseQty,
          innerPackQty: draft[i].innerPackQty,
          purchasePrice: draft[i].purchasePrice,
        });
      })
    );
  }, []);

  // const onSchemeMarginTypeChange = useCallback((val, index) => {
  //   const i = index;
  //   setProducts(
  //     produce((draft) => {
  //       draft[i]._form.schemeMargin = "";
  //       draft[i].unitPrice = PoService.calculateUnitPrice(draft[i]);
  //       draft[i]._finalMargin = PoService.calculateFinalMargin(draft[i]);
  //       draft[i]._cm1 = PoService.calculateCM1(draft[i]);
  //     })
  //   );
  // }, []);

  // To Handle Product Quantity change in PoItemCard
  const onPurchaseQtyChange = useCallback((val, index) => {
    setProducts(
      produce((draft) => {
        let v = parseFloat(val) || 0;
        if (v <= 0) {
          v = 1;
        }

        draft[index].qty = v;
        draft[index].totalValue = PoService.calculateTotal({
          qtyType: draft[index].qtyType,
          qty: v,
          caseQty: draft[index].caseQty,
          innerPackQty: draft[index].innerPackQty,
          purchasePrice: draft[index].purchasePrice,
        });
      })
    );
  }, []);

  // To Handle Product  Quantity Type change in PoItemCard
  const onPurchaseQtyTypeChange = useCallback((val, index) => {
    setProducts(
      produce((draft) => {
        draft[index].qtyType = val;
        draft[index].totalValue = PoService.calculateTotal({
          qtyType: draft[index].qtyType,
          qty: draft[index].qty,
          caseQty: draft[index].caseQty,
          innerPackQty: draft[index].innerPackQty,
          purchasePrice: draft[index].purchasePrice,
        });
      })
    );
  }, []);
  // To handle Bulk Product using Product Id and Open BulkPidModal
  const onBrowseOptionChange = (chngfn) => (e) => {
    if (e == "bulkProduct") {
      setShowBulkModal(true);
      return;
    }
    chngfn(e);
  };
  // This will get all Pid and from BulkPidModal and add Product to PO Recursively
  const onBulkPidSubmit = async (data) => {
    // product ids has to be less than or equal to 20

    if (data.length > 20) {
      Toaster.error("Only 20 Product IDs are allowed");
      return;
    } else if (!data.length) {
      Toaster.error("Please enter Products Ids first");
      return;
    }

    // close the modal
    setShowBulkModal(false);

    // get pids - do a recursive api call and add to the products array
    const pids = data || [];
    let index = 0;

    const process = async (index) => {
      const p = pids[index];

      setBusyLoader({
        loading: true,
        message: `Please wait , loading product ${index + 1} / ${pids.length}`,
      });

      const r = await ProductService.getProduct(p.label);

      if (r.statusCode != 200) {
        Toaster.error(
          `Unable to fetch Product Data "${p.label}" Please Try Later`
        );
        setBusyLoader({
          loading: false,
          message: "",
        });

        return;
      }
      handleProductAddFlow(r.resp, (data) => {
        index++;
        if (data.status == "success" && index < pids.length) {
          process(index);
        } else {
          setBusyLoader({
            loading: false,
            message: "",
          });
        }
      });
    };

    if (pids.length) {
      process(index);
    }
  };

  // To remove Product From The List
  const onRemoveProduct = useCallback(
    async (index) => {
      const r = await Alert.confirm({
        title: "Remove Product",
        text: `Do you want to remove "${products[index].name}" ? `,
      });

      if (!r.isConfirmed) {
        return;
      }

      setProducts(
        produce((draft) => {
          draft.splice(index, 1);
        })
      );
    },
    [products]
  );
  // To Open Vendor Price Modal
  const openVendorPriceModal = useCallback(
    () => setShowVendorPriceModal(true),
    []
  );

  // To Open ManageMrpModal
  const openShowMrpModal = useCallback(() => {
    setShowMrpModal(true);
  }, []);

  // To Close MangeMrpModal
  const closeMrpModal = useCallback(() => {
    mrpModalRef.current.data = {};
    mrpModalRef.current.index = -1;
    setShowMrpModal(false);
  }, []);

  // To close Vendor Price Modal
  const closeVendorPriceModal = useCallback(() => {
    vendorPriceModalRef.current.data = [];
    setShowVendorPriceModal(false);
  }, []);

  // This will add Product which we are selecting from loadByBrandModal
  const selectedProductsCb = useCallback((r) => {
    setShowLoadByBrandModal(false);
    if (r.action == "submitted") {
      setProducts(
        produce((draft) => {
          r.data.forEach((x) => {
            draft.push(x);
          });
        })
      );
    }
  }, []);

  // To handle Expected Date
  const dateChangeCb = (chngFn, key) => {
    return (val) => {
      chngFn(val);
      setValue(key, val[0]);
    };
  };

  // This will Open PO Preview Modal after validation product
  const onPreviewSubmit = () => {
    const validation = validateProducts();

    if (!validation.valid) {
      Toaster.error(validation.errorMsg);
      return;
    }
    openPreviewModal();
  };

  // To Validate PO List Before opening PoPreviewModal
  const validateProducts = () => {
    let errorMsg = "";

    let remarks = getValues("remarks");
    let deliveryDate = getValues("deliveryDate");

    if (!remarks) {
      errorMsg = "Please enter remarks fist";
    } else if (!deliveryDate) {
      errorMsg = "Please enter delivery date";
    } else if (differenceInMinutes(new Date(deliveryDate), new Date()) <= 0) {
      errorMsg = "Delivery Date should be greater than today date";
    }

    products.forEach((p) => {
      if (!p.qty * 1) {
        errorMsg = `Please enter quantity for ${p.name} / ${p._id}`;
      }
    });

    return {
      errorMsg,
      valid: !errorMsg,
    };
  };

  // This will take vendor List and  Open VendorPriceModal to show price
  const vendorPriceCb = useCallback(
    (index) => {
      vendorPriceModalRef.current.data = products[index].vendorPriceList;
      openVendorPriceModal();
    },
    [openVendorPriceModal, products]
  );

  // callback to Manage Mrp modal
  const productMrpCb = useCallback(
    (index) => {
      mrpModalRef.current.data = products[index];
      mrpModalRef.current.index = index;
      openShowMrpModal();
    },
    [openShowMrpModal, products]
  );

  // callback to Manage Mrp modal and will update mrpList of PO Product
  const addMrpListCb = (data) => {
    setProducts(
      produce((draft) => {
        draft[data.index].mrpList = data.mrpList;
      })
    );
  };

  const loadEditData = useCallback(
    (d) => {
      setValue("deliveryDate", d.expectedAt);
      setValue("remarks", d.remarks);
      d._basic = {};
      const basicKeys = [
        "_id",
        "poValue",
        "status",
        "createdAt",
        "verifiedAt",
        "lastUpdated",
        "createdBy",
        "modifiedBy",
      ];
      each(basicKeys, (v) => {
        d._basic[v] = d[v];
      });

      if (d?.products?.length) {
        let tmp = [];
        setAdditionalData([...d.products], attachAdditionalDataConfig, (x) => {
          tmp.push(x);
          if (tmp.length == attachAdditionalDataConfig.length) {
            let products = [...attachAllData([...d.products], tmp)];
            setProducts(
              produce(() =>
                products.map((x) => PoService.formatEditPoProducts(x))
              )
            );
          }
        });
      }

      setEditPoDetails(d);
    },
    [attachAllData, setAdditionalData, setValue]
  );

  return (
    <>
      <PageInfo
        title="Create Purchase Order"
        breadcrumbs={breadcrumbs}
        navigate={navigate}
      />

      {loading ? (
        <PageLoader />
      ) : (
        <>
          <div className="mb-4">
            <Overview vendor={vendor} warehouse={warehouseDetails} />
          </div>

          {/* 
          <div className="mb-4">
            <ProductSelectBlock
              vendorId={vendor._id}
              onProductSelect={onProductSelect}
            />
          </div> */}

          {/* When PO Id is not There Add Mode */}

          {query.poId && products.length == 0 ? null : (
            <AppCard noShadow={true}>
              <div className="row">
                <div className="col-4">
                  <div className="row">
                    <div className="col-col-12 me-4">
                      <Controller
                        control={control}
                        name="browse"
                        render={({ field: { onChange, value } }) => (
                          <RadioInput
                            gap={1}
                            name="browse"
                            options={browseOptions}
                            isMandatory={true}
                            onChange={onBrowseOptionChange(onChange)}
                            value={value}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      {browseType == "product" ? (
                        <SearchProduct
                          vendorId={vendor._id}
                          callback={onProductSelect}
                        />
                      ) : null}
                      {browseType == "bulkProduct" ? (
                        <>
                          <TextareaInput
                            label="Enter comma separted Product IDs"
                            register={register}
                            name="pids"
                            error={errors?.pids?.message}
                            placeholder="Enter here..."
                            isMandatory={true}
                            note="Max allowed 20 Product IDs"
                            gap={1}
                          />
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* product type */}
                {/* {products.length > 0 ? (
                  <div className="col-auto text-end">
                    <div className="mb-2">Choose Product Type</div>
                    <SelectInput
                      register={register}
                      name="productType"
                      options={[]}
                      gap={1}
                    />
                  </div>
                ) : null} */}

                <div className="col text-end mt-4">
                  <button
                    className="btn btn-success fs-val-md"
                    onClick={openLoadByBrandModal}
                  >
                    Load by Brand
                  </button>
                </div>
              </div>
            </AppCard>
          )}

          {/* When PO Id is not There Add Mode */}
          {!query.poId && products.length == 0 && (
            <div className=" d-flex h-25 w-100 justify-content-center align-items-center bg-white rounded-3 font-18">
              <div className=" fw-semibold">Please Add Products</div>
            </div>
          )}

          {/* When PO Id is there edit mode */}
          {query.poId && products.length == 0 && (
            <div className=" d-flex h-25 w-100 justify-content-center align-items-center bg-white rounded-3 font-18">
              <div className=" fw-semibold">Invalid Po Id</div>
            </div>
          )}

          {products.map((x, index) => (
            <PoItem
              key={x._id}
              data={x}
              callback={({ action, data }) => {
                if (action === "qty") {
                  onPurchaseQtyChange(data.qty, index);
                } else if (action === "schemeMargin") {
                  onSchemeMarginChange(data.additionalMargin, index);
                }
              }}
              mode="edit"
            />
          ))}

          {products.length > 0 ? (
            <table
              className={classNames(
                "table  table-bordered table-centered bg-white",
                styles.summary
              )}
            >
              <tbody>
                <tr>
                  <td width="35%">
                    <div
                      className={classNames(styles["summary-lbl"], "fw-bold")}
                    >
                      Total Purchase Order Summary
                    </div>
                  </td>
                  <td width="20%">
                    <div className="mb-2">
                      <span
                        className={classNames(
                          styles["summary-lbl"],
                          "fw-bold me-2"
                        )}
                      >
                        Total B2B price:
                      </span>
                      <span
                        className={classNames(
                          styles["summary-lbl"],
                          "fw-bold text-primary"
                        )}
                      >
                        <Amount
                          value={summary.totalB2bPrice}
                          decimalPlace={2}
                        />
                      </span>
                    </div>
                    <div>
                      <span
                        className={classNames(
                          styles["summary-lbl"],
                          "fw-bold me-2"
                        )}
                      >
                        Total CM 1:
                      </span>
                      <span
                        className={classNames(
                          styles["summary-lbl"],
                          "fw-bold text-success"
                        )}
                      >
                        {CommonService.roundedByDecimalPlace(
                          summary.totalCm1,
                          2
                        )}{" "}
                        %
                      </span>
                    </div>
                  </td>
                  <td width="20%">
                    <div className="mb-2">
                      <span
                        className={classNames(
                          styles["summary-lbl"],
                          "fw-bold me-2"
                        )}
                      >
                        Total PO Qty:
                      </span>
                      <span
                        className={classNames(
                          styles["summary-lbl"],
                          "fw-bold text-dark"
                        )}
                      >
                        {summary.totalPoQuantity}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="mb-2">
                      <span
                        className={classNames(
                          styles["summary-lbl"],
                          "fw-bold me-2"
                        )}
                      >
                        Total PO Value:
                      </span>
                      <span
                        className={classNames(
                          styles["summary-lbl"],
                          "fw-bold text-warning"
                        )}
                      >
                        <Amount value={summary.totalPoValue} decimalPlace={2} />
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : null}

          {products.length > 0 && (
            <AppCard noShadow={true}>
              <div className="row">
                <div className="col-4">
                  <label className="fs-val-md mb-1 form-label text-dark">
                    Expected Delivery Date
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    control={control}
                    name="deliveryDate"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <DatePickerInput
                          placeholder="Choose Delivery Date"
                          value={[value]}
                          inpChange={dateChangeCb(onChange, "deliveryDate")}
                          config={expectedDateConfig}
                        />
                      );
                    }}
                  />
                </div>
                <div className="col-8">
                  <TextareaInput
                    name="remarks"
                    register={register}
                    isMandatory={true}
                    label="Enter Remarks"
                    rows={1}
                  />
                </div>
              </div>
              <div className="col-12 text-end align-self-end">
                <button className="btn btn-primary" onClick={onPreviewSubmit}>
                  Submit
                </button>
              </div>
            </AppCard>
          )}
        </>
      )}

      <BusyLoader show={busyLoader.loading} message={busyLoader.message} />

      <BulkPidModal
        show={showBulkModal}
        onSubmit={onBulkPidSubmit}
        products={products}
        onClose={closeBulkPidModal}
      />

      <LoadByBrandModal
        show={showLoadByBrandModal}
        closeModal={closeLoadByBrandModal}
        margins={vendor.margins}
        wh={warehouseDetails._id}
        callback={selectedProductsCb}
        existingProductsIds={existingProductsIds}
      />

      <ManageMrpModal
        show={showMrpModal}
        currentMrp={mrpModalRef.current.data.mrp}
        closeModal={closeMrpModal}
        mrpList={mrpModalRef.current.data.mrpList}
        mode="edit"
        pId={mrpModalRef.current.data._id}
        index={mrpModalRef.current.index}
        callback={addMrpListCb}
      />

      <PoPreviewModal
        show={showPreviewModal}
        closeModal={closePreviewModal}
        vendor={vendor}
        warehouseDetails={warehouseDetails}
        deliveryDate={deliveryDate}
        remarks={remarks}
        totalB2bPrice={summary.totalB2bPrice}
        products={products}
        totalCm1={summary.totalCm1}
        totalPoQuantity={summary.totalPoQuantity}
        totalPoValue={summary.totalPoValue}
        editPoDetails={editPoDetails}
      />

      <VendorPriceModal
        show={showVendorPriceModal}
        closeModal={closeVendorPriceModal}
        data={vendorPriceModalRef.current}
      />
    </>
  );
};

export default memo(ManagePoProduct);
