import { CommonService } from "@sk/services";
import {
  Amount,
  AppCard,
  BtnLink,
  DateFormatter,
  HighlightText,
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { useEffect, useState } from "react";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "Invoice No", width: "20%" },
  { label: "Invoice Amount", width: "20%" },
  { label: "Invoiced On", width: "20%" },
  { label: "Attachment", width: "10%" },
];

const GrnViewInvoice = ({ data }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [data]);

  const handleAttachmentClick = (attachment) => {
    CommonService.downloadAsset(attachment._id, true);
  };

  const totalInvoiceAmount = data.reduce(
    (sum, invoice) => sum + invoice.amount,
    0
  );

  return (
    <AppCard>
      <div className="row mb-3 g-4">
        <div className="col-auto">
          <HighlightText type="primary" template={2} isSoft={true}>
            Total Invoices
            <span className="px-2">:</span>
            <span>{data.length}</span>
          </HighlightText>
        </div>
        <div className="col-auto">
          <HighlightText type="danger" template={2} isSoft={true}>
            Total Invoice Amount
            <span className="px-2">:</span>
            <Amount value={totalInvoiceAmount} decimalPlace={2} />
          </HighlightText>
        </div>
      </div>

      <div className="mb-3 custom-scrollbar thin-scrollbar">
        <table className="table table-sm table-hover">
          <TableHeader data={headers} isSticky={true} noBg={true} />
          <tbody>
            {loading ? (
              <TableSkeletonLoader rows={10} cols={headers.length} />
            ) : null}

            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Invoices found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {!loading &&
              data.map((invoice, index) => (
                <tr key={invoice.id} className="fs-val-md">
                  <td>{index + 1}</td>
                  <td>{invoice.no}</td>
                  <td>
                    <DateFormatter date={invoice.date} />
                  </td>
                  <td>
                    <Amount value={invoice.amount} decimalPlace={2} />
                  </td>
                  <td>
                    {invoice.doc.map((attachment, idx) => (
                      <div key={idx}>
                        <BtnLink
                          className="fs-val-sm me-2"
                          onClick={() => handleAttachmentClick(attachment)}
                        >
                          <i className="bi bi-paperclip" />{" "}
                          {attachment.originalName}
                        </BtnLink>
                      </div>
                    ))}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </AppCard>
  );
};

export default GrnViewInvoice;
