import { memo, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { AppCard, EntitySearchInput } from "@sk/uis";

const AdvanceFilterModal = ({ show = false, callback }) => {
  const { handleSubmit, control, reset, getValues } = useForm({
    defaultValues: {
      brand: [],
      category: [],
      product: [],
    },
  });

  const onSubmit = (data) => {
    callback({ status: "apply", data });
  };

  const onHideCb = useCallback(() => {
    callback({ status: "close" });
  }, [callback]);

  const resetForm = () => {
    reset();
    callback({ status: "reset", data: getValues() });
  };

  return (
    <Modal show={show} onHide={onHideCb} centered>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">
          <div className="page-title">Advance Filter</div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="modal-bg">
        <AppCard>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Brand */}
            <div className="mb-3">
              <Controller
                control={control}
                name="brand"
                key="brand"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type="brand"
                    label="Search for Brand"
                    name="brand"
                    placeholder="Search by Id/Name"
                    value={value}
                    isMandatory={true}
                    callback={onChange}
                    uid="brand"
                    emptyLabel="No Brand Found"
                    isMultiple={true}
                  />
                )}
              />
            </div>

            {/* Category */}
            <div className="mb-3">
              <Controller
                control={control}
                name="category"
                key="category"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type="category"
                    label="Search for Category"
                    name="category"
                    placeholder="Search by Id/Name"
                    value={value}
                    isMandatory={true}
                    callback={onChange}
                    uid="category"
                    emptyLabel="No Category Found"
                    isMultiple={true}
                  />
                )}
              />
            </div>

            {/* Product */}
            <div className="mb-3">
              <Controller
                control={control}
                name="product"
                key="product"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type="product"
                    label="Search for Product"
                    name="product"
                    placeholder="Search by Id/Name"
                    value={value}
                    isMandatory={true}
                    callback={onChange}
                    uid="Product"
                    emptyLabel="No Product Found"
                    isMultiple={true}
                  />
                )}
              />
            </div>
          </form>
        </AppCard>
      </Modal.Body>

      <Modal.Footer>
        <button
          className="btn btn-warning me-3 px-4 fw-semibold"
          onClick={resetForm}
        >
          Reset
        </button>

        <button
          className="btn btn-primary px-4 fw-semibold"
          onClick={handleSubmit(onSubmit)}
        >
          Apply
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(AdvanceFilterModal);
