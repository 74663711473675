import { useAttachAdditionalData } from "@sk/hooks";
import {
  AuthService,
  CommonService,
  NavService,
  PoService,
} from "@sk/services";
import { AppCard, AppliedFilterLabel, PageInfo, Rbac } from "@sk/uis";
import { set } from "date-fns";
import { produce } from "immer";
import debounce from "lodash/debounce";
import merge from "lodash/merge";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import listView from "../utilityService";
import PoFilter from "./components/PoFilter";
import PoSummary from "./components/PoSummary";
import Table from "./components/Table";
import AdvanceFilterModal from "./modals/AdvanceFilterModal";

const rbac = {
  addPO: ["AddPO"],
};

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Purchase Order",
    link: "/purchase-order/list",
  },
];

const ListPo = () => {
  const { register, getValues } = useForm();

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [summaryCard, setSummaryCard] = useState(PoService.getPoStatuses());

  const [showAdvanceFilterModal, setShowAdvanceFilterModal] = useState(false);

  const [filterLabels, setFilterLabels] = useState([]);

  const paginationRef = useRef({
    totalRecords: 0,
    rowsPerPage: 50,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 50,
  });

  const advFilterDataRef = useRef({
    state: AuthService.getDefaultWhState(),
    wh: AuthService.getDefaultWhId(),
  });

  const filterRef = useRef({});

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  const applyFilter = useCallback(async () => {
    paginationRef.current.activePage = 1;
    paginationRef.current.startSlNo = 1;

    loadList();

    loadSummary();

    prepareFilterLabels();

    // for total records
    setLoadingTotalRecords(true);
    const c = await getCount(getFilterParams());
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getFilterParams, loadList, loadSummary, prepareFilterLabels]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const r = await getData(getFilterParams());
    const d = r.resp || [];

    let tmp = [];
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    setData(d);
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  const prepareFilterLabels = useCallback(() => {
    const v = { ...filterRef.current };
    if (v.hideUnlinkedCategories) {
      delete v.hideUnlinkedCategories;
    }
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  const getFilterParams = useCallback(() => {
    const f = { ...advFilterDataRef.current, ...filterRef.current };

    let p = {
      page: paginationRef.current.activePage,
      count: paginationRef.current.rowsPerPage,
      filter: {},
    };

    if ((f.search || "").trim()) {
      p.filter.$or = [
        {
          _id: f.search,
        },
        {
          "contact.vendorName": {
            $regex: f.search,
            $options: "gi",
          },
        },
        {
          "contact.vendorId": f.search,
        },
      ];
    }

    console.log(f);

    if (f.brand && f.brand.length) {
      p.filter.products = {
        $elemMatch: { brandId: { $in: f.brand.map((b) => b.value._id) } },
      };
    }

    if (f.category && f.category.length) {
      p.filter.products = {
        $elemMatch: { categoryId: { $in: f.category.map((c) => c.value._id) } },
      };
    }

    if (f.product && f.product.length) {
      p.filter.products = {
        $elemMatch: { productId: { $in: f.product.map((p) => p.value._id) } },
      };
    }

    if (f.user && f.user.length) {
      p.filter.createdBy = f.user[0].value._id;
    }

    if (f.createdAt && f.createdAt.length == 2) {
      p.filter.createdAt = {
        $gte: set(f?.createdAt[0], {
          hours: 0,
          minutes: 0,
          seconds: 0,
        }),
        $lte: set(f?.createdAt[1], {
          hours: 23,
          minutes: 59,
          seconds: 59,
        }),
      };
    }

    if (f.expectedAt?.length > 0) {
      p.filter.expectedAt = {
        $gte: set(f?.expectedAt[0], {
          hours: 0,
          minutes: 0,
          seconds: 0,
        }),
        $lte: set(f?.expectedAt[0], {
          hours: 23,
          minutes: 59,
          seconds: 59,
        }),
      };
    }

    if (f.lockedPo) {
      p.filter.isLocked = true;
    }

    if (f.type) {
      p.filter.poType = f.type;
    }

    if (f.status) {
      p.filter.status = f?.status;
    }
    if (f.wh) {
      p.filter.whId = f.wh;
    }

    if (!Object.keys(p.filter).length) {
      delete p.filter;
    }

    return p;
  }, [getValues]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const loadSummary = useCallback(async () => {
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );

    const filterParams = getFilterParams();
    delete filterParams.page;
    delete filterParams.count;

    let promises = [];
    summaryCard.forEach((e) => {
      promises.push(
        getCount(
          merge(
            {},
            filterParams,
            e.value ? { filter: { status: e.value } } : {}
          )
        )
      );
    });
    const r = await Promise.all(promises);
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k].count || 0;
        });
      })
    );
  }, [getFilterParams, summaryCard]);

  const getData = (params) => {
    return PoService.getList(params);
  };

  const getCount = async (params) => {
    try {
      const r = await PoService.getCount(params);
      return { count: r.resp || 0 };
    } catch (error) {
      return { count: 0 };
    }
  };

  const onSearch = useCallback(
    debounce(() => {
      applyFilter();
    }, 500),
    []
  );

  const createPo = () => {
    NavService.openInNewTab("/purchase-order/manage/main");
  };

  const createAutoPo = () => {};

  const openAdvanceFilterModal = () => {
    setShowAdvanceFilterModal(true);
  };

  const advanceFilterModalCb = useCallback(
    (r) => {
      setShowAdvanceFilterModal(false);
      if (r.status == "apply" || r.status == "reset") {
        advFilterDataRef.current = r.data;
        applyFilter();
      }
    },
    [applyFilter]
  );

  const onFilterChange = (e) => {
    filterRef.current = { ...filterRef.current, ...e.data };
    applyFilter();
  };

  const onRemoveFilterLabel = useCallback(
    ({ action, data }) => {
      if (action === "remove") {
        const label = listView.formLabels[data.key];

        // Reset the specific filter to its default value
        filterRef.current = {
          ...filterRef.current,
          [data.key]: label.resetVal || "",
        };

        // Reapply filter after removing the label
        applyFilter();
      }
    },
    [applyFilter]
  );

  return (
    <>
      <div className="my-3 row">
        <div className="col align-self-center">
          <PageInfo
            breadcrumbs={breadcrumbs}
            noMargin={true}
            title="Purchase Order List"
          />
        </div>
        {/* <div className="col-auto">
          <Rbac roles={rbac.addPO}>
            <button className="btn btn-primary" onClick={createPo}>
              <i className="bi bi-plus"></i> Create PO
            </button>
          </Rbac>
        </div> */}
      </div>

      <AppCard>
        <PoFilter callback={onFilterChange} />
      </AppCard>

      <div>
        <PoSummary summary={summaryCard} />
      </div>

      <AppCard>
        {filterLabels.length > 0 ? (
          <AppliedFilterLabel
            labels={filterLabels}
            className="mb-3"
            callback={onRemoveFilterLabel}
          />
        ) : null}
        <Table
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
        />
      </AppCard>

      <AdvanceFilterModal
        show={showAdvanceFilterModal}
        callback={advanceFilterModalCb}
        filterData={advFilterDataRef.current}
      />
    </>
  );
};

export default ListPo;
