import { useCallback } from "react";
import { Modal } from "react-bootstrap";

const ViewRemarkModal = ({ product, show, callback }) => {
  const onHideCb = useCallback(() => {
    callback({ action: "close" });
  }, [callback]);
  return (
    <Modal show={show} backdrop="static" onHide={onHideCb} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0 pb-0">Reject Remarks</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="fs-val-sm text-muted">Deal Name</div>
          <div className="fs-val-md fw-semibold mb-2">
            {product.name} - ({product.dealId})
          </div>
        </div>
        <div className="fs-val-sm text-muted">Remarks</div>
        <div className="fs-val-md fw-semibold mb-2">
          {product.remarks || "N/A"}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewRemarkModal;
