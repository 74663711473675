import { useAttachAdditionalData } from "@sk/hooks";
import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  Spinner,
  TableHeader,
} from "@sk/uis";
import { cloneDeep } from "lodash";
import { memo, useEffect, useState } from "react";

const header = [
  { label: "Sl. No", width: "5%" },
  { label: "Logged On" },
  { label: "Updated By" },
  { label: "Old Status" },
  { label: "New Status" },
  { label: "Remarks" },
];

const attachAdditionalDataConfig = [
  {
    key: "loggedBy",
    api: "user",
    loadingKey: "_userLoading",
    dataKey: "_loggedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const PoLockSummary = ({ logs = [] }) => {
  const [data, setData] = useState([]);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  useEffect(() => {
    setData(logs.map((x) => ({ ...x, _userLoading: true })));

    if (logs.length) {
      let tmp = [];
      setAdditionalData(cloneDeep(logs), attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          setData([...attachAllData(cloneDeep(logs), tmp)]);
        }
      });
    }
  }, [logs, attachAllData, setAdditionalData]);

  return (
    <div>
      <table className="table table-sm">
        <TableHeader data={header} noBg={true} />
        <tbody>
          {data.length == 0 && (
            <tr className="fs-val-md">
              <td colSpan={header.length}>
                <NoDataFound>No summary to display</NoDataFound>
              </td>
            </tr>
          )}

          {data.length > 0 &&
            data.map((x, i) => (
              <tr className="fs-val-md" key={i}>
                <td>{i + 1}</td>
                <td>
                  <DateFormatter date={x.loggedAt} />
                </td>
                <td>
                  {x._userLoading ? (
                    <Spinner type="dots" />
                  ) : (
                    x?._loggedBy?.name ?? "N/A"
                  )}
                </td>
                <td>
                  <HighlightText
                    status={x.oldStatus ? "Unlocked" : "Locked"}
                    isBadge={true}
                  />
                </td>
                <td>
                  <HighlightText
                    status={x.oldStatus ? "Unlocked" : "Locked"}
                    isBadge={true}
                  />
                </td>
                <td>{x.remarks}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default memo(PoLockSummary);
PoLockSummary;
