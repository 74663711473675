import { Toaster } from "@sk/uis";
import { memo, useCallback, useState } from "react";
import { Modal } from "react-bootstrap";

const remarksOptions = [
  { label: "Select Reason", value: "" },
  { label: "Reason 1", value: "reason1" },
  { label: "Others", value: "other" },
];

const ClosePoRemarksModal = ({ show, callback, detail = {}, type }) => {
  const [remarks, setRemarks] = useState("");
  // const [reason, setReason] = useState("");

  const onRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  const onSubmit = () => {
    const r = remarks.trim();

    let errorMsg = "";
    if (!r) {
      errorMsg = "Please provide remarks";
    }

    if (errorMsg) {
      Toaster.error(errorMsg);
      return;
    }

    callback({ action: "submit", remarks });
  };

  const onHideCb = useCallback(() => {
    callback({ action: "close" });
  }, [callback]);

  return (
    <Modal show={show} backdrop="static" onHide={onHideCb} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0 pb-0">
          {type == "close"
            ? "Remarks for Close PO"
            : "Enter reason for moving this PO to 'Payment Pending' status"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="col-12">
            <textarea
              type="text"
              value={remarks}
              onChange={onRemarksChange}
              className="form-control"
              maxLength={300}
              placeholder="Type remarks here"
              rows={2}
            ></textarea>
            <div className="text-muted fs-val-sm">Max 300 characters</div>
          </div>
          {/* <select
            className="form-select mb-2"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          >
            {remarksOptions.map((x) => (
              <option value={x.value} key={x.value}>
                {x.label}
              </option>
            ))}
          </select>
          {reason == "other" && (
            <>
              <textarea
                rows={5}
                onChange={onRemarksChange}
                maxLength={300}
                value={remarks}
                className="form-control"
                placeholder="Enter your remarks here..."
              ></textarea>
              <div className="text-muted fs-val-sm">Max 300 characters</div>
            </>
          )} */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={onSubmit}>
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(ClosePoRemarksModal);
