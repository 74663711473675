import { AuthService } from "@sk/services";
import { AppCard, EntitySearchInput } from "@sk/uis";
import { memo, useCallback, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

const defaultFormValues = {
  brand: [],
  category: [],
  product: [],
};

const AdvanceFilterModal = ({ show = false, callback, filterData }) => {
  const { handleSubmit, control, setValue, getValues, reset } = useForm({
    defaultValues: { ...defaultFormValues },
  });

  useEffect(() => {
    if (show) {
      reset();
      Object.keys(filterData || {}).forEach((x) => {
        setValue(x, filterData[x]);
      });
      onStateChange();
    }
  }, [filterData, getValues, onStateChange, reset, setValue, show]);

  const onSubmit = (data) => {
    let d = { ...data };
    delete d.warehouses;
    callback({ status: "apply", data: d });
  };

  const onHideCb = useCallback(() => {
    callback({ status: "close" });
  }, [callback]);

  const resetForm = () => {
    reset();
    onStateChange();
    let d = { ...getValues() };
    delete d.warehouses;
    callback({ status: "reset", data: d });
  };

  const onStateChange = useCallback(() => {
    const v = getValues();
    const s = v.state;
    const wh = AuthService.getWhOnState(s).map((x) => ({
      label: x.name,
      value: x._id,
    }));
    const dWh = AuthService.getDefaultWhIdOnState(s);
    setValue("warehouses", wh);
    if (dWh) {
      setValue("wh", dWh);
    } else {
      setValue("wh", wh.length > 0 ? wh[0].value : "");
    }
  }, [getValues, setValue]);

  return (
    <>
      <Modal show={show} onHide={onHideCb}>
        <Modal.Header closeButton className="bg-white">
          <Modal.Title className="mb-0 text-dark">Advance Filter</Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal-bg">
          <AppCard>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Brand */}
              <div className="mb-3">
                <Controller
                  control={control}
                  name="brand"
                  key="brand"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type="brand"
                      label="Search for Brand"
                      name="brand"
                      placeholder="Search by Id/Name"
                      value={value}
                      callback={onChange}
                      uid="brand"
                      emptyLabel="No Brand Found"
                    />
                  )}
                />
              </div>
              {/* Category */}
              <div className="mb-3">
                <Controller
                  control={control}
                  name="category"
                  key="category"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type="category"
                      label="Search for Category"
                      name="brand"
                      placeholder="Search by Id/Name"
                      value={value}
                      callback={onChange}
                      uid="category"
                      emptyLabel="No Category Found"
                    />
                  )}
                />
              </div>
              {/* Product */}
              <div className="mb-3">
                <Controller
                  control={control}
                  name="product"
                  key="product"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type="product"
                      label="Search for Product"
                      name="product"
                      placeholder="Search by Id/Name"
                      value={value}
                      callback={onChange}
                      uid="Product"
                      emptyLabel="No Product Found"
                    />
                  )}
                />
              </div>
            </form>
          </AppCard>
        </Modal.Body>

        <Modal.Footer>
          {/* Action Buttons */}
          <div className="col-auto ms-auto">
            <button
              className="btn btn-warning me-3 px-4 fw-semibold"
              onClick={resetForm}
            >
              Reset
            </button>

            <button
              className="btn btn-primary px-4 fw-semibold"
              onClick={handleSubmit(onSubmit)}
            >
              Apply
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(AdvanceFilterModal);
