import { useFetchUrlQueryString } from "@sk/hooks";

import {
  AjaxService,
  CommonService,
  GrnService,
  NavService,
  PoService,
  WarehouseService,
} from "@sk/services";

import { Alert, NoDataFound, PageInfo, PageLoader, Toaster } from "@sk/uis";

import { produce } from "immer";

import { cloneDeep, toNumber, uniq } from "lodash";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { addDays, addMonths, differenceInDays, set } from "date-fns";
import { useNavigate, useSearchParams } from "react-router-dom";
import GrnInvoiceItem from "./components/GrnInvoiceItem";
import Overview from "./components/Overview";
import GrnInwardWarningModal from "./modals/GrnInwardWarningModal";
import GrnVendorInvoiceModal from "./modals/GrnVendorInvoiceModal";

const getProductByBarcode = async (warehouse, barcode, params) => {
  const r = await WarehouseService.getProductByBarcode(
    warehouse,
    barcode,
    params
  );
  return Array.isArray(r.resp) ? r.resp : [];
};

const maxStockReceivedOnDateRange = new Date().toISOString().split("T")[0];

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "GRN List",
    link: "/purchase-order/grn/list",
  },
  {
    name: "Create GRN",
  },
];

const defaultForData = {
  invoicedQty: "",
  receivedQty: "",
  shortageQty: "",
  mrp: "",
  otherMrp: "",
  manufactureDate: "",
  isDamaged: false,
  damagedQty: "",
  damagedReason: "",
  inputDisabled: true,
  isScanned: false,
  productCaseQty: "",
  caseQtyProof: [],
  damageProof: [],
  mrpProof: [],
  month: "",
  days: "",
  offerCode: "",
  vendorHsn: "",
  vendorTax: "",
};

function GrnManage() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const query = useFetchUrlQueryString(searchParams);

  const [display, setDisplay] = useState("loading");

  const [showVendorInvoiceModal, setShowVendorInvoiceModal] = useState(false);

  const [barcode, setBarcode] = useState({ data: "", invalidBarcode: false });

  const [data, setData] = useState([]);

  const [products, setProducts] = useState([]);

  const [invoiceDetails, setInvoiceDetails] = useState({});

  const [errorMsg, setErrorMsg] = useState("Po ID is Required");

  const selectedPo = useMemo(() => data.map((x) => x._id), [data]);

  const [stockReceivedOn, setStockReceivedOn] = useState("");

  const [totalProductDetails, setTotalProductDetails] = useState({
    qty: 0,
    total: 0,
  });

  const areaDetailsRef = useRef([]);

  const scannedProducts = useMemo(
    () => products.filter((x) => x?._formData?.isScanned),
    [products]
  );

  const [grnWarning, setGrnWarning] = useState({ showModal: false, data: {} });

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(() => {
    if (query.id) {
      let ids = query.id.split(",");
      loadPoDetails(ids);
    } else {
      setDisplay("noDataFound");
    }
  }, [query, loadPoDetails]);

  const loadPoDetails = useCallback(
    async (ids) => {
      setDisplay("loading");

      const r = await PoService.getList({
        filter: {
          _id: { $in: ids },
        },
      });
      const d = Array.isArray(r.resp) ? r.resp : [];

      if (!d.length) {
        setDisplay("noDataFound");
        setErrorMsg("No PO Found");
        return;
      }

      const v = validatePO(d, ids);

      if (!v.valid) {
        setErrorMsg(v.msg);
        setDisplay("noDataFound");
        return;
      }
      attachProductData(d);
      setData(d);

      setShowVendorInvoiceModal(true);
    },
    [validatePO, attachProductData]
  );

  const validatePO = useCallback((list, ids) => {
    const warehouse = uniq(list.map((x) => x.whId));
    const vendor = uniq(list.map((x) => x.contact.vendorId));
    let msg = "";
    if (ids.length != list.length) {
      msg = "PO IDs Are not Valid";
    } else if (warehouse.length > 1) {
      msg = "PO IDs are not from same warehouse";
    } else if (vendor.length > 1) {
      msg = "PO IDs are not from same vendor";
    }

    return {
      msg,
      valid: !msg,
    };
  }, []);

  const attachProductData = useCallback((list) => {
    let prds = [];

    list.forEach((currentPo) => {
      // Filtering if product quantity is Full
      currentPo.products = currentPo.products.filter((e) => {
        return !e.quantity.isFull;
      });

      currentPo.products.forEach((currentProduct) => {
        let index = prds.findIndex(
          (p) =>
            p.productId == currentProduct.productId &&
            p.mrp == currentProduct.mrp &&
            p.barcode == currentProduct.barcode
        );

        // If Product is already there we will add Qty
        if (index !== -1) {
          prds[index].quantity.requested += currentProduct.quantity.requested;
          prds[index].poId.push(currentPo._id);
        } else {
          // other wise we will push
          prds.push({ ...currentProduct, poId: [currentPo._id] });
        }
      });
    });

    // let prds =
    //   list.map((x) => x.products.map((p) => ({ ...p, poId: x._id })) || []) ||
    //   [];

    if (!prds.length) {
      setErrorMsg("No Products Found For PO IDs");
      setDisplay("noDataFound");
      return;
    }

    prds = prds.map((x) => {
      let productsDetails = cloneDeep(x); // saving  product details
      x._formData = { ...defaultForData, product: productsDetails };
      return x;
    });
    setProducts(prds);
    setDisplay("details");
  }, []);

  const formDataCb = (payload) => {
    let val = payload.value;
    let key = payload.key;
    let index = payload.index;

    if (
      key == "invoicedQty" ||
      key == "receivedQty" ||
      key == "shortageQty" ||
      key == "damagedQty"
    ) {
      val = CommonService.roundedByDecimalPlace(val, 0);
      if (val < 0) {
        val = "";
      }
      // } else if (key == "mrp" || key == "otherMrp") {
      // val = CommonService.roundedByDecimalPlace(val, 3);

      // if (val < 0) {
      //   val = "";
      // }
    } else if (key == "isDamaged") {
      if (!val) {
        setProducts(
          produce((draft) => {
            draft[index]._formData["damagedQty"] = "";
            draft[index]._formData["damagedReason"] = "";
          })
        );
      }
    } else if (key == "month" && val) {
      setProducts(
        produce((draft) => {
          let mnfDate = new Date(draft[index]._formData["manufactureDate"]);

          let mnt = addMonths(mnfDate, val, "months");
          let d = addDays(mnt, draft[index]._formData["days"] || 0, "days");
          draft[index]._formData["expiryDate"] = d;

          // No of days from Manufacture date to expiry date
          let shelfLifeDay = differenceInDays(new Date(d), new Date(mnfDate));
          draft[index]._formData["shelfLifeDays"] = shelfLifeDay;

          // shelfLife Percentage
          let shelfLifePercentage =
            Math.ceil(d - new Date()) / Math.ceil(d - mnfDate);
          draft[index]._formData["shelfLifePercentage"] = (
            shelfLifePercentage * 100
          ).toFixed(2);
        })
      );
    } else if (key == "days" && val) {
      setProducts(
        produce((draft) => {
          let mnfDate = new Date(draft[index]._formData["manufactureDate"]);

          let months = addMonths(mnfDate, val, "months");
          let expiryDt = addDays(
            months,
            draft[index]._formData["days"] || 0,
            "days"
          );
          draft[index]._formData["expiryDate"] = expiryDt;

          // No of days from Manufacture date to expiry date
          let shelfLifeDay = differenceInDays(
            new Date(expiryDt),
            new Date(mnfDate)
          );
          draft[index]._formData["shelfLifeDays"] = shelfLifeDay;

          // shelfLife Percentage
          let shelfLifePercentage =
            Math.ceil(expiryDt - new Date()) / Math.ceil(expiryDt - mnfDate);
          draft[index]._formData["shelfLifePercentage"] = (
            shelfLifePercentage * 100
          ).toFixed(2);
        })
      );
    }
    if (["damageProof", "caseQtyProof", "mrpProof"].indexOf(key) != -1) {
      if (payload.extraKey && payload.extraKey.action == "removeImg") {
        setProducts(
          produce((draft) => {
            let d = [...draft[index]._formData[key]];
            d.splice(val, 1);
            draft[index]._formData[key] = d;
          })
        );
      } else {
        let f = [
          {
            assetId: val.asset,
            docPath: val.resp.path || "",
            docType: val.resp.type || "",
            originalName: val.resp.originalName || "",
            asset: val.asset,
          },
        ];
        setProducts(
          produce((draft) => {
            draft[index]._formData[key] = draft[index]._formData[key]?.length
              ? [...draft[index]._formData[key], ...f]
              : f;
          })
        );
      }
    } else {
      setProducts(
        produce((draft) => {
          draft[index]._formData[key] = val;
        })
      );
    }

    // total product details
    // let qty = 0;
    // let price = 0;
    // setProducts(
    //   produce((e) => {
    //     // price
    //     price =
    //       +e._formData?.receivedQty *
    //       (e._formData?.mrp == "Other"
    //         ? e._formData?.otherMrp * 1
    //         : e._formData?.mrp * 1);
    //     // qty
    //     qty = +e._formData?.receivedQt || 0;
    //   })
    // );
    // setTotalProductDetails({ qty: qty, total: price });
  };

  const grnVendorInvoiceModalCb = useCallback((payload) => {
    if (payload.action == "submitted") {
      setInvoiceDetails(payload.formData);
    }
    setShowVendorInvoiceModal(false);
  }, []);

  const openVendorInvoiceModal = () => {
    setShowVendorInvoiceModal(true);
  };

  const doBarcodeScan = useCallback(async () => {
    let b = barcode.data;
    let scannedBarcodes = products.map((x) => x._formData.barcode);

    if (scannedBarcodes.indexOf(barcode.data) != -1) {
      Toaster.warn("Duplicate barcode");
      return;
    }

    const r = await getProductByBarcode(data?.[0]?.delivery?.whId, b, {
      filter: { isSkWarehouse: true },
    });

    if (!r.length) {
      setBarcode({ data: barcode.data, invalidBarcode: true });
      Toaster.warn("No such barcode found");
      return;
    }

    const tmp = [];
    r.forEach((acc, x) => {
      products.forEach((g, k) => {
        if (acc.productId === g.productId) {
          tmp.push(k);
        }
      });
    });

    if (!tmp.length) {
      setBarcode({ data: barcode.data, invalidBarcode: false });
      Toaster.warn("No Product for entered barcode");
      return;
    }

    if (tmp.length > 1) {
      let text = "There are " + tmp.length + " Occurrence  of the barcode";
      const r = await Alert.confirm({
        title: "Please confirm",
        text: text,
        okText: "Yes",
        cancelText: "No",
      });
      if (!r.isConfirmed) {
        return;
      }
    }

    setProducts(
      produce((draft) => {
        tmp.forEach((index) => {
          if (draft[index]) {
            draft[index]._formData.inputDisabled = false;
            draft[index]._formData.isScanned = true;
            draft[index]._formData.barcode = b;
          }
        });
      })
    );
  }, [barcode, data, products]);

  const onBarcodeChange = (e) => {
    const { value } = e.target;
    setBarcode({ data: value, invalidBarcode: false });
  };

  const handleKeyPress = useCallback(
    (ev) => {
      if (ev && ev.key === "Enter" && barcode.data) {
        doBarcodeScan();
      } else if (ev && ev.key === "Enter" && !barcode.data) {
        Toaster.error("Please Scan Barcode");
      }
    },
    [barcode.data, doBarcodeScan]
  );

  const onSubmit = () => {
    const validation = validatePoProducts();

    if (!validation.isValid) {
      Toaster.error(validation.msg);
      return;
    }

    validatePoWarning();
  };

  const validatePoProducts = () => {
    const scannedProducts = products.filter((x) => x._formData.isScanned);

    let msg = "";

    if (!scannedProducts.length) {
      msg = "Please scan at least one product before proceed";
    }

    let isInvoiceDateMismatch = (invoiceDetails?.invoices || []).filter((e) => {
      let d = differenceInDays(
        set(new Date(stockReceivedOn), { hours: 0, minutes: 0 }),
        set(new Date(e.invoiceDate), { hours: 0, minutes: 0 })
      );
      return d < 0;
    });

    for (let prod of scannedProducts) {
      let formData = prod._formData;

      const isTotalNonDamage =
        formData.receivedQty * 1 + formData.shortageQty * 1 !=
        formData.invoicedQty;

      const isTotalDamage =
        formData.receivedQty * 1 +
          formData.shortageQty * 1 +
          formData.damagedQty * 1 !=
        formData.invoicedQty;

      // Case Qty Details
      if (formData?.isCaseQtyChanged && !formData.productCaseQty) {
        msg = `Product Case Qty is required for product : ${prod.name} `;
      } else if (formData?.isCaseQtyChanged && !formData.caseQtyProof.length) {
        msg = `Upload Product Case Qty changed  Images for product : ${prod.name} `;
      } else if (formData.hasOffer && !formData.offerCode) {
        msg = `Please Enter offer Code for product ${prod.name}`;

        // Quantity Details
      } else if (!formData.invoicedQty) {
        msg = `Invoice quantity is required for product : ${prod.name} `;
      } else if (formData.receivedQty === "") {
        msg = `Received quantity is required for product : ${prod.name} `;
      } else if (formData.receivedQty > formData.invoicedQty) {
        msg = `Received quantity is more than Invoice quantity for product : ${prod.name} `;
      } else if (formData.shortageQty > formData.invoicedQty) {
        msg = `Shortage quantity is more than Invoice quantity for product  : ${prod.name} `;
      } else if (formData.isDamaged && !formData.damagedReason) {
        msg = `Please Select Damage Reason for product : ${prod.name} `;
      } else if (formData.isDamaged && !formData.damagedQty) {
        msg = `Enter Damage Quantity for product : ${prod.name} `;
      } else if (!formData.isDamaged && isTotalNonDamage) {
        msg = `Shortage and Received quantity should match Invoice Quantity   : ${prod.name} `;
      } else if (formData.isDamaged && isTotalDamage) {
        msg = `Shortage, Received , Damaged and quantity should match Invoice Quantity   : ${prod.name} `;
      } else if (formData.isDamaged && !formData.damageProof.length) {
        msg = `Upload Images for Damages for  product : ${prod.name} `;

        // Pricing Details
      } else if (!formData.mrp) {
        msg = `MRP  is required for product : ${prod.name} `;
      } else if (
        formData.mrp &&
        formData.mrp == "Other" &&
        !formData.otherMrp
      ) {
        msg = `Other mrp quantity is required for product : ${prod.name} `;
      } else if (
        formData.mrp &&
        formData.mrp == "Other" &&
        !formData.mrpProof.length
      ) {
        msg = `Upload MRP Changes Image  for product : ${prod.name} `;
      } else if (!formData.vendorHsn) {
        msg = `Please Enter Vendor HSN for product: ${prod.name}`;
      } else if (
        formData.vendorHsn &&
        (formData.vendorHsn.length < 4 || formData.vendorHsn.length > 8)
      ) {
        msg = `Please Enter Valid Vendor HSN for product: ${prod.name}`;
      } else if (!formData.vendorTax) {
        msg = `Please Enter Vendor Tax for product: ${prod.name}`;

        // Shelf Life
      } else if (!formData.manufactureDate) {
        msg = `Manufacture Date is required for product : ${prod.name} `;
      } else if (!formData.month && !formData.days) {
        msg = `Expire Month & Days is required for product : ${prod.name} `;

        // Vendor Details
      } else if (
        !invoiceDetails.invoices?.length ||
        !invoiceDetails?.vehicleDetails?.lrNumber
      ) {
        msg = `Please Add Vendor Invoice Details`;

        // Checking Stock received date and Invoice date
      } else if (isInvoiceDateMismatch.length) {
        msg = `Stock Received date is less than Invoice Date Please check`;
      } else if (!stockReceivedOn.length) {
        msg = "Please Choose Stock Received On Date";
      }
    }

    return {
      msg,
      isValid: !msg,
    };
  };

  const validatePoWarning = async () => {
    let p = {
      poId: selectedPo[0],
      products: products.map((e) => {
        return { id: e.productId, inwardingQuantity: e._formData.receivedQty };
      }),
    };
    const r = await GrnService.grnInwardWarning(p);

    if (
      r.resp &&
      (r.resp.firstWarning.isWarning ||
        r.resp.secondWarning.isWarning ||
        r.resp.thirdWarning.isWarning)
    ) {
      setGrnWarning({ showModal: true, data: r.resp });
    } else {
      createGrn();
    }
    // error need to add
  };

  const createGrn = async () => {
    let p = preparePayload();
    const r = await GrnService.create(p);
    if (r.statusCode != 200) {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(
        error?.msg?.length
          ? error.msg
          : "Failed to Create GRN, Please Try again"
      );
      return;
    } else {
      Toaster.success("Grn Created Successfully");
      NavService.to(navigate, "/purchase-order/grn/list");
    }
  };

  const preparePayload = () => {
    let barcodeArray = products.map((e) => {
      return {
        barcode: e._formData.barcode,
        poId: e.poId.length ? e.poId[0] : "",
        productId: e.productId,
      };
    });

    let productDetails = products.map((e) => {
      let fd = {
        area: invoiceDetails.invoices?.length
          ? invoiceDetails.invoices[0].dock
          : "",
        barcode: e._formData.barcode,
        barcodeScanned: true,
        bin: invoiceDetails?.invoices[0]?.bin || "",
        caseQtyChangeProofOfDocument: e._formData.caseQtyProof.map((e) => {
          return e;
        }),
        damagedQty: e._formData?.damagedQty + "",
        expiry: {
          manufactureDate: e._formData?.manufactureDate,
          noOfDays: e._formData?.days,
          noOfMonths: e._formData?.month,
          shelfLife: e._formData?.expiryDate,
        },
        hasBeenSplit: e?.hasBeenSplit ? true : false,
        invoiceNo: invoiceDetails?.invoices[0]?.invoiceNo || 0,
        invoiceQuantity: e?._formData?.invoicedQty || 0,
        isCaseQtyChanged: e._formData?.isCaseQtyChanged,
        location: invoiceDetails.invoices[0].dockName,
        manufactureDate: e._formData.manufactureDate,
        marginDealerPrice: false,
        newMrpValue: e._formData.mrp != e.mrp ? e._formData.mrp : e.mrp,
        newMrpValueChange: e._formData.mrp != e.mrp,
        otherMrp: e._formData.mrp == "Other",
        otherMrpImage: (e._formData.mrpProof || []).map((e) => {
          return e.assetId;
        }),
        otherNewMrp: e._formData?.otherMrp || "",
        productCaseQty: toNumber(e._formData.productCaseQty),
        proofOfDocument: (e._formData.damageProof || []).map((e) => {
          return e;
        }),
        rack: invoiceDetails?.invoices[0]?.rack || "",
        receivedQuantity: e._formData.receivedQty,

        shelfLife: e._formData?.expiryDate,
        shelfLifeDays: e._formData?.shelfLifeDays,
        shelfLifePercentage: e._formData.shelfLifePercentage / 100,
        shortageQty: e._formData?.shortageQty + "",
        total: e._formData.receivedQty * e._formData.otherMrp,
        poId: e.poId.length ? e.poId[0] : "",
        price: {
          mrp: e.mrp,
          margin: e.margins,
          dealerPrice: e.dealerPrice,
          purchasePrice: e.purchasePrice || 0,
        },
      };
      if (e?._formData?.damagedReason) {
        fd.damagedDetails = {
          reason: e._formData.damagedReason,
        };
      }
      let m = { ...e };
      delete m._formData;
      delete m.status;
      delete m.poId;
      return { ...m, ...fd };
    });
    let dock = invoiceDetails?.invoices?.length
      ? invoiceDetails.invoices[0].dock
      : "";
    let racksData = areaDetailsRef.current.filter((e) => {
      return e._id == dock || "";
    });
    let racks = racksData.length ? racksData[0].racks : [];

    let vendor = data.length ? data[0] : {};

    let p = {
      areas: areaDetailsRef.current,
      barcodeArray: barcodeArray,
      chipsPos: selectedPo,
      currentBarcodeArray: barcodeArray,
      invoiceDetails: (invoiceDetails?.invoices || []).map((e) => {
        return {
          amount: e.amount,
          brandCategories: [
            {
              brandId: e.brandId,
              categoryId: e.categoryId,
              amount: e.amount,
            },
          ],
          date: e.invoiceDate,
          doc: [
            {
              _id: e.asset.asset,
              docType: e.asset?.resp?.type,
              docPath: e.asset?.resp?.path,
              originalName: e.asset?.resp?.originalName,
            },
          ],
          dock: { areaId: e.dock, bin: e.bin, rack: e.rack },
          no: e.invoiceNo,
        };
      }),
      invoiceTotal: (invoiceDetails.invoices || []).reduce((sum, e) => {
        sum = +e.amount;
      }, 0),
      lrNumber: invoiceDetails.lrNumber,
      opened: false,
      poTotal: (products || []).reduce(
        (sum, e) =>
          (sum =
            +e._formData.receivedQty *
            (e._formData.mrp == "Other"
              ? e._formData.otherMrp
              : e._formData.mrp)),
        0
      ),
      poTotalReceived: products.reduce(
        (sum, e) => (sum = +e._formData.receivedQty),
        0
      ),
      proceedStatus: true,
      prodList: productDetails,
      productDetails: productDetails,
      racks: racks,
      remarks: invoiceDetails?.vehicleDetails?.remarks || "",
      scannedStatus: true,
      selectedPos: selectedPo,
      selectedVendor: {
        _id: data?.[0]?.contact?.vendorId,
        name: data?.[0]?.contact?.vendorName,
      },
      // splitProdList:[]
      stockReceivedAt: stockReceivedOn,
      // to:"WMF0"
      vehicleNumber: invoiceDetails?.vehicleDetails?.vhNo || "",
      vendorId: vendor?.contact?.vendorId || "",
      vendorName: vendor?.contact?.vendorName || "",
      vendorWhId: vendor?.delivery?.whId || "",
      whId: vendor?.delivery?.whId || "",
      shippingCost: invoiceDetails?.vehicleDetails?.shippingCost || "",
      // hsnGrnDetailsfromVendor: {
      //   HSNNumber: "21312",
      //   tax: 0,
      // },
      warnings: grnWarning.data,
    };
    return p;
  };

  const areaDetailsCb = useCallback((d) => {
    areaDetailsRef.current = d;
  }, []);

  const splitCb = useCallback(
    (d) => {
      const selectedData = products[d.index];
      let details = {
        ...selectedData,
        _formData: {
          ...defaultForData,
          product: selectedData,
          isScanned: false,
          inputDisabled: true,
        },
      };
      const updatedCards = [...products];
      updatedCards.splice(d.index + 1, 0, {
        ...details,
        hasBeenSplit: true,
      });
      setProducts(updatedCards);
    },
    [products]
  );

  const onCancelSplitCb = useCallback(
    (d) => {
      let pro = [...products];
      pro.splice(d.index, 1);
      setProducts(pro);
    },
    [products]
  );

  const onCancelScanCb = useCallback((d) => {
    setProducts(
      produce((draft) => {
        const sData = draft[d.index];
        delete sData._formData;
        if (draft[d.index]) {
          draft[d.index] = {
            ...sData,
            _formData: {
              ...defaultForData,
              product: sData,
              isScanned: false,
              inputDisabled: true,
            },
          };
        }
      })
    );
  }, []);

  const onStockReceivedOnchange = (e) => {
    setStockReceivedOn(e.target.value);
  };

  const onAssignBarcodeCb = useCallback(
    async (d) => {
      const r = await getProductByBarcode(
        data?.[0]?.delivery?.whId,
        barcode.data,
        {
          filter: { isSkWarehouse: true },
        }
      );

      if (r.length) {
        Toaster.warn("Duplicate Barcode");
        return;
      } else {
        setProducts(
          produce((draft) => {
            if (draft[d.index]) {
              draft[d.index]._formData.isScanned = true;
              draft[d.index]._formData.barcode = barcode.data;
              draft[d.index]._formData.inputDisabled = false;
            }
          })
        );
        setBarcode({ data: "", invalidBarcode: false });
      }
    },
    [barcode, data]
  );

  const grnWarningModalCb = (e) => {
    setGrnWarning({ show: false, data: {} });
    if (e && e.status == "success") {
      createGrn();
    }
  };

  return (
    <>
      <div className="mt-2">
        <PageInfo
          title="Create GRN"
          navigate={navigate}
          breadcrumbs={breadcrumbs}
        />
      </div>

      {display == "loading" && <PageLoader />}

      {display == "noDataFound" && <NoDataFound> {errorMsg}</NoDataFound>}

      {display == "details" && (
        <>
          <div className="my-4">
            <Overview
              vendor={data?.[0]?.contact}
              warehouse={data?.[0]?.delivery}
            />
          </div>

          {/* PO Details and Product Ids */}
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-2 border-end">
                  <div className="fs-val-lg fw-semibold">
                    Total Products ({products.length})
                  </div>
                </div>
                <div className="col ps-4">
                  <span className="fs-val-md me-1 fw-semibold">
                    Selected PO :
                  </span>
                  <span className="fs-val-md ">{selectedPo.join(" , ")}</span>
                </div>
                <div className="col-auto ms-auto">
                  <div className="fs-val-sm">
                    Stock Received On <span className="text-danger">*</span>
                  </div>
                  <input
                    disabled={data.inputDisabled}
                    className="form-control form-control-sm"
                    type="date"
                    value={data.manufactureDate}
                    onChange={onStockReceivedOnchange}
                    max={maxStockReceivedOnDateRange}
                  />
                </div>
                <div className="col-2 ps-4 text-end">
                  <button
                    className="btn btn-primary"
                    onClick={openVendorInvoiceModal}
                  >
                    Add Vendor Invoice
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="py-2">
            {products.map((x, i) => (
              <div className="mb-2" key={i}>
                <GrnInvoiceItem
                  data={x._formData}
                  index={i}
                  callback={formDataCb}
                  productDetails={x}
                  splitCb={splitCb}
                  onCancelSplitCb={onCancelSplitCb}
                  onCancelScanCb={onCancelScanCb}
                  barcode={barcode}
                  onAssignBarcodeCb={onAssignBarcodeCb}
                />
              </div>
            ))}
            <div className="position-sticky bottom-0 shadow rounded p-2 bg-white">
              <div className="row align-items-center">
                <div className="col-4">
                  <div className="input-group ps-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Scan barcode here"
                      value={barcode.data}
                      onChange={onBarcodeChange}
                      onKeyDown={handleKeyPress}
                    />
                    <span className="input-group-text">
                      <i className="bi bi-upc-scan"></i>
                    </span>
                  </div>
                </div>
                <div className="col-2">
                  <span> Scanned : </span>
                  <span>
                    {scannedProducts.length} /{products.length}
                  </span>
                </div>

                <div className="col-6 text-end">
                  <button
                    className="fs-val-md btn btn-primary me-3"
                    onClick={onSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <GrnVendorInvoiceModal
        show={showVendorInvoiceModal}
        callback={grnVendorInvoiceModalCb}
        warehouse={data?.[0]?.delivery?.whId}
        invoiceDetails={invoiceDetails}
        areaDetailsCb={areaDetailsCb}
      />

      <GrnInwardWarningModal
        show={grnWarning.showModal}
        handleClose={grnWarningModalCb}
        warningData={grnWarning.data}
      />
    </>
  );
}

export default GrnManage;
