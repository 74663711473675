import React from "react";
import VendorDetails from "./VendorDetails";
import WarehouseDetails from "./WarehouseDetails";

const Overview = ({ vendor, warehouse }) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-6 border-end">
            <VendorDetails vendor={vendor} />
          </div>
          <div className="col-6 ps-4">
            <WarehouseDetails warehouse={warehouse} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
