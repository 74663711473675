import {
  DatePickerInput,
  EntitySearchInput,
  SelectInput,
  TextInput,
} from "@sk/uis";
import { memo, useCallback, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

const canvasStyle = { width: "30%" };

const createdOnDateConfig = { mode: "range", maxDate: new Date() };

const statusOptions = [
  { label: "All", value: "" },
  { label: "Draft", value: "Draft" },
  { label: "Approval Pending", value: "Approval Pending" },
  { label: "Approved", value: "Approved" },
  { label: "Partially Received", value: "Partially Received" },
  // { label: "Received", value: "Received" },
  { label: "Payment Pending", value: "Received - Payment Pending" },
  { label: "Closed", value: "Closed" },
  { label: "Rejected", value: "Rejected" },
];

const billToStoreOptions = [
  { label: "All", value: "" },
  { label: "Bill to Store", value: "Store" },
  { label: "Bill to StoreKing", value: "StoreKing" },
];

const AdvanceFilterModal = ({ show = false, callback, filterData }) => {
  const { handleSubmit, control, register, setValue, getValues, reset } =
    useForm({
      defaultValues: {
        createdAt: [],
        status: "",
        deal: [],
        category: [],
        brand: [],
        isBillToStore: "",
        paymentNo: "",
        invoiceNo: "",
      },
    });

  useEffect(() => {
    if (show) {
      reset();
      Object.keys(filterData || {}).forEach((x) => {
        setValue(x, filterData[x]);
      });
    }
  }, [filterData, getValues, reset, setValue, show]);

  const onSubmit = (data) => {
    let d = { ...data };
    callback({ status: "submitted", data: d });
  };

  const onHideCb = useCallback(() => {
    callback({ status: "close" });
  }, [callback]);

  const resetForm = () => {
    reset();

    let d = { ...getValues() };
    callback({ status: "reset", data: d });
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={onHideCb}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-white">
          <Offcanvas.Title>
            <div className="page-title"> Advance Filter </div>
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="p-4  border-top">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-6">
                <div className="mb-3">
                  <SelectInput
                    register={register}
                    name="status"
                    label="Status"
                    options={statusOptions}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <SelectInput
                    register={register}
                    name="isBillToStore"
                    label="Bill To"
                    options={billToStoreOptions}
                  />
                </div>
              </div>
            </div>

            <TextInput
              type="text"
              label="Invoice Ref No."
              placeholder="Enter Ref number here"
              register={register}
              name="invoiceNo"
            />

            <TextInput
              type="text"
              label="Payment Ref No."
              placeholder="Enter Ref number here"
              register={register}
              name="paymentNo"
            />

            {/* Deal */}
            <div className="mb-3">
              <Controller
                control={control}
                name="deal"
                key="deal"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type="deal"
                    label="Search for Deal"
                    name="product"
                    placeholder="Search by Id/Name"
                    value={value}
                    callback={onChange}
                    uid="Deal"
                    emptyLabel="No Deal Found"
                  />
                )}
              />
            </div>

            {/* Category */}
            <div className="mb-3">
              <Controller
                control={control}
                name="category"
                key="category"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type="category"
                    label="Search for Category"
                    name="product"
                    placeholder="Search by Id/Name"
                    value={value}
                    callback={onChange}
                    uid="Deal"
                    emptyLabel="No Category Found"
                  />
                )}
              />
            </div>

            {/* Brand */}
            <div className="mb-3">
              <Controller
                control={control}
                name="brand"
                key="brand"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type="brand"
                    label="Search for Brand"
                    name="product"
                    placeholder="Search by Id/Name"
                    value={value}
                    callback={onChange}
                    uid="Deal"
                    emptyLabel="No brand Found"
                  />
                )}
              />
            </div>

            {/* date range */}
            <div className="mb-3">
              <div className="fs-val-md mb-2">Created On</div>
              <Controller
                name="createdAt"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <DatePickerInput
                    placeholder="Choose"
                    value={value}
                    inpChange={onChange}
                    config={createdOnDateConfig}
                  />
                )}
              />
            </div>
          </form>
        </Offcanvas.Body>

        <Offcanvas.Header>
          {/* Action Buttons */}
          <div className="col-auto ms-auto">
            <button
              className="btn btn-warning me-3 px-4 fw-semibold"
              onClick={resetForm}
            >
              Reset
            </button>

            <button
              className="btn btn-primary px-4 fw-semibold"
              onClick={handleSubmit(onSubmit)}
            >
              Apply
            </button>
          </div>
        </Offcanvas.Header>
      </Offcanvas>
    </>
  );
};

export default memo(AdvanceFilterModal);
