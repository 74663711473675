import { ProductService } from "@sk/services";
import { Typeahead } from "@sk/uis";
import React, { memo, useState } from "react";

const SearchProduct = ({ callback, vendorId }) => {
  const [search, setSearch] = useState({
    loading: false,
    result: [],
    value: [],
  });

  const onSearch = async (val) => {
    setSearch((v) => ({ ...v, loading: true, result: [] }));
    let data = [];
    const r = await ProductService.getProductByVendor(vendorId, {
      filter: {
        name: { $regex: val, $options: "gi" },
        is_active: true,
        $or: [{ isSkWarehouse: true }, { isSkWarehouse: { $exists: false } }],
        original_creator_type: { $nin: ["THIRD_PARTY_SELLER"] },
      },
    });
    data = (r?.resp || []).map((x) => ({ label: x.name, value: x }));
    setSearch((v) => ({ ...v, loading: false, result: data }));
  };

  const onSelect = (val) => {
    setSearch((v) => ({ ...v, value: [] }));
    callback({ value: val });
  };

  return (
    <Typeahead
      label=""
      placeholder="Search for Product"
      val={search.value}
      searching={search.loading}
      onSearch={onSearch}
      result={search.result}
      onItemChange={onSelect}
      uid="po-product"
      gap={1}
    />
  );
};

export default memo(SearchProduct);
