import { AppCard, DateFormatter, HighlightText, KeyVal } from "@sk/uis";

const PosPoBasicInfo = ({ poDetails }) => {
  return (
    <div>
      {poDetails.status == "Rejected" && (
        <div className="alert alert-danger fw-semibold" role="alert">
          Purchase Order (PO) request has been rejected, &quot;
          {poDetails?._rejectedBy?.remarks} &quot;
        </div>
      )}
      <AppCard noShadow={true}>
        <div className="row">
          <div className="col-12">
            <div className="mb-2 fw-semibold fs-val-lg">Basic Details</div>
          </div>
          <div className="col-3">
            <KeyVal label="PO ID" labelCol="col-4" contentCol="col-8">
              <span className="fw-semibold">: {poDetails._id}</span>
            </KeyVal>
            <KeyVal label="Status" labelCol="col-4" contentCol="col-8">
              <HighlightText status={poDetails._statusLbl} isBadge={true} />
            </KeyVal>
          </div>
          <div className="col-3">
            <KeyVal label="Created On" labelCol="col-4" contentCol="col-8">
              <div className="fw-semibold">
                : <DateFormatter date={poDetails.createdAt} />
              </div>
            </KeyVal>
            {poDetails.status == "Approved" && (
              <KeyVal label="Approved By" labelCol="col-4" contentCol="col-8">
                : {poDetails?._approvedBy?.loggedByName}
              </KeyVal>
            )}
            {poDetails.status == "Rejected" && (
              <KeyVal label="Rejected By" labelCol="col-4" contentCol="col-8">
                : {poDetails?._rejectedBy?.loggedByName}
              </KeyVal>
            )}
          </div>
          <div className="col-3">
            <KeyVal label="Last Updated" labelCol="col-4" contentCol="col-8">
              <div className="fw-semibold">
                : <DateFormatter date={poDetails.createdAt} />
              </div>
            </KeyVal>
            {poDetails.status == "Approved" && (
              <KeyVal label="Approved On" labelCol="col-4" contentCol="col-8">
                <span className="fw-semibold">
                  : <DateFormatter date={poDetails?._approvedBy?.loggedOn} />
                </span>
              </KeyVal>
            )}
            {poDetails.status == "Rejected" && (
              <KeyVal label="Rejected On" labelCol="col-4" contentCol="col-8">
                <span className="fw-semibold">
                  : <DateFormatter date={poDetails?._rejectedBy?.loggedOn} />
                </span>
              </KeyVal>
            )}
          </div>
          <div className="col-3">
            <KeyVal label="Expected By" labelCol="col-4" contentCol="col-8">
              <div className="fw-semibold">
                :
                <DateFormatter
                  date={poDetails.expectedDeliveryDate}
                  format="dd MMM yyyy"
                />
              </div>
            </KeyVal>

            <KeyVal label="Bill To" labelCol="col-4" contentCol="col-8">
              <div className="fw-semibold">
                {poDetails.isBillToStore ? "Store" : "StoreKing"}
              </div>
            </KeyVal>
          </div>

          {/* <div className="col-12">
            {poDetails.status == "Rejected" && (
              <div>
                <span className="fw-semibold">Rejected Remarks : </span>
                <span className="text-danger">
                  &quot;{poDetails?._rejectedBy?.remarks}&quot;
                </span>
              </div>
            )}
          </div> */}
        </div>
      </AppCard>
    </div>
  );
};

export default PosPoBasicInfo;
