import React from "react";

const VendorDetails = ({ vendor = {} }) => {
  return (
    <div>
      <div className="fs-val-lg fw-semibold mb-1">Vendor Details</div>
      <div className="fs-val-md text-primary mb-1">
        {vendor.vendorName} ({vendor.vendorId})
      </div>
      <div className="mb-1 text-muted">{vendor?.address}</div>
      <div className="fw-semibold">
        <div className="d-inline-block me-2">
          <i className="bi bi-telephone-fill"></i>
          <span className="px-1">{vendor?.mobile}</span>
        </div>
        {vendor?.email ? (
          <div className="d-inline-block me-2">
            <i className="bi bi-envelope"></i>
            <span className="px-1">{vendor?.email}</span>
          </div>
        ) : null}

        <div className="d-inline-block me-2">
          <span>GST No.: </span>
          <span className="px-1">{vendor?.vendorGST || "N/A"}</span>
        </div>
      </div>
    </div>
  );
};

export default VendorDetails;
