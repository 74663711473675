import { useFetchUrlQueryString } from "@sk/hooks";
import { GrnService, NavService, UserService } from "@sk/services";
import {
  Amount,
  AppCard,
  AuditLogTable,
  BtnLink,
  DateFormatter,
  HighlightText,
  KeyVal,
  PageInfo,
  PageLoader,
  Tabs,
} from "@sk/uis";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import GrnViewBasicInfo from "./components/basic-info/GrnViewBasicInfo";
import GrnViewDebitAdjustmentTabLayout from "./components/debit-adjustment/GrnViewDebitAdjustmentTabLayout";
import GrnViewPoDetails from "./components/po-details/GrnViewPoDetails";
import GrnViewProduct from "./components/products/GrnViewProduct";
import GrnViewStatusLog from "./components/status-log/GrnViewStatusLog";
import GrnViewInvoice from "./components/invoice/GrnViewInvoice";
import GrnViewPaymentInfoModal from "./modals/GrnViewPaymentInfoModal";
import { orderBy } from "lodash";

const GrnView = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const query = useFetchUrlQueryString(searchParams);

  const [display, setDisplay] = useState("loading");
  const [details, setDetails] = useState({});
  const [activeTab, setActiveTab] = useState({ key: "products" }); // State for active tab
  const [paymentInfoModal, setPaymentInfoModal] = useState({ show: false });

  const calculateDebitAmounts = (autoDebitData) => {
    const calculateAmounts = (data, typeFilter) => {
      return data.reduce(
        (acc, v) => {
          if (v.status !== "Rejected") {
            if (v.totalAmount) acc.total += v.totalAmount;
            if (v.debitAmount) acc.total += v.debitAmount;
            if (v.ProductDiscrepancyAmount)
              acc.total += v.ProductDiscrepancyAmount;

            if (v.type === typeFilter) {
              if (v.totalAmount) acc.filtered += v.totalAmount;
              if (v.debitAmount) acc.filtered += v.debitAmount;
              if (v.ProductDiscrepancyAmount)
                acc.filtered += v.ProductDiscrepancyAmount;
            }
          }
          return acc;
        },
        { total: 0, filtered: 0 }
      );
    };

    const totalAmounts = calculateAmounts(autoDebitData);
    const autoAmounts = calculateAmounts(autoDebitData, "Auto");
    const manualAmounts = calculateAmounts(autoDebitData, "Manual");

    return {
      totalDebitAmount: totalAmounts.total,
      totalAutoDebitAmount: autoAmounts.filtered,
      totalManualDebitAmount: manualAmounts.filtered,
    };
  };

  useEffect(() => {
    const grnId = query?.id;
    if (grnId) {
      const fetchGrn = async () => {
        setDisplay("loading");
        const r = await GrnService.getGrnById(grnId);
        if (r.resp?._id) {
          const ids = [];

          const data = r.resp;

          if (r.resp?.modifiedBy) {
            ids.push(r.resp.modifiedBy);
          }

          if (ids.length > 0) {
            const u = await UserService.getList({
              filter: { _id: { $in: ids } },
              select: "name",
            });

            data._modifiedBy =
              u?.resp?.find((i) => i._id === data.modifiedBy) || {};
          }

          const autoDebit = await GrnService.getAutoDebitAdjustList(grnId);
          const debitAmounts = calculateDebitAmounts(autoDebit.resp);

          setDetails({
            ...data,
            autoDebitAdjustments: autoDebit.resp,
            autoDebitAmounts: debitAmounts,
            productDetails: orderBy(data.productDetails, ["name"], ["asc"]),
            basic: {
              lrNumber: data.lrNumber,
              vehicleNumber: data.vehicleNumber,
              shippingCost: data.shippingCost,
              remarks: data.remarks,
              vendor: { ...data.vendor, vendorId: data.vendorId },
              warehouse: data.whAddress,
            },
            paymentDetails: {
              invoiceAmount: data.totalInvoiceValue,
              grnAmount: data.actualGrnAmount,
              debitAmounts,
              payableAmount: data.payableAmount,
            },
          });
          setDisplay("details");
        } else {
          setDetails({});
          setDisplay("error");
        }
      };

      fetchGrn();
    }
  }, [query]);

  const viewVendor = () => {
    const vendorId = details?.vendorId;
    if (vendorId) {
      NavService.openInNewTab("/vendor/view", {
        id: vendorId,
      });
    }
  };

  const handlePaymentInfoModal = (action) => {
    if (action === "close") {
      setPaymentInfoModal({ show: false });
    }
  };

  const tabs = [
    { key: "products", tabName: "Products" },
    { key: "basicInfo", tabName: "Basic Info" },
    { key: "poDetails", tabName: "PO Details" },
    { key: "invoiceDetails", tabName: "Invoice Details" },
    { key: "creditDebitAdjustment", tabName: "Credit/Debit Adjustment" },
    { key: "statusUpdateLog", tabName: "Status Update Log" },
    { key: "auditLog", tabName: "Audit Log" },
  ];

  return (
    <>
      <PageInfo
        title={"GRN Details - #" + details?._id}
        breadcrumbs={breadcrumbs}
        navigate={navigate}
      />

      {display === "loading" && <PageLoader />}

      {display === "details" ? (
        <>
          <AppCard title={"Basic Details"}>
            <div className="row">
              <div className="col-4">
                <KeyVal label="GRN ID">: {details?._id}</KeyVal>
                <KeyVal label="Status">
                  :{" "}
                  <HighlightText type={details.statusColor} template={2}>
                    {details?.status}
                  </HighlightText>
                </KeyVal>
                <KeyVal label="Warehouse" noBottomSpace={true}>
                  : {details?.whAddress?.name} - ({details?.whId})
                </KeyVal>
              </div>
              <div className="col-4">
                <KeyVal label="Vendor">
                  :{" "}
                  <BtnLink className="fs-val-md" onClick={viewVendor}>
                    {details?.vendor?.name}
                  </BtnLink>
                </KeyVal>
                <KeyVal label="Stock Received" noBottomSpace={true}>
                  :{" "}
                  <DateFormatter
                    date={details.stockReceivedAt}
                    format={"dd MMM yyyy"}
                  />
                </KeyVal>
              </div>
              <div className="col-4">
                <KeyVal label="Created At">
                  :{" "}
                  <DateFormatter
                    date={details.createdAt}
                    format={"dd MMM yyyy"}
                  />
                  ,
                  <span className="text-muted text-truncate">
                    {" "}
                    by {details?.createdBy}
                  </span>
                </KeyVal>
                <KeyVal label="Last Updated" noBottomSpace={true}>
                  :{" "}
                  <DateFormatter
                    date={details.lastUpdated}
                    format={"dd MMM yyyy"}
                  />
                  <span className="text-muted text-truncate">
                    {" "}
                    , by {details?._modifiedBy?.name}
                  </span>
                </KeyVal>
              </div>
            </div>
          </AppCard>

          <AppCard title={"Payment Details"}>
            <div className="row">
              <div className="col-4">
                <KeyVal label="Total Amount" noBottomSpace={true}>
                  :{" "}
                  <span className="text-danger fw-semibold">
                    <Amount value={details?.amount} decimalPlace={2} />
                  </span>
                </KeyVal>
              </div>
              <div className="col-4">
                <KeyVal
                  label="Debit Amount"
                  labelCol="col-4"
                  contentCol="col-8"
                  noBottomSpace={true}
                >
                  :{" "}
                  <Amount
                    value={details?.autoDebitAmounts?.totalDebitAmount}
                    decimalPlace={2}
                  />
                </KeyVal>
              </div>
              <div className="col-4">
                <BtnLink
                  className="fs-val-sm"
                  onClick={() => setPaymentInfoModal({ show: true })}
                >
                  <i className="bi bi-info-circle me-1"></i>
                  View More About Payments
                </BtnLink>
              </div>
            </div>
          </AppCard>

          <div className="mb-3">
            <Tabs
              data={tabs}
              callback={({ value }) => setActiveTab(value)}
              activeTab={activeTab}
              shadow={true}
              template={4}
            />
          </div>

          {activeTab.key === "products" && (
            <GrnViewProduct data={details.productDetails} />
          )}

          {activeTab.key === "basicInfo" && (
            <GrnViewBasicInfo details={details?.basic} />
          )}

          {activeTab.key === "poDetails" && (
            <GrnViewPoDetails poData={details.uniquePos} />
          )}

          {activeTab.key === "invoiceDetails" && (
            <GrnViewInvoice data={details?.invoiceDetails} />
          )}

          {activeTab.key === "creditDebitAdjustment" && (
            <GrnViewDebitAdjustmentTabLayout
              data={details?.autoDebitAdjustments}
              autoDebitAmounts={details?.autoDebitAmounts}
              grnId={details._id}
            />
          )}

          {activeTab.key === "statusUpdateLog" && (
            <GrnViewStatusLog data={details?.statusUpdateLog} />
          )}

          {activeTab.key === "auditLog" && (
            <AppCard>
              <AuditLogTable entityId={details._id} entityType={"Grn"} />
            </AppCard>
          )}

          <GrnViewPaymentInfoModal
            show={paymentInfoModal.show}
            callback={({ action }) => handlePaymentInfoModal(action)}
            details={details?.paymentDetails}
          />
        </>
      ) : null}
    </>
  );
};

const breadcrumbs = [
  {
    name: "Home",
    to: "/",
  },
  {
    name: "GRN List",
    link: "/purchase-order/grn/list",
  },
  {
    name: "GRN Details",
  },
];

export default GrnView;
