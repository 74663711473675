import { VendorMarginModal, VendorOpenPoModal } from "@sk/features";
import { VendorService } from "@sk/services";
import {
  DateFormatter,
  InfoPopover,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo, useCallback, useEffect, useRef, useState } from "react";

const tableHeaders = [
  {
    label: "Sl.No",
    width: "5%",
  },
  {
    label: "Vendor ID",
    width: "8%",
  },

  {
    label: "Vendor Name",
    width: "20%",
  },
  {
    label: "Address",
    width: "15%",
  },
  {
    label: "Avg. Delivery TAT",
    width: "10%",
    isCentered: true,
  },
  {
    label: "Credit Day",
    width: "10%",
    isCentered: true,
  },
  {
    label: "Total PO",
    width: "5%",
    isCentered: true,
  },
  {
    label: "Open PO",
    width: "5%",
    isCentered: true,
  },
  {
    label: "Last PO Date",
    width: "15%",
  },
  {
    label: "Action",
    isCentered: true,
    width: "auto",
  },
];

const getData = async (type, params) => {
  if (type === "topVendors") {
    const whId = "WMF28";
    const r = await VendorService.getVendorsAnalyticsOnWh(
      {
        filter: { whId },
      },
      true
    );
    return { data: r };
  } else {
    const r = await VendorService.getVendors(params);
    const vendorData = r.resp;

    // Extract vendor IDs for analytics
    const vendorIds = vendorData.map((vendor) => vendor._id);

    // Fetch analytics data
    const analyticsResponse = await VendorService.getVendorsAnalyticsOnWh(
      {
        page: 1,
        count: vendorIds.length,
        filter: {
          "contact.vendorId": { $in: vendorIds },
          whId: "WMF28",
        },
      },
      false
    );

    const analyticsData = analyticsResponse;

    // Combine vendor data with analytics data
    const combinedData = vendorData.map((vendor) => {
      const analytics = analyticsData.find((a) => a.vId === vendor._id) || {};
      return {
        ...vendor,
        _analytics: analytics, // Attach analytics data to vendor
      };
    });

    return { data: combinedData };
  }
};

const getCount = async (params) => {
  const r = await VendorService.getCount(params);
  return { count: r.resp };
};

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const prepareFilterParams = (filter, pagination) => {
  let params = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      margins: {
        $elemMatch: {
          whId: { $in: [filter.wh] },
        },
      },
    },
  };

  let categoryIds = filter?.category?.map((x) => x.value?._id);
  let brandIds = filter?.brand?.map((x) => x.value?._id);
  const productIds = filter?.product?.map((x) => x.value?._id);

  if (filter?.product?.length > 0) {
    filter?.product?.forEach((x) => {
      const product = x.value;
      if (Array.isArray(product?.brand)) {
        brandIds.push(...product.brand);
      }
      if (Array.isArray(product?.category)) {
        categoryIds.push(...product.category);
      }
    });
  }

  if (filter?.vendorName) {
    params.filter.vendorName = { $regex: filter.vendorName, $options: "i" };
  }

  if (categoryIds?.length > 0) {
    params.filter.margins.$elemMatch.category = { $in: categoryIds };
  }

  if (brandIds?.length > 0) {
    params.filter.margins.$elemMatch.brand = { $in: brandIds };
  }

  if (productIds?.length > 0) {
    // params.filter.margins.$elemMatch.product = { $in: productIds };
  }

  return params;
};

const tableContainerStyle = {
  maxHeight: "500px",
  overflowY: "auto",
  overflowX: "auto",
};

const Table = ({ type, filterData }) => {
  const [showOpenPoModal, setShowOpenPoModal] = useState(false);
  const modalDataRef = useRef({ vendorDetails: {} });

  const paginationRef = useRef({ ...defaultPaginationConfig });
  const sortRef = useRef({ key: "", direction: "asc" });
  const filterRef = useRef({});

  const [loading, setLoading] = useState(true);
  const [vendors, setVendors] = useState([]);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);
  const [marginModal, setMarginModal] = useState({
    show: false,
    vendorId: null,
  });

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    loadData();

    setLoadingTotalRecords(false);
    const r = await getCount(
      prepareFilterParams(filterRef.current, paginationRef.current)
    );
    paginationRef.current.totalRecords = r.count;
  }, []);

  useEffect(() => {
    filterRef.current = { ...filterRef.current, type, ...filterData };
    applyFilter();
  }, [type, filterData]);

  const loadData = async () => {
    setLoading(true);

    const r = await getData(
      filterRef.current.type,
      prepareFilterParams(filterRef.current, paginationRef.current)
    );

    setVendors(r.data);
    setLoading(false);
  };

  const closePOModal = useCallback(() => {
    modalDataRef.current.vendorDetails = {};
    setShowOpenPoModal(false);
  }, []);

  const closeMarginModal = useCallback(() => {
    setMarginModal({ show: false, vendorId: null });
  }, []);

  const handleSort = (key) => {
    const direction =
      sortRef.current.key === key && sortRef.current.direction === "asc"
        ? "desc"
        : "asc";
    sortRef.current = { key, direction };
    loadData();
  };

  const paginationCb = (data) => {
    paginationRef.current.activePage = data.activePage;
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    loadData();
  };

  const openMarginModal = (vendorId) => {
    setMarginModal({ show: true, vendorId });
  };

  return (
    <>
      <PaginationSummary
        paginationConfig={paginationRef.current}
        loadingTotalRecords={loadingTotalRecords}
        className="mb-3"
      />

      <div
        className="tbl-scroll-container custom-scrollbar thin-scrollbar mb-3"
        style={tableContainerStyle}
      >
        <table className="table table-sm table-hover">
          <TableHeader data={tableHeaders} onSort={handleSort} isSticky noBg />
          <tbody>
            {loading ? (
              <TableSkeletonLoader
                rows={paginationRef.current.rowsPerPage}
                cols={tableHeaders.length}
              />
            ) : vendors.length === 0 ? (
              <tr>
                <td colSpan={tableHeaders.length}>
                  <NoDataFound>No Data Found</NoDataFound>
                </td>
              </tr>
            ) : (
              vendors.map((x, index) => (
                <tr key={x._id} className="fs-val-md">
                  <td>{paginationRef.current.startSlNo + index}</td>
                  <td>{x._id}</td>
                  <td>
                    <div className="mb-1 fw-semibold">{x.name}</div>
                    <div className="fs-val-sm">
                      {x._contactDetails?.mobile && (
                        <span className="d-inline-flex align-items-center me-2 text-muted">
                          <i className="bi bi-telephone me-1"></i>
                          {x._contactDetails.mobile}
                        </span>
                      )}
                      {x._contactDetails?.email && (
                        <span className="d-inline-flex align-items-center me-2 text-muted">
                          <i className="bi bi-envelope me-1"></i>
                          {x._contactDetails.email}
                        </span>
                      )}
                    </div>
                  </td>

                  <td>
                    {x.address ? (
                      <div className="text-wrap">
                        <div
                          className="text-truncate"
                          style={{ maxWidth: "300px" }}
                        >
                          <i className="bi bi-geo-alt me-1"></i>
                          {x.address.line1}
                        </div>
                        {x.address.line2 ? (
                          <div>
                            <InfoPopover
                              header="Full Address"
                              content={`${x.address.line1}, ${
                                x.address.line2
                              }, ${x.address.landmark || ""}`}
                              placement="top"
                            >
                              <button className="btn btn-link p-0 btn-sm fs-val-xs text-muted text-start">
                                View More
                              </button>
                            </InfoPopover>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      "--"
                    )}
                  </td>
                  <td className="text-center">{x.average_tat} days</td>
                  <td className="text-center">{x.credit_days} days</td>
                  <td className="text-center">{x?._analytics?.totalPo || 0}</td>
                  <td className="text-center">{x?._analytics?.openPo || 0}</td>
                  <td>
                    {x._analytics.lastPoDate ? (
                      <DateFormatter
                        date={x._analytics.lastPoDate}
                        format="dd MMM yyyy"
                      />
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td className="text-center">
                    <div>
                      <button className="btn btn-primary btn-sm me-2 fs-val-md">
                        Create PO
                      </button>
                      <button
                        className="btn btn-info btn-sm fs-val-md"
                        onClick={() => openMarginModal(x._id)}
                      >
                        View Margin
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <PaginationBlock
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />

      <VendorOpenPoModal
        show={showOpenPoModal}
        closeModal={closePOModal}
        vendorId={modalDataRef.current.vendorDetails._id}
      />

      <VendorMarginModal
        show={marginModal.show}
        closeModal={closeMarginModal}
        vendorId={marginModal.vendorId}
      />
    </>
  );
};

export default memo(Table);
