import { CommonService, NavService } from "@sk/services";
import {
  Amount,
  AppCard,
  BtnLink,
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

const headers = [
  { label: "#", width: "3%" },
  { label: "Product", width: "20%" },
  { label: "PID", width: "10%", isCentered: true },
  { label: "PO ID", width: "10%" },
  { label: "Invoice Qty", width: "10%", isCentered: true },
  { label: "Received Qty", width: "10%", isCentered: true },
  { label: "Tax value", width: "10%", isCentered: true },
  { label: "MRP", width: "10%", isCentered: true },
  { label: "Base Margin", width: "10%", isCentered: true },
  { label: "Scheme Margin", width: "10%", isCentered: true },
  { label: "Purchase Price", width: "10%", isCentered: true },
  { label: "Total Price", width: "10%", isCentered: true },
];

const containerStyle = {
  overflowX: "auto",
  maxHeight: "calc(100vh - 300px)",
};

const tableStyle = {
  minWidth: "1500px",
};

const GrnViewProduct = ({ data, loading }) => {
  const viewProduct = (product) => {
    NavService.openInNewTab("/cms/product/view", {
      id: product.productId,
    });
  };

  return (
    <AppCard>
      <div className="fs-val-md mb-2">Showing {data.length} products</div>

      <div
        style={containerStyle}
        className="mb-3 custom-scrollbar thin-scrollbar tbl-scroll-container fixed-width-table"
      >
        <table className="table table-sm table-hover" style={tableStyle}>
          <TableHeader data={headers} isSticky={true} />
          <tbody>
            {loading ? (
              <TableSkeletonLoader rows={10} cols={headers.length} />
            ) : null}

            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Products found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {!loading &&
              data.map((x, index) => (
                <tr key={x._id} className="fs-val-md">
                  <td>{index + 1}</td>
                  <td>
                    <div className="mb-1">
                      <BtnLink
                        className="fs-val-md text-wrap"
                        onClick={() => viewProduct(x)}
                      >
                        {x.name}
                      </BtnLink>
                    </div>
                  </td>
                  <td className="text-center">{x.productId}</td>
                  <td>
                    {x._poIds?.[0] ? (
                      <BtnLink
                        className="fs-val-md"
                        onClick={(e) => {
                          e.stopPropagation();
                          NavService.openInNewTab("/purchase-order/view", {
                            id: x._poIds[0],
                          });
                        }}
                      >
                        {x._poIds[0]}
                      </BtnLink>
                    ) : (
                      "--"
                    )}
                    <div className="fs-val-sm text-muted">
                      {x._poIds?.length > 1 ? `+${x._poIds.length - 1}` : ""}
                    </div>
                  </td>
                  <td className="text-center">{x.invoiceQuantity}</td>
                  <td className="text-center">{x.receivedQuantity}</td>
                  <td className="text-center">
                    <Amount value={x?.taxValue} decimalPlace={2} />
                  </td>
                  <td className="text-center">
                    <Amount value={x.price?.mrp} decimalPlace={2} />
                  </td>
                  <td className="text-center">{x.price?.margin?.bMargin}</td>
                  <td className="text-center">
                    {x.price?.margin?.sMarginType === "%" ? (
                      <>
                        {CommonService.roundedByDecimalPlace(
                          x.price?.margin?.sMargin || 0,
                          2
                        )}
                        %
                      </>
                    ) : (
                      <Amount
                        value={x.price?.margin?.sMargin}
                        decimalPlace={2}
                      />
                    )}
                  </td>
                  <td className="text-center">
                    <Amount value={x.price?.purchasePrice} decimalPlace={2} />
                  </td>
                  <td className="text-center">
                    <Amount value={x.baseValue} decimalPlace={2} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </AppCard>
  );
};

export default GrnViewProduct;
