import { NavService } from "@sk/services";
import {
  Amount,
  BtnLink,
  DateFormatter,
  HighlightText,
  InfoPopover,
  NoDataFound,
  PaginationBlock,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

const containerStyle = {
  overflowX: "auto",
  maxHeight: "calc(100vh - 300px)",
};

const tableStyle = {
  minWidth: "1600px", // Ensure full width for all columns
};

const headers = [
  { label: "Sl.no", width: "3%", isSticky: true, stickyPosition: "left" },
  {
    label: "GRN ID",
    width: "8%",
    isSticky: true,
    stickyPosition: "left",
    style: { left: "4.5%" },
  },
  { label: "Vendor ID", width: "8%" },
  { label: "Vendor", width: "15%" },
  { label: "Inwarded On", width: "8%" },
  { label: "Items", width: "5%" },
  { label: "Status", width: "8%" },
  { label: "Invoice Qty", width: "6%", isCentered: true },
  { label: "Received Qty", width: "6%", isCentered: true },
  { label: "Invoice Value", width: "6%", isCentered: true },
  { label: "Total Value", width: "6%", isCentered: true },
  { label: "Warehouse", width: "5%" },
  { label: "Action", width: "6%" },
];

const rbac = {
  viewGrn: ["ViewGRN"],
};

const stickySerialNumberStyle = {
  left: 0,
};

const stickyGrnIdStyle = {
  left: "4.5%",
};

const tdStyle = {
  quantity: { backgroundColor: "#f0f8ff" }, // Light blue
  value: { backgroundColor: "#f0fff0" }, // Light green
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  const viewGrnDetails = (id) => {
    NavService.openInNewTab("/purchase-order/grn/view", { id });
  };

  const viewVendorDetails = (id) => {
    NavService.openInNewTab("/vendor/view", { id });
  };

  return (
    <div>
      <div className="fs-val-sm mb-1 text-muted">
        Showing <span className="text-dark">{data.length}</span> GRNs
      </div>
      <div
        style={containerStyle}
        className="mb-3 custom-scrollbar thin-scrollbar tbl-scroll-container fixed-width-table"
      >
        <table className="table table-sm" style={tableStyle}>
          <TableHeader
            data={headers}
            isSticky={true}
            noBg={true}
            sort={sort}
            sortCb={sortCb}
          />
          <tbody className="fs-val-md">
            {loading && <TableSkeletonLoader rows={10} cols={headers.length} />}

            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No GRN found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {!loading &&
              data.map((x, index) => (
                <tr
                  key={x._id}
                  className="cursor-pointer"
                  onClick={() => viewGrnDetails(x._id)}
                >
                  <td
                    className="sticky-column bg-white"
                    style={stickySerialNumberStyle}
                  >
                    {paginationConfig.startSlNo + index}
                  </td>
                  <td
                    className="sticky-column bg-white"
                    style={stickyGrnIdStyle}
                  >
                    <BtnLink
                      className="fs-val-md"
                      onClick={(e) => {
                        e.stopPropagation();
                        viewGrnDetails(x._id);
                      }}
                    >
                      {x._id}
                    </BtnLink>
                  </td>
                  <td>{x.vendorId}</td>
                  <td>
                    <BtnLink
                      className="fs-val-md text-wrap"
                      onClick={(e) => {
                        e.stopPropagation();
                        viewVendorDetails(x.vendorId);
                      }}
                    >
                      {x?.vendor?.name ?? "N/A"}
                    </BtnLink>
                  </td>
                  <td>
                    {x.inwardedOn ? (
                      <DateFormatter
                        date={x.inwardedOn}
                        format={"dd MMM yyyy"}
                      />
                    ) : (
                      "--"
                    )}
                  </td>
                  <td>
                    {x.productDetails.length}{" "}
                    <span className="fs-val-sm text-black-50">items</span>
                  </td>
                  <td>
                    <HighlightText
                      type={x.statusColor}
                      template={2}
                      className="text-capitalize"
                    >
                      {x.status}
                    </HighlightText>
                  </td>
                  <td className="text-center" style={tdStyle.quantity}>
                    {x.totalInvoiceQty}
                  </td>
                  <td className="text-center" style={tdStyle.quantity}>
                    <span
                      className={
                        x.totalInvoiceQty != x.totalReceivedQty
                          ? "text-danger"
                          : ""
                      }
                    >
                      {x.totalReceivedQty}
                    </span>
                  </td>
                  <td className="text-center" style={tdStyle.value}>
                    <Amount value={x.totalInvoiceValue} decimalPlace={2} />
                  </td>
                  <td className="text-center" style={tdStyle.value}>
                    <Amount value={x.payableAmount} decimalPlace={2} />
                  </td>
                  <td>
                    {x.whId}
                    <InfoPopover content={<div>{x.whAddress?.name}</div>} />
                  </td>
                  <td>
                    <Rbac roles={rbac.viewGrn}>
                      <button className="btn btn-sm fs-val-sm btn-outline-primary">
                        View
                      </button>
                    </Rbac>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />
    </div>
  );
};

export default Table;
