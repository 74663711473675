import { PosPoPrintTemplate } from "@sk/features";
import { NavService } from "@sk/services";
import {
  Amount,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { useRef, useState } from "react";
import ClosePoRemarksModal from "../../components/ClosePoRemarksModal";

const trStyle = {
  height: "110px",
};

const headers = [
  { label: "Sl No", width: "5%", isCentered: true, priority: 1 },
  { label: "PO ID" },
  { label: "Store Details", width: "15%" },
  { label: "Vendor Details", width: "15%" },
  { label: "Deals", isCentered: true },
  { label: "PO Value", isCentered: true },
  // { label: "Invoice Ref No.", isCentered: true, width: "10%" },
  { label: "Bill TO", isCentered: true },
  { label: "Status", isCentered: true, width: "10%" },
  { label: "Created On" },
  { label: "Actions", isCentered: true, width: "8%" },
];

const approveBtnRbac = ["ApproveThirdpartyPO"];
const viewBtnRbac = ["ViewThirdpartyPO"];
const printBtnRbac = ["ListThirdPartyPO"];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  openPendingPaymentModal,
  sortCb,
}) => {
  // Navigate to View  Product
  const viewPosPo = (id) => {
    NavService.openInNewTab("/purchase-order/pos/view", {
      id,
    });
  };

  const viewApprovePosPo = (id) => {
    NavService.openInNewTab("/purchase-order/pos/approve", {
      id,
    });
  };

  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />

        {/* Table Body */}
        <tbody>
          {/*  When Table is Loading  */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}
          {/* When No loading */}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Data Found </NoDataFound>
              </td>
              <td></td>
            </tr>
          ) : null}
          {!loading && data.length
            ? data.map((x, index) => (
                <tr key={index} className="fs-val-md" style={trStyle}>
                  {/* Serial Number */}
                  <td className="text-center">
                    {paginationConfig.startSlNo + index}
                  </td>
                  <td className="text-center">{x._id}</td>

                  {/* Franchise Details */}
                  <td>
                    <div className="text-primary mb-1">
                      {x.franchise.name ?? "N/A"} - {x.franchise.id ?? "N/A"}
                    </div>
                    <div className="fs-val-sm text-muted">
                      <span>{x.franchise?.town}, </span>
                      <span>{x.franchise?.district}, </span>
                      <span>{x.franchise?.state} </span>
                      <span>- {x.franchise?.pincode}</span>
                    </div>
                  </td>

                  {/* Vendor Details */}
                  <td>
                    <div className="text-primary mb-1">
                      {x?.vendorDetails.name ?? "N/A"} -{" "}
                      {x?.vendorDetails?.id ?? "N/A"}
                    </div>
                    <div className="fs-val-sm text-muted">
                      <span>
                        {x.vendorDetails?.town ||
                          x.vendorDetails?.address?.city}
                        ,
                      </span>
                      <span> {x.vendorDetails?.district}, </span>
                      <span>{x.vendorDetails?.state} </span>
                      <span>- {x.vendorDetails?.postcode}</span>
                    </div>
                  </td>

                  {/* Product Details */}
                  <td className="fw-semibold text-center">
                    {x.productList.length}
                  </td>
                  {/* PO Value */}
                  <td className="text-primary text-center">
                    <Amount value={x.poSummary.amount} decimalPlace={2} />
                  </td>

                  {/* Bill To */}
                  <td className="text-center">
                    <span className="badge badge-soft-blue">
                      {x.isBillToStore ? "Store" : "StoreKing"}
                    </span>
                  </td>

                  {/* Status */}
                  <td className="text-center">
                    <HighlightText status={x._statusLbl} />
                  </td>

                  {/* Created On  */}
                  <td>
                    <DateFormatter date={x.createdAt} />
                  </td>

                  <td className="text-start">
                    <Rbac roles={approveBtnRbac}>
                      {x.status == "Approval Pending" && (
                        <button
                          className="btn btn-sm btn-outline-success fs-val-xs mb-2 w-100"
                          onClick={() => viewApprovePosPo(x._id)}
                        >
                          Approve
                        </button>
                      )}
                    </Rbac>

                    <Rbac roles={viewBtnRbac}>
                      <button
                        className="btn w-100 btn-sm btn-outline-primary fs-val-xs mb-2"
                        onClick={() => viewPosPo(x._id)}
                      >
                        View
                      </button>
                    </Rbac>

                    {x.status != "Rejected" && (
                      <Rbac roles={printBtnRbac}>
                        <button
                          className="btn w-100 btn-sm btn-outline-primary fs-val-xs mb-2"
                          onClick={() => PosPoPrintTemplate.doPrint(x)}
                      >
                          Print PO
                        </button>
                      </Rbac>
                    )}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>

      {/* Pagination Block */}
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default Table;

/* 
{x.isBillToStore === false &&
                      x.status == "Received - Payment Pending" && (
                        <div>
                          <button
                            className="btn w-100 btn-sm btn-outline-primary fs-val-xs"
                            onClick={() => {
                              openPendingPaymentModal(x, index);
                            }}
                          >
                            Payment Pending
                          </button>
                        </div>
                      )}
*/
