import { TableHeader, Toaster } from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const headers = [
  { label: "Sl No", width: "10%" },
  { label: "Product Id", width: "40%" },
  { label: "Exceed Qty", width: "40%" },
];

const defaultFormData = {
  first: false,
  second: false,
  third: false,
};

const GrnInwardWarningModal = ({ show, handleClose, warningData }) => {
  const [formData, setFormData] = useState({ ...defaultFormData });
  const onHideCb = useCallback(() => {
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    setFormData({ ...defaultFormData });
  }, []);

  const submit = () => {
    let msg = "";
    if (warningData.firstWarning?.isWarning && !formData.first) {
      msg = "Please Select Checkbox for GRN inwarded withing 48 hours";
    } else if (warningData.secondWarning?.isWarning && !formData.second) {
      msg = "Please Select Checkbox for Stocks Exceeding requested po products";
    } else if (warningData.thirdWarning?.isWarning && !formData.third) {
      msg =
        "Please Select Checkbox for Given products already inwared for same po and other grn ids";
    }
    if (msg) {
      Toaster.error(msg);
      return;
    } else {
      handleClose({ status: "success" });
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHideCb} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">GRN Intake Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-danger fs-val-sm">
            Note: Please select all check boxes to proceed further
          </div>

          {/* First warning msg */}
          {warningData?.firstWarning?.isWarning ? (
            <div className="mt-3">
              <input
                className="form-check-input me-3"
                type="checkbox"
                checked={formData.first}
                onChange={(e) =>
                  setFormData({ ...formData, first: e.target.checked })
                }
              />
              {warningData?.firstWarning?.message || "rtertwetetwet regerg"}
            </div>
          ) : null}

          {/* Second Warning */}
          {warningData?.secondWarning?.isWarning ? (
            <>
              <div className="mt-5">
                <input
                  className="form-check-input me-3"
                  type="checkbox"
                  checked={formData.second}
                  onChange={(e) =>
                    setFormData({ ...formData, second: e.target.checked })
                  }
                />
                Inwarded Quantities for given PO's Products exceeding requested
                PO Qty
              </div>
              <div className="row mt-2 ms-1">
                <div className="col-4 ms-3">
                  <table className="table table-bordered bg-white table-hover">
                    <TableHeader data={headers} />
                    <tbody className="fs-val-md">
                      {warningData?.secondWarning?.excessQtyProducts.map(
                        (e, i) => (
                          <tr key={i + e.pId}>
                            <td>{i + 1}</td>
                            <td>{e.pId}</td>
                            <td>{e.execessQty}</td>
                          </tr>
                        )
                      )}
                      <tr key={"34234"}>
                        <td>1</td>
                        <td>P324234</td>
                        <td>344</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : null}

          {/* Third Warning */}

          {warningData?.thirdWarning?.isWarning ? (
            <div className="mt-3">
              <input
                className="form-check-input me-3"
                type="checkbox"
                checked={formData.third}
                onChange={(e) =>
                  setFormData({ ...formData, third: e.target.checked })
                }
              />
              {warningData?.thirdWarning?.message} and Product ID's :
              {warningData?.thirdWarning?.previouslyInwardedPIds.join(",")}
            </div>
          ) : null}

          <div className="mt-3 text-end">
            <button className="btn btn-primary" onClick={submit}>
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(GrnInwardWarningModal);
