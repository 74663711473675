import { NavService } from "@sk/services";
import {
  Amount,
  BtnLink,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

const headers = [
  { label: "Sl No", width: "3%", key: "slNo" },
  { label: "PO ID", key: "poId", width: "5%" },
  { label: "Vendor Name", key: "vendorName", width: "20%" },
  { label: "Vendor ID", key: "vendorId", width: "5%" },
  { label: "Created On", key: "created", width: "13%" },
  { label: "Expected", key: "expected", width: "13%" },
  { label: "PO Value", key: "poValue", width: "9%" },
  { label: "PO Items", key: "poItems", width: "9%" },
  { label: "PO Status", key: "status", width: "9%" },
  { label: "Warehouse", width: "15%", key: "warehouse" },
  { label: "Actions", key: "action", width: "15%" },
];

const rbac = {
  viewPo: ["ViewPO"],
};

// Define styles outside the component
const tableContainerStyle = {
  maxHeight: "600px",
  overflowY: "auto",
  overflowX: "auto",
};

const tableStyle = {
  minWidth: "1400px",
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
}) => {
  const viewDetails = (id) => {
    NavService.openInNewTab("/purchase-order/view", { id: id });
  };
  const editPo = (data, ev) => {
    ev.stopPropagation();
    let p = {
      id: data?.contact?.vendorId,
      wh: data?.whId,
      poId: data._id,
    };
    NavService.openInNewTab("/purchase-order/manage/product", p);
  };

  const onRowClick = (id) => {
    NavService.openInNewTab("/purchase-order/view", { id: id });
  };

  const viewVendor = (id, ev) => {
    ev.stopPropagation();
    NavService.openInNewTab("/vendor/view", { id: id });
  };

  return (
    <>
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationConfig}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>
      <div
        className="tbl-scroll-container custom-scrollbar thin-scrollbar mb-3"
        style={tableContainerStyle}
      >
        <table className="table table-sm table-hover" style={tableStyle}>
          <TableHeader data={headers} noBg isSticky />
          <tbody className="fs-val-md">
            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Purchase order found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {loading ? (
              <TableSkeletonLoader
                rows={paginationConfig.rowsPerPage}
                cols={headers.length}
              />
            ) : null}

            {!loading &&
              data.map((x, index) => (
                <tr
                  key={x._id}
                  onClick={() => onRowClick(x._id)}
                  className="cursor-pointer"
                >
                  <td>{paginationConfig.startSlNo + index}</td>
                  <td>{x._id}</td>
                  <td>
                    <BtnLink
                      className="fs-val-md"
                      onClick={(ev) => viewVendor(x.contact?.vendorId, ev)}
                    >
                      {x.contact?.vendorName}
                    </BtnLink>
                  </td>
                  <td>{x.contact?.vendorId}</td>
                  <td>
                    <DateFormatter date={x.createdAt} format="dd MMM yyyy" />,
                    <span className="text-muted fs-val-sm ms-1">
                      <DateFormatter date={x.createdAt} format="hh:mm a" />
                    </span>
                  </td>
                  <td>
                    <DateFormatter date={x.expectedAt} format="dd MMM yyyy" />
                  </td>
                  <td>
                    <Amount value={x.poValue} decimalPlace={2} />
                  </td>
                  <td>
                    {x.products?.length || 0}{" "}
                    <span className="text-muted fs-val-sm ms-1">items</span>
                  </td>
                  <td>
                    <HighlightText
                      type={x._statusColor}
                      template={2}
                      className="text-capitalize"
                    >
                      {x.status}
                    </HighlightText>
                  </td>
                  <td>{x._wh?.name}</td>
                  <td>
                    <Rbac roles={rbac.viewPo}>
                      <button className="btn btn-sm fs-val-sm btn-outline-primary">
                        View
                      </button>
                    </Rbac>
                    {/* {x.status == "Draft" && (
                      <Rbac roles={rbac.viewPo}>
                        <button
                          className="btn btn-sm fs-val-sm btn-outline-warning ms-1"
                          onClick={(ev) => editPo(x, ev)}
                        >
                          Edit
                        </button>
                      </Rbac>
                    )} */}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />
    </>
  );
};

export default Table;
