import { PoService } from "@sk/services";
import { useEffect, useState } from "react";
import {
  AppCard,
  TableHeader,
  TableSkeletonLoader,
  NoDataFound,
  Amount,
} from "@sk/uis";
import { produce } from "immer";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "PO ID", width: "15%" },
  { label: "Total PO Qty", width: "15%" },
  { label: "Total Invoice Qty", width: "15%", isCentered: true },
  { label: "Total Received Qty", width: "15%", isCentered: true },
  { label: "Total PO Price", width: "15%", isCentered: true },
  { label: "Total Intake Value", width: "20%", isCentered: true },
];

const GrnViewPoDetails = ({ poData }) => {
  const [loading, setLoading] = useState(true);
  const [poDetails, setPoDetails] = useState([]);

  useEffect(() => {
    const fetchPoDetails = async () => {
      setLoading(true);
      const r = await PoService.getList({
        filter: {
          _id: { $in: poData.map((x) => x.poId) },
        },
      });

      const d = produce(poData, (draft) => {
        draft.forEach((item) => {
          const po = r.resp.find((poItem) => poItem._id === item.poId);
          if (po) {
            item.totalValue = po.poValue;
            item.invoiceQty = po.totalInvoicedQty;
          }
        });
      });
      setPoDetails(d);

      setLoading(false);
    };

    if (poData && poData.length > 0) {
      fetchPoDetails();
    }
  }, [poData]);

  return (
    <AppCard>
      <div className="mb-3 custom-scrollbar thin-scrollbar rounded border">
        <table className="table table-sm table-hover">
          <TableHeader data={headers} isSticky={true} />
          <tbody>
            {loading ? (
              <TableSkeletonLoader rows={10} cols={headers.length} />
            ) : null}

            {!loading && !poDetails.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No PO Details found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {!loading &&
              poDetails.map((x, index) => (
                <tr key={x.poId} className="fs-val-md">
                  <td>{index + 1}</td>
                  <td>{x.poId}</td>
                  <td>{x.invoiceQty}</td>
                  <td className="text-center">{x.invoiceQuantity}</td>
                  <td className="text-center">{x.receivedQuantity}</td>
                  <td className="text-center">
                    <Amount value={x.totalValue} decimalPlace={2} />
                  </td>
                  <td className="text-center">
                    <Amount value={x.grnValue} decimalPlace={2} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </AppCard>
  );
};

export default GrnViewPoDetails;
