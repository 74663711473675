import { AuthService, CommonService, PoService } from "@sk/services";
import { AutoCompleteInput, EntitySearchInput, SelectInput } from "@sk/uis";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

const warehouseOptions = AuthService.getLoggedInEmpWh().map((x) => {
  return { label: x.name, value: x._id };
});

const defaultFormValues = {
  warehouse: AuthService.getDefaultWhId(),
  vendor: [],
  po: [],
};

const GrnSelectPoForm = ({ callback, data }) => {
  const { register, control, getValues, setValue } = useForm({
    defaultValues: defaultFormValues,
  });

  const onVendorSelect = useCallback(
    (chngFn) => (val) => {
      chngFn(val);
      setValue("po", []);
      callback({ formData: getValues(), type: "vendor" });
    },
    [callback, getValues, setValue]
  );

  const onPoIdChange = useCallback(
    (chngFn) => (val) => {
      chngFn(val);
      callback({ formData: getValues(), type: "po" });
    },
    [callback, getValues]
  );

  const onWarehouseChange = useCallback(() => {
    setValue("vendor", []);
    setValue("po", []);
    callback({ formData: getValues(), type: "warehouse" });
  }, [callback, getValues, setValue]);

  const onPoIdSearch = useCallback(async (val, callback) => {
    let params = {
      page: 1,
      count: 10,
      select: "_id,name,contact,whId",
      filter: {
        status: { $in: ["Open", "Partially Inwarded"] },
      },
    };

    const search = val.trim();
    const searchString = CommonService.sanitizeRegex(search);
    const searchRegex = { $regex: searchString, $options: "gi" };
    if (search) {
      params.filter._id = searchRegex;
    }

    const r = await PoService.getList(params);

    callback(
      (r.resp || []).map((x) => ({
        label: x._id,
        value: x,
      }))
    );
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-3">
          <SelectInput
            options={warehouseOptions}
            name="warehouse"
            label="Warehouse"
            register={register}
            callback={onWarehouseChange}
          />
        </div>

        <div className="col-3">
          <Controller
            control={control}
            name="vendor"
            key="vendor"
            render={({ field: { onChange, value } }) => (
              <EntitySearchInput
                type="vendor"
                label="Search for Vendor"
                name="masterBrand"
                placeholder="Search by  Id/Name"
                value={value}
                isMandatory={true}
                callback={onVendorSelect(onChange)}
                uid="vendor"
                emptyLabel="vendor"
              />
            )}
          />
        </div>
        <div className="col-3">
          <Controller
            control={control}
            name="po"
            render={({ field: { onChange, value } }) => (
              <AutoCompleteInput
                label="Search For PO "
                placeholder="Search By PO ID "
                value={value}
                onSearch={onPoIdSearch}
                callback={onPoIdChange(onChange)}
                uid="product-list-search"
                isMultiple={false}
                emptyLabel="No PO Found"
                gap={0}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default GrnSelectPoForm;
