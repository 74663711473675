import React from "react";
import VendorDetails from "./VendorDetails";
import WarehouseDetails from "./WarehouseDetails";

const Overview = ({ vendor = {}, warehouse = {} }) => {
  return (
    <div className="row">
      <div className="col-6">
        <VendorDetails vendor={vendor} />
      </div>
      <div className="col-6">
        <WarehouseDetails warehouse={warehouse} />
      </div>
    </div>
  );
};

export default Overview;
