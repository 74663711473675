import { AuthService } from "@sk/services";

export const defaultFormData = {
  search: "",
  wh: AuthService.getDefaultWhId(),
  status: "",
  dateRange: [],
  category: [],
  brand: [],
  product: [],
};

export const filterLabelsObj = {
  vendor: {
    label: "Vendor",
    valuePath: "[0].value.name",
    canRemove: true,
    resetVal: "",
  },
  brand: {
    label: "Brand",
    valuePath: "[0].value.name",
    canRemove: true,
    resetVal: [],
  },
  category: {
    label: "Category",
    valuePath: "[0].value.name",
    canRemove: true,
    resetVal: [],
  },
  product: {
    label: "Product",
    valuePath: "[0].value.name",
    canRemove: true,
    resetVal: [],
  },
  dateRange: {
    label: "Inwarded On",
    type: "dateRange",
    canRemove: true,
    resetVal: [],
  },
  type: {
    label: "PO Type",
    canRemove: true,
    resetVal: "",
  },
  lockedPo: {
    label: "Locked PO",
    canRemove: true,
    resetVal: "",
  },
  wh: {
    label: "Warehouse",
    canRemove: true,
    resetVal: "",
  },
  status: {
    label: "Status",
    canRemove: true,
    resetVal: "",
  },
};
