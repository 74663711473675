import { CommonService, PoService } from "@sk/services";
import {
  AppliedFilterLabel,
  PageInfo,
  PaginationSummary,
  SummaryCard,
  TextInput,
} from "@sk/uis";

import { useCallback, useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";

import { set } from "date-fns";

import { debounce, merge } from "lodash";

import Table from "./components/Table";

import { produce } from "immer";

import listView from "../constantService";

import AdvFilterModal from "./modals/AdvFilterModal";

import PendingPaymentModal from "../modals/PendingPaymentModal";

const formLabels = listView.formLabels;

const defaultPaginationOpt = listView.pagination;

const defaultFormData = listView.filterFormData;

const breadcrumbs = listView.breadcrumb;

const defaultSummaryData = listView.summaryData;

// To Get The Pos Po List Data
const getData = async (params) => {
  const r = await PoService.getPosPo(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        return x;
      })
    : [];
};

// To Get Count
const getCount = async (params) => {
  try {
    const r = await PoService.getPosPoCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

// Preparing Filter Params
const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      $or: [],
    },
  };

  let search = filter?.search?.trim();
  const franchise = filter?.franchise?.trim();

  let searchRegex = { $regex: search, $options: "gi" };
  const franchiseRegex = { $regex: franchise, $options: "gi" };

  if (search) {
    let filter = [
      { _id: searchRegex },
      { "vendorDetails.name": searchRegex },
      { "vendorDetails.id": searchRegex },
    ];
    if (franchise) {
      p.filter.$or.push(...filter);
    } else {
      p.filter.$or = filter;
    }
  }

  if (franchise) {
    let filter = [
      { "franchise.name": franchiseRegex },
      { "franchise.id": franchiseRegex },
    ];
    if (search) {
      p.filter.$or.push(...filter);
    } else {
      p.filter.$or = filter;
    }
  }

  let status = filter.status;

  p.filter.status = status;

  if (filter?.isBillToStore) {
    p.filter.isBillToStore = filter.isBillToStore == "Store" ? true : false;
  }
  if (filter?.deal?.length) {
    p.filter["productList.dealId"] = filter.deal[0].value._id;
  }

  if (filter?.category?.length) {
    p.filter["productList.category.id"] = filter.category[0].value._id;
  }

  if (filter?.brand?.length) {
    p.filter["productList.brand.id"] = filter.brand[0].value._id;
  }
  let invoiceNo = filter?.invoiceNo?.trim();
  if (invoiceNo) {
    p.filter["invoiceDetails.refno"] = invoiceNo;
  }

  let paymentNo = filter?.paymentNo?.trim();
  if (paymentNo) {
    p.filter["paymentSettlement.refNo"] = paymentNo;
  }

  if (filter.createdAt && filter.createdAt.length == 2) {
    p.filter.createdAt = {
      $gte: set(filter?.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter?.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (!p.filter.status) {
    delete p.filter.status;
  }
  if (!p.filter.$or.length) {
    delete p.filter.$or;
  }
  return p;
};

const defaultSortOpt = { key: "lastUpdated", value: "desc" };

const ListPosPo = () => {
  // Navigation Object For Routing
  const router = useNavigate();

  const { register, getValues } = useForm();

  const [data, setData] = useState([]);

  // loading state for List data
  const [loadingData, setLoadingData] = useState(true);

  // loading State for total count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  const [showPendingPaymentModal, setShowPendingPaymentModal] = useState(false);

  const [summaryCard, setSummaryCard] = useState([...listView.summaryData]);

  const [filterLabels, setFilterLabels] = useState([]);

  const filterDataRef = useRef({ ...defaultFormData });

  const advFilterRef = useRef({ ...defaultFormData });

  const paginationRef = useRef({ ...defaultPaginationOpt });

  const pendingPaymentModalRef = useRef({ poDetails: {}, index: -1 });

  // To Store Sort Ref
  const sortRef = useRef({ ...defaultSortOpt });

  useEffect(() => {
    init();
  }, [init]);

  const applyFilter = useCallback(
    async (isSummaryRequired = false) => {
      paginationRef.current = { ...defaultPaginationOpt };

      loadList();

      if (isSummaryRequired) {
        loadSummary();
      }

      prepareFilterLabels();

      loadCount();
    },
    [loadList, loadCount, prepareFilterLabels, loadSummary]
  );

  const loadCount = useCallback(async () => {
    // for total records
    setLoadingTotalRecords(true);

    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;

    setLoadingTotalRecords(false);
  }, [getFilterParams]);

  // Fetching List Data
  const loadList = useCallback(async () => {
    setLoadingData(true);

    const p = getFilterParams();
    const d = await getData(p);

    // To Attach Additional Data
    // let tmp = [];
    // setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
    //   tmp.push(x);
    //   if (tmp.length == listView.additionalTableDataConfig.length) {
    //     setData([...attachAllData(d, tmp)]);
    //   }
    // });

    setData(d);
    setLoadingData(false);
  }, [getFilterParams]);

  // Preparing Filter Params
  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  }, []);

  const loadSummary = useCallback(async () => {
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );

    const filterParams = getFilterParams();
    delete filterParams.page;
    delete filterParams.count;

    let promises = [];
    summaryCard.forEach((e) => {
      promises.push(getCount(merge({}, filterParams, { filter: e.filter })));
    });
    const r = await Promise.all(promises);
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k].count || 0;
        });
      })
    );
  }, [getFilterParams, summaryCard]);

  // Init
  const init = useCallback(async () => {
    applyFilter(true);
  }, [applyFilter]);

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const onSearch = useCallback(
    debounce(() => {
      filterDataRef.current = { ...filterDataRef.current, ...getValues() };
      applyFilter();
    }, 700),
    []
  );

  const openAdvanceFilterModal = useCallback(() => {
    setShowAdvFilterModal(true);
  }, []);

  const advFilterCb = useCallback(
    (payload) => {
      if (payload.status == "submitted" || payload.status == "reset") {
        advFilterRef.current = { ...payload.data };
        filterDataRef.current = {
          ...filterDataRef.current,
          ...advFilterRef.current,
        };

        applyFilter();
      }
      setShowAdvFilterModal(false);
    },
    [applyFilter]
  );

  const prepareFilterLabels = useCallback(() => {
    const v = { ...advFilterRef.current };

    const l = CommonService.prepareAppliedFilterLabels(formLabels, v);
    setFilterLabels(l);
  }, []);

  const onSummaryCardClick = useCallback(
    (data) => {
      if (!data?.isClickable) {
        return;
      }
      advFilterRef.current.status = data.filter?.status || "";
      filterDataRef.current = {
        ...filterDataRef.current,
        ...advFilterRef.current,
      };
      applyFilter();
    },
    [applyFilter]
  );

  const openPendingPaymentModal = useCallback((x, index) => {
    pendingPaymentModalRef.current.poDetails = x;
    pendingPaymentModalRef.current.index = index;
    setShowPendingPaymentModal(true);
  }, []);

  const pendingPaymentModalCb = useCallback(async (payload) => {
    if (payload.action == "submit") {
      let index = pendingPaymentModalRef.current.index;

      setData(
        produce((draft) => {
          draft[index].status = "Closed";
          draft[index]._statusLbl = "Closed";
          draft[index].paymentSettlement = payload.data.paymentSettlement;
        })
      );
    }
    pendingPaymentModalRef.current.poDetails = {};
    pendingPaymentModalRef.current.index = -1;

    setShowPendingPaymentModal(false);
  }, []);

  return (
    <>
      <PageInfo
        title="POS Third party PO"
        breadcrumbs={breadcrumbs}
        navigate={router}
      />

      {/* summary */}
      <div className="row align-self-center mb-2">
        {summaryCard.map((e, k) => (
          <div className="col-2" key={k}>
            <SummaryCard
              value={e.value}
              title={e.label}
              loading={e.loading}
              valueColor={e.color}
              img={e.img}
              template={3}
              isClickable={e.isClickable}
              onSummaryCardClick={onSummaryCardClick}
              data={e}
              info={e.info}
            />
          </div>
        ))}
      </div>

      <div className="row">
        <div className="col-4">
          <TextInput
            register={register}
            placeholder="Search by PO ID/Vendor Name /ID "
            isMandatory={false}
            name="search"
            callback={onSearch}
          />
        </div>

        <div className="col-4">
          <TextInput
            register={register}
            placeholder="Search By Smart Store Name / ID  "
            isMandatory={false}
            name="franchise"
            callback={onSearch}
          />
        </div>

        <div className="col-auto">
          <button
            className="btn app-filter-btn"
            type="button"
            onClick={openAdvanceFilterModal}
          >
            <i className="bi bi-funnel"></i> FILTER
          </button>
        </div>
      </div>

      {filterLabels.length > 0 ? (
        <div className="mb-3">
          <AppliedFilterLabel labels={filterLabels} />
        </div>
      ) : null}

      <PaginationSummary
        paginationConfig={paginationRef.current}
        loadingTotalRecords={loadingTotalRecords}
      />
      <Table
        data={data}
        sort={sortRef.current}
        sortCb={sortCb}
        openPendingPaymentModal={openPendingPaymentModal}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />

      <AdvFilterModal
        callback={advFilterCb}
        filterData={advFilterRef.current}
        show={showAdvFilterModal}
      />

      <PendingPaymentModal
        show={showPendingPaymentModal}
        callback={pendingPaymentModalCb}
        poDetails={pendingPaymentModalRef.current.poDetails}
      />
    </>
  );
};

export default ListPosPo;
