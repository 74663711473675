import activeImg from "../assets/imgs/active.svg";
import notLinkedImg from "../assets/imgs/not-linked.svg";
import totalImg from "../assets/imgs/total.svg";

const listView = {
  additionalTableDataConfig: [
    {
      key: "whId",
      api: "franchise",
      loadingKey: "whLoading",
      dataKey: "_wh",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],

  summaryData: [
    {
      label: "Total PO",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Draft",
      value: 0,
      loading: true,
      filter: { status: "Draft" },
      color: "success",
      img: activeImg,
    },
    {
      label: "Submitted",
      value: 0,
      loading: true,
      filter: { status: "Submitted" },
      color: "primary",
      img: activeImg,
    },
    {
      label: "Open",
      value: 0,
      loading: true,
      color: "danger",
      img: notLinkedImg,
      filter: { status: "Open" },
    },
    {
      label: "Partially Inwarded",
      value: 0,
      loading: true,
      color: "success",
      img: activeImg,
      filter: { status: "Partially Inwarded" },
    },
    {
      label: "Closed",
      value: 0,
      loading: true,
      filter: { status: "Closed" },
      color: "danger",
      img: notLinkedImg,
    },
    {
      label: "Rejected",
      value: 0,
      loading: true,
      filter: { status: "Rejected" },
      color: "danger",
      img: notLinkedImg,
    },
    {
      label: "Deleted",
      value: 0,
      loading: true,
      filter: { status: "Deleted" },
      color: "danger",
      img: notLinkedImg,
    },
    {
      label: "Cancelled",
      value: 0,
      loading: true,
      filter: { status: "Cancelled" },
      color: "danger",
      img: notLinkedImg,
    },
  ],

  formLabels: {
    vendor: {
      label: "Vendor",
      valuePath: "[0].value.name",
      canRemove: true,
      resetVal: "",
    },
    brand: {
      label: "Brand",
      valuePath: "[0].value.name",
      canRemove: true,
      resetVal: [],
    },
    category: {
      label: "Category",
      valuePath: "[0].value.name",
      canRemove: true,
      resetVal: [],
    },
    product: {
      label: "Product",
      valuePath: "[0].value.name",
      canRemove: true,
      resetVal: [],
    },

    user: {
      label: "User",
      valuePath: "[0].value.name",
      canRemove: true,
      resetVal: [],
    },

    createdAt: {
      label: "Created On",
      type: "dateRange",
      canRemove: true,
      resetVal: [],
    },
    expectedAt: {
      label: "Expected On",
      type: "date",
      canRemove: true,
      resetVal: "",
    },
    type: {
      label: "PO Type",
      canRemove: true,
      resetVal: "",
    },
    lockedPo: {
      label: "Locked PO",
      canRemove: true,
      resetVal: "",
    },
    wh: {
      label: "Warehouse",
      canRemove: true,
      resetVal: [],
    },
    state: {
      label: "State",
      canRemove: true,
      resetVal: [],
    },
    status: {
      label: "Status",
      canRemove: true,
      resetVal: [],
    },
  },
};

export default listView;
