import React from "react";

const WarehouseDetails = ({ warehouse = {} }) => {
  return (
    <div>
      <div className="fs-val-lg fw-semibold mb-1">Warehouse Details</div>
      <div className="fs-val-md text-primary mb-1">
        {warehouse.name} ({warehouse.whId})
      </div>
      <div className="mb-1 text-muted">
        {warehouse.address?.door_no} {warehouse.address?.street},{" "}
        {warehouse.district} - {warehouse.pincode}
      </div>
      <div className="fw-semibold">
        <div className="d-inline-block me-2">
          <i className="bi bi-telephone-fill"></i>
          <span className="px-1">{warehouse?.mobile}</span>
        </div>

        {warehouse?.email ? (
          <div className="d-inline-block me-2">
            <i className="bi bi-envelope"></i>
            <span className="px-1">{warehouse?.email}</span>
          </div>
        ) : null}

        <div className="d-inline-block me-2">
          <span>GST No.: </span>
          <span className="px-1">{warehouse?.gstNo || "N/A"}</span>
        </div>
      </div>
    </div>
  );
};

export default WarehouseDetails;
