import { NavService } from "@sk/services";
import { Amount, AppCard, BtnLink, KeyVal } from "@sk/uis";

const GrnViewBasicInfo = ({ details }) => {
  const viewVendor = () => {
    NavService.openInNewTab("/vendor/view", {
      id: details.vendor?.vendorId,
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-6">
          <AppCard title="Vendor Details">
            <KeyVal label="Vendor">
              :{" "}
              <BtnLink className="fs-val-md" onClick={viewVendor}>
                {details.vendor?.name} ({details.vendor.vendorId})
              </BtnLink>
            </KeyVal>

            <KeyVal label="Address">
              <div className="d-flex gap-1">
                <div>: </div>
                <div>
                  {details.vendor?.address?.city},{" "}
                  {details.vendor?.address?.district},{" "}
                  {details.vendor?.address?.state} -{" "}
                  {details.vendor?.address?.pincode}
                </div>
              </div>
            </KeyVal>
            <KeyVal label="GST Number">
              : {details.vendor?.gstNo || "N/A"}
            </KeyVal>
          </AppCard>
        </div>
        <div className="col-6">
          <AppCard title="Warehouse Details">
            <KeyVal label="Warehouse">: {details.warehouse?.name}</KeyVal>

            <KeyVal label="Address">
              <div className="d-flex gap-1">
                <div>: </div>
                <div>
                  {details.warehouse?.town}, {details.warehouse?.district},{" "}
                  {details.warehouse?.state}
                </div>
              </div>
            </KeyVal>
            <KeyVal label="GST Number">
              : {details.warehouse?.gstNo || "N/A"}
            </KeyVal>
          </AppCard>
        </div>

        <div className="col-6">
          {/* Driver details */}
          <AppCard title="Driver Details">
            <KeyVal label="LR Number">: {details?.lrNumber || "N/A"}</KeyVal>
            <KeyVal label="Vehicle Number">
              : {details?.vehicleNo || "N/A"}
            </KeyVal>
            <KeyVal label="Shipping Cost">
              : <Amount value={details?.shippingCost} decimalPlace={2} />
            </KeyVal>
            <KeyVal label="Remarks">: {details?.remarks || "N/A"}</KeyVal>
          </AppCard>
        </div>
      </div>
    </>
  );
};

export default GrnViewBasicInfo;
