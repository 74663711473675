import classNames from "classnames";
import { appConfigs } from "@sk/services";
import {
  Amount,
  DateFormatter,
  ImgPreviewModal,
  NoDataFound,
  TableHeader,
} from "@sk/uis";
import { groupBy } from "lodash";
import { Fragment, memo, useCallback, useMemo, useRef, useState } from "react";
import PaymentDetailsBankModal from "../modal/PaymentDetailsBankModal";

const headers = [
  { label: "Sl.no", isCentered: true },
  { label: "Payment Ref ID", isCentered: true },
  { label: "Invoice No" },
  { label: "Payment Mode" },
  { label: "Payment Date", isCentered: true },
  { label: "Paid Amount", isCentered: true },
  // { label: "Deals" },
  { label: "Remarks" },
  { label: "Attachment" },
];

const statusColors = {
  Approved: "text-success",
  Rejected: "text-danger",
  "Partially Received": "text-primary",
  Received: "text-primary",
};

const PaymentDetails = ({ data, invoiceCb, productList, poDetails }) => {
  const [showImgPreviewModal, setShowImgPreviewModal] = useState(false);

  const [showBankDetailsModal, setShowBankModal] = useState(false);

  const imgModalRef = useRef({
    imgIndex: -1,
    invoiceIndex: -1,
  });

  const paymentDetailsBankModal = useRef({ paymentDetails: {} });

  const paymentData = useMemo(
    () =>
      data.map((x) => {
        // updating asset
        x.assets = x.proofs.map((asset) => {
          return {
            id: asset,
            isDownload: true,
          };
        });

        return x;
      }),
    [data]
  );

  const invoiceAmount = useMemo(() => {
    return (poDetails.invoiceDetails || []).reduce(
      (acc, cur) => acc + cur.amount,
      0
    );
  }, [poDetails]);

  const statusSummary = useMemo(() => {
    let groupedData = groupBy(productList, "status");
    return [
      ...Object.keys(groupedData).map((key) => {
        return {
          key: key,
          total: groupedData[key].length,
          color: statusColors[key] || "text-primary",
        };
      }),
    ];
  }, [productList]);

  const handleCloseImgModal = useCallback(() => {
    imgModalRef.current = {
      imgIndex: -1,
      invoiceIndex: -1,
    };
    setShowImgPreviewModal(false);
  }, []);

  const openImgPreviewModal = useCallback((imgIndex, invoiceIndex) => {
    imgModalRef.current = { imgIndex, invoiceIndex };
    setShowImgPreviewModal(true);
  }, []);

  const openBankModal = useCallback((x) => {
    paymentDetailsBankModal.current.paymentDetails = x;
    setShowBankModal(true);
  }, []);

  const closeBankModal = useCallback(() => {
    paymentDetailsBankModal.current.paymentDetails = {};
    setShowBankModal(false);
  }, []);

  return (
    <>
      <div className="p-3">
        <div className="row mb-2">
          <div className="col-auto fs-val-md me-3">
            <span className="fw-bold "> Total Deals : </span>
            <span className="fw-bold ms-1">{productList.length}</span>
          </div>

          <div className="col-auto fs-val-md me-3">
            <span className="fw-bold"> Total PO Value : </span>
            <span className="fw-bold ms-1">
              <Amount value={poDetails.poSummary.amount} decimalPlace={2} />
            </span>
          </div>
          <div className="col-auto fs-val-md me-3">
            <span className="fw-bold"> Inwarded Stock Value : </span>
            <span className="fw-bold ms-1">
              <Amount value={invoiceAmount} decimalPlace={2} />
            </span>
          </div>
          {poDetails?.poSummary?.pendingAmount ? (
            <div className="col-auto fs-val-md me-3">
              <span className="fw-bold"> Pending Payment : </span>
              <span className="fw-bold ms-1">
                <Amount
                  value={poDetails?.poSummary?.pendingAmount || 0}
                  decimalPlace={2}
                />
              </span>
            </div>
          ) : null}

          {statusSummary.map((x) => (
            <div className="me-3 col-auto fs-val-md" key={x.key}>
              <span className={classNames(x.color, "fw-bold")}>{x.key} :</span>
              <span className="fw-bold ms-1">{x.total}</span>
            </div>
          ))}
        </div>

        <table className="table table-bordered">
          <TableHeader data={headers} />
          <tbody>
            {!paymentData.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound> No payment data found </NoDataFound>
                </td>
              </tr>
            ) : null}
            {paymentData.map((x, index) => (
              <tr key={index} className="fs-val-md">
                <td className="text-center">{index + 1}</td>
                <td className="text-center">{x.refNo}</td>
                <td>
                  <div
                    className="btn btn-link"
                    onClick={() => invoiceCb(x.invoiceNo)}
                    tabIndex={-1}
                    role="button"
                  >
                    {x.invoiceNo}
                  </div>
                </td>
                <td>{x.paymentMethod}</td>

                <td className="text-center">
                  <DateFormatter date={x.paymentDate} format="dd MMM yyyy" />
                </td>

                <td className="text-center text-danger fw-semibold">
                  <Amount value={x.invoiceAmount} decimalPlace={2} />
                </td>

                <td> {x.remarks || "N/A"} </td>
                <td>
                  {x.assets.map((asset, i) => (
                    <Fragment key={asset.id}>
                      <button
                        className="btn btn-link btn-sm me-2 fs-val-sm"
                        onClick={() => openImgPreviewModal(i, index)}
                      >
                        Attachment {i + 1}
                      </button>
                      {asset.isDownload && (
                        <a
                          href={appConfigs.ASSET + "/" + asset.id}
                          className="btn btn-link btn-sm fs-val-sm"
                        >
                          Download
                        </a>
                      )}
                    </Fragment>
                  ))}

                  {poDetails.isBillToStore == false &&
                    poDetails.status == "Closed" && (
                      <button
                        className="btn btn-link btn-sm fs-val-sm"
                        onClick={() => openBankModal(x)}
                      >
                        Payment Details
                      </button>
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <ImgPreviewModal
          show={showImgPreviewModal}
          handleClose={handleCloseImgModal}
          assets={paymentData[imgModalRef.current.invoiceIndex]?.assets || []}
          imageIndex={imgModalRef.current.imgIndex}
        />
      </div>

      <PaymentDetailsBankModal
        show={showBankDetailsModal}
        closeModal={closeBankModal}
        data={paymentDetailsBankModal.current.paymentDetails}
      />
    </>
  );
};

export default memo(PaymentDetails);
