import activeImg from "../../assets/imgs/active.svg";
import notLinkedImg from "../../assets/imgs/not-linked.svg";
import totalImg from "../../assets/imgs/total.svg";

const listView = {
  formLabels: {
    deal: {
      label: "Deal",
      valuePath: "[0].value.name",
    },

    category: {
      label: "Category",
      valuePath: "[0].value.name",
    },

    brand: {
      Brand: "Category",
      valuePath: "[0].value.name",
    },

    createdAt: {
      label: "Created On",
      type: "dateRange",
    },

    status: {
      label: "Status",
    },
    paymentNo: {
      label: "Payment Ref No.",
    },
    invoiceNo: {
      label: "Invoice Ref No.",
    },
    isBillToStore: {
      label: "Bill To",
    },
  },
  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  filterFormData: {
    createdAt: [],
    status: "",
    deal: [],
    category: [],
    brand: [],
    paymentNo: "",
    invoiceNo: "",
    isBillToStore: "",
  },
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "POS Third party PO",
    },
  ],
  summaryData: [
    {
      label: "Total",
      isClickable: true,
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
      info: { content: "Overall PO count including all status" },
    },
    {
      label: "Draft",
      isClickable: true,
      value: 0,
      loading: true,
      filter: { status: "Draft" },
      color: "success",
      img: activeImg,
      info: { content: "PO creation is in Processing" },
    },
    {
      label: "Approval Pending",
      isClickable: true,
      value: 0,
      loading: true,
      filter: { status: "Approval Pending" },
      color: "primary",
      img: activeImg,
      info: { content: "PO has been created, awaiting for Approval" },
    },
    {
      label: "Approved",
      isClickable: true,
      value: 0,
      loading: true,
      color: "success",
      img: activeImg,
      filter: { status: "Approved" },
      info: {
        content: "PO has been reviewed and waiting to receive the stock",
      },
    },
    {
      label: "Partially Received",
      isClickable: true,
      value: 0,
      loading: true,
      color: "danger",
      img: notLinkedImg,
      filter: { status: "Partially Received" },
      info: {
        content:
          "Supplier has delivered only a portion of the items, which buyer has Ordered",
      },
    },
    // {
    //   label: "Received",
    //   isClickable: true,
    //   value: 0,
    //   loading: true,
    //   color: "danger",
    //   img: notLinkedImg,
    //   filter: { status: "Received" },
    //   info: { content: "Products are received, PO has been closed" },
    // },
    {
      label: "Payment Pending",
      isClickable: true,
      value: 0,
      loading: true,
      filter: { status: "Received - Payment Pending" },
      color: "danger",
      img: notLinkedImg,
      info: {
        content:
          "PO has been received but the Payment has not been completed yet",
      },
    },

    {
      label: "Closed",
      isClickable: true,
      value: 0,
      loading: true,
      color: "danger",
      img: notLinkedImg,
      filter: { status: "Closed" },
      info: { content: "Products are received, PO has been closed" },
    },

    {
      label: "Rejected",
      isClickable: true,
      value: 0,
      loading: true,
      filter: { status: "Rejected" },
      color: "danger",
      img: notLinkedImg,
      info: { content: "PO has been rejected" },
    },
  ],
};

export default listView;
