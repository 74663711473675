import { useFetchUrlQueryString } from "@sk/hooks";
import {
  CommonService,
  FranchiseService,
  NavService,
  OmsService,
  PoService,
} from "@sk/services";

import {
  Alert,
  AppCard,
  BusyLoader,
  NoDataFound,
  PageInfo,
  PageLoader,
  Toaster,
  Amount,
} from "@sk/uis";

import { PoItemCard } from "@sk/features";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { produce } from "immer";

import PosPoBasicInfo from "../components/PosPoBasicInfo";

import PoItemRemarksModal from "../modals/PoItemRemarksModal";

import classNames from "classnames";
import { cloneDeep, groupBy } from "lodash";
import styles from "../PosPo.module.scss";
import BasicDetails from "./components/BasicDetails";
import BillToShipToDetails from "./components/BillToShipToDetails";

// BreadCrumb Config
const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "POS Third party PO",
    link: "/purchase-order/pos/list",
  },
  {
    name: "POS-PO Verification",
  },
];

const style = {
  tableHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  tableFooterStyle: {
    backgroundColor: "#e4edff",
  },
  canvasStyle: {
    width: "90%",
  },
};

const statusColors = {
  Approved: "text-success",
  Rejected: "text-danger",
  "Partially Received": "text-primary",
  Received: "text-primary",
};

const ApprovePosPo = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [display, setDisplay] = useState("loading");

  const [details, setDetails] = useState({});

  const [busyLoader, setBusyLoader] = useState(false);

  const [remarks, setRemarks] = useState("");

  const [paymentMode, setPaymentMode] = useState("");

  // to Fetch QueryString
  const query = useFetchUrlQueryString(searchParams);

  const [showItemRemarksModal, setShowItemRemarksModal] = useState(false);

  const itemRemarksModalRef = useRef({});

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(() => {
    if (query.id) {
      loadPosPoDetails(query.id);
    } else {
      setDisplay("noDataFound");
    }
  }, [loadPosPoDetails, query.id]);

  // get Product Details
  const loadPosPoDetails = useCallback(async () => {
    setDisplay("loading");
    const r = await PoService.getPosPo({
      filter: {
        _id: query.id,
      },
    });
    const d = Array.isArray(r.resp) ? r.resp : [];

    let data = d[0];

    if (data._id && data.status == "Approval Pending") {
      const r = await attachPrdSalesAndPurchaseDetails(data);
      data.productList = r.map((x) => PoService.formatPosPoProductResp(x));

      setDisplay("details");
    } else {
      setDisplay("noDataFound");
    }

    setDetails(data);

    // let tmp = [];
    // setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
    //   tmp.push(x);
    //   if (tmp.length == listView.additionalTableDataConfig.length) {
    //     setData([...attachAllData(d, tmp)]);
    //   }
    // });
  }, [query.id, attachPrdSalesAndPurchaseDetails]);

  const attachPrdSalesAndPurchaseDetails = useCallback(async (data) => {
    const dealIds = data.productList.map((x) => x.dealId);
    let productList = data.productList;
    const p = {
      franchiseId: data.franchise.id,
      groupbycondName: "deal",
      groupbycond: "deal",
      filter: {
        _id: data.franchise.id,
      },
      dealFilter: {
        _id: { $in: dealIds },
      },
    };

    const r = await FranchiseService.getFranchiseInventorySummary(p);

    const d = Array.isArray(r.resp) ? r.resp : [];

    productList.forEach((p, i) => {
      let prd = d.find((x) => p.dealId == x._id);
      p.openPosPoQty = prd?.openPosPoQty || 0;
      p.currentStock = prd?.qty || 0;
      p.purchasedIn = {
        fifteenDays:
          (prd?.thirdPartyPosPurchaseData?.fifteendayQty || 0) +
          (prd?.purchaseData?.fifteendayQty || 0),
        thirtyDays:
          (prd?.thirdPartyPosPurchaseData?.thirtydaysQty || 0) +
          (prd?.purchaseData?.thirtydaysQty || 0),
        sixtyDays:
          (prd?.thirdPartyPosPurchaseData?.sixtydaysQty || 0) +
          (prd?.purchaseData?.sixtydaysQty || 0),
      };

      p.soldIn = {
        thirtyDays: prd?.salesData?.thirtyday?.qty || 0,
        sixtyDays: prd?.salesData?.sixtyday?.qty || 0,
        ninetyDays: prd?.salesData?.ninetyday?.qty || 0,
      };
    });

    return productList;
  }, []);

  const preparePayload = (enteredRemarks, rejectAllDeals, status) => {
    const p = {
      ...details,
      status: status,
      remarks: enteredRemarks,
      paymentHandledBy: "Head Office", // paymentMode,
    };

    p.productList = (cloneDeep(details?.productList) || []).map((x) => {
      let val = x.totalValue;
      return {
        ...x.resp,
        status: rejectAllDeals || status == "Rejected" ? "Rejected" : x.status,
        requestedQuantity: Number(x.qty),
        shelfLife: x?.shelfLife ?? "",
        manufactureDate: x?.manufactureDate ?? "",
        discount: x.finalMargin * 1,
        remarks: x.rejectedRemarks || "",
        isDamaged: x.isDamaged,
        damagedQty: x.damagedQty,
        damagedReason: x.damagedReason,
        expiresOn: x.expiresOn ?? "",
        price: x.purchasePrice,
        additionalDiscount: x.additionalMargin,
        totalValue: val,
      };
    });

    p.requestProductList = (cloneDeep(details.requestProductList) || []).map(
      (reqProd) => {
        const curProduct = (cloneDeep(p?.productList) || []).find(
          (x) => x.pid == reqProd.pid
        );
        return {
          ...reqProd,
          requestedQuantity: curProduct.requestedQuantity,
          discount: curProduct.discount,
          price: curProduct?.price,
          additionalDiscount: curProduct?.additionalDiscount,
          displayUnitType: curProduct?._displayUnitType,
          totalValue: curProduct.totalValue,
        };
      }
    );
    return p;
  };

  const updatePosPoStatus = async (status) => {
    const enteredRemarks = remarks.trim();

    let rejectAllDeals = false;

    let approvedDeals = details.productList.filter(
      (x) => x.status == "Approved"
    );

    let isDealsApprovedOrRejected = details.productList.every(
      (x) => x.status == "Approved" || x.status == "Rejected"
    );

    let errorMsg = "";

    // If Remarks are not there
    if (status == "Approved" && !isDealsApprovedOrRejected) {
      errorMsg = "All the above products either be approved or rejected";
    }

    if (status == "Rejected" && approvedDeals.length) {
      // preparing confirmation text if some deals are Approved
      rejectAllDeals = true;
    }

    if (status == "Rejected" && !enteredRemarks) {
      errorMsg = "Please enter remarks before rejecting ";
    }

    if (errorMsg) {
      Toaster.error(errorMsg);
      return;
    }

    if (rejectAllDeals) {
      // Confirmation
      const c = await Alert.confirm({
        title: "Please confirm",
        text: "Please check the approved deals will also be rejected, do you want to proceed",
      });

      if (!c.isConfirmed) {
        return;
      }
    }

    setBusyLoader(true);
    const p = preparePayload(enteredRemarks, rejectAllDeals, status);
    const r = await OmsService.updatePosPoStatus(details._id, p);

    setBusyLoader(false);

    if (r.statusCode != 200) {
      Toaster.error(r.resp.message || `Unable to ${status} ,please try again`);
      return;
    }

    Toaster.success(`PO ${status} successfully`);
    navToPosPoViewPage();
  };

  const onQtyChange = useCallback((val, index) => {
    setDetails(
      produce((draft) => {
        let v = val;
        let curr = draft.productList[index];
        if (v <= 0) {
          v = "";
        }

        curr.qty = v;
        if (curr.sellInLoose) {
          curr.totalValue = CommonService.calculatePriceOnUom(
            curr.purchasePrice,
            v * 1,
            {
              packsize: curr.packsize,
              uom: curr.uom,
            },
            curr.unitType
          ).total;
        } else {
          curr.totalValue = v * 1 * curr.purchasePrice;
        }
      })
    );
  }, []);

  const onSchemeMarginChange = useCallback((val, index) => {
    let v = val;

    if (v && !isNaN(v)) {
      v = CommonService.roundedByDecimalPlace(v, 2);
    }

    if (val >= 100) {
      v = 100;
    }

    setDetails(
      produce((draft) => {
        let curr = draft.productList[index];

        let finalMargin = curr.baseMargin + v;

        curr.additionalMargin = v;

        curr.finalMargin = finalMargin;

        curr.purchasePrice = PoService.calculatePurchasePrice(
          curr.mrp,
          finalMargin
        );
        if (curr.sellInLoose) {
          curr.totalValue = CommonService.calculatePriceOnUom(
            curr.purchasePrice,
            curr.qty * 1,
            {
              packsize: curr.packsize,
              uom: curr.uom,
            },
            curr.unitType
          ).total;
        } else {
          curr.totalValue = curr.qty * 1 * curr.purchasePrice;
        }
      })
    );
  }, []);

  const approveCb = useCallback((index) => {
    setDetails(
      produce((draft) => {
        draft.productList[index].status = "Approved";
      })
    );
  }, []);

  const rejectCb = useCallback(
    (index) => {
      itemRemarksModalRef.current = {
        index,
        product: details.productList[index],
      };
      setShowItemRemarksModal(true);
    },
    [details.productList]
  );

  const itemRemarksModalCb = useCallback((r) => {
    setShowItemRemarksModal(false);
    if (r?.action == "submit") {
      const d = itemRemarksModalRef.current;
      setDetails(
        produce((draft) => {
          draft.productList[d.index].status = "Rejected";
          draft.productList[d.index].rejectedRemarks = r.remarks;
        })
      );
    }
  }, []);

  const navToPosPoViewPage = () => {
    let t = setTimeout(() => {
      NavService.replace(navigate, "/purchase-order/pos/view", {
        id: details._id,
      });
      clearTimeout(t);
    }, 2000);
  };

  const damageCb = (payload) => {
    let val = payload.value;
    let key = payload.key;
    let index = payload.index;

    if (key == "damagedQty") {
      val = CommonService.roundedByDecimalPlace(val, 0);
      if (val < 0) {
        val = "";
      }
    }

    setDetails(
      produce((draft) => {
        draft.productList[index][key] = val;
      })
    );
  };

  const statusSummary = useMemo(() => {
    let groupedData = groupBy(details.productList, "status");
    return [
      ...Object.keys(groupedData).map((key) => {
        return {
          key: key,
          total: groupedData[key].length,
          color: statusColors[key] || "text-primary",
        };
      }),
    ];
  }, [details]);

  const summary = useMemo(() => {
    return {
      totalPoValue: details?.productList?.reduce(
        (acc, cur) => acc + cur.totalValue * 1,
        0
      ),
      totalPoUnitQuantity: details.productList?.reduce((acc, cur) => {
        if (!cur.sellInLoose) {
          return acc + cur.qty * 1;
        }
        return acc;
      }, 0),

      totalPoLooseQuantity: details.productList?.reduce((acc, cur) => {
        if (cur.sellInLoose) {
          return acc + cur.qty * 1;
        }
        return acc;
      }, 0),

      looseType: details.productList?.reduce((acc, cur) => {
        if (cur.sellInLoose) {
          return cur._displayUnitType;
        }
        return acc;
      }, ""),
    };
  }, [details]);

  return (
    <>
      <PageInfo
        title={
          query.id ? `POS Third party PO #${query.id}` : "POS Third party PO"
        }
        breadcrumbs={breadcrumbs}
        navigate={navigate}
      />

      {display == "loading" && <PageLoader />}

      {display == "noDataFound" && <NoDataFound> No Data Found</NoDataFound>}

      {display == "details" && (
        <>
          <div
            className={classNames(
              "alert alert-primary border-0 text-center",
              styles.blinkText
            )}
          >
            <strong>Approval Required </strong>
            !!! Please review the PO details and take the necessary action.
          </div>

          <PosPoBasicInfo poDetails={details} />

          <div className="mb-3">
            <BasicDetails
              vendorDetails={details.vendorDetails}
              franchiseDetails={details.franchise}
            />
          </div>

          {!details.isBillToStore && (
            <div className="mb-3">
              <BillToShipToDetails
                billTo={details.billTo}
                shipTo={details.shipTo}
              />
            </div>
          )}

          <div className="fw-semibold mb-2">
            <div className="row">
              <div className="col-3">
                <span className="fs-val-lg">Deals List </span>
                <span className="fs-val-md">
                  ({details.productList.length})
                </span>
              </div>
              <div className="col-9 fs-val-sm text-end">
                "Please make a decision by approving or rejecting each product"
              </div>
            </div>
          </div>

          {details.productList.map((x, index) => (
            <PoItemCard
              key={x._id}
              data={x}
              index={index}
              approveCb={approveCb}
              rejectCb={rejectCb}
              feature="POS-PO"
              damageCb={damageCb}
              onSchemeMarginChange={onSchemeMarginChange}
              onQtyChange={onQtyChange}
            />
          ))}

          <table
            className={classNames(
              "table  table-bordered table-centered",
              styles.summary
            )}
            style={style.tableHeaderStyle}
          >
            <tbody>
              <tr>
                <td width="35%">
                  <div className={classNames(styles["summary-lbl"], "fw-bold")}>
                    Total Purchase Order Summary
                  </div>
                </td>
                <td width="20%">
                  {statusSummary.map((x) => (
                    <div className="mb-2" key={x.key}>
                      <span
                        className={classNames(
                          styles["summary-lbl"],
                          "fw-bold me-2"
                        )}
                      >
                        {x.key} :
                      </span>
                      <span
                        className={classNames(
                          styles["summary-lbl"],
                          "fw-bold text-primary"
                        )}
                      >
                        {x.total}
                      </span>
                    </div>
                  ))}
                </td>
                <td width="20%">
                  <div className="mb-2">
                    <span
                      className={classNames(
                        styles["summary-lbl"],
                        "fw-bold me-2"
                      )}
                    >
                      Total PO Unit Qty:
                    </span>
                    <span
                      className={classNames(
                        styles["summary-lbl"],
                        "fw-bold text-dark"
                      )}
                    >
                      {summary?.totalPoUnitQuantity || 0}
                    </span>
                  </div>
                  {summary.totalPoLooseQuantity ? (
                    <>
                      <div className="mb-2">
                        <span
                          className={classNames(
                            styles["summary-lbl"],
                            "fw-bold me-2"
                          )}
                        >
                          Total PO Loose Qty:
                        </span>
                        <span
                          className={classNames(
                            styles["summary-lbl"],
                            "fw-bold text-dark"
                          )}
                        >
                          {summary?.totalPoLooseQuantity} {summary.looseType}
                        </span>
                      </div>
                    </>
                  ) : null}
                </td>
                <td>
                  <div className={classNames("mb-2", styles["summary-lbl"])}>
                    <span className="fw-bold me-2">Total PO Value:</span>
                    <span className="fw-bold text-warning">
                      <Amount value={summary.totalPoValue} decimalPlace={2} />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <AppCard noShadow={true}>
            <div className="row">
              <div className="col-12">
                <div className="fw-semibold fs-val-lg mb-3">
                  Action Required
                </div>
              </div>

              <div className="col-12 mb-4">
                <div className="w-50">
                  <div className="fs-val-md">
                    Remarks <span className="text-danger">*</span>
                  </div>
                  <textarea
                    className="form-control"
                    placeholder="Enter remarks here"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                    rows={1}
                  ></textarea>
                </div>
              </div>
              <div className="col-6">
                <button
                  className="btn btn-danger"
                  onClick={() => updatePosPoStatus("Rejected")}
                >
                  Reject Purchase Order
                </button>
              </div>
              <div className="col-6 text-end">
                <button
                  className="btn btn-success"
                  onClick={() => updatePosPoStatus("Approved")}
                >
                  Approve Purchase Order
                </button>
              </div>
            </div>
          </AppCard>

          <PoItemRemarksModal
            show={showItemRemarksModal}
            product={itemRemarksModalRef.current.product}
            callback={itemRemarksModalCb}
          />

          <BusyLoader show={busyLoader} message={"Submitting, please wait"} />
        </>
      )}
    </>
  );
};

export default ApprovePosPo;
