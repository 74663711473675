import { Amount, DateFormatter, KeyVal, NoDataFound, Toaster } from "@sk/uis";
import classNames from "classnames";
import { memo, useRef } from "react";
import styles from "../selectPoStyles.module.scss";

const GrnSelectedPoCard = ({
  data,
  loading,
  onCheckboxChangeCb,
  filterData,
  isLoadMore,
}) => {
  const checkboxRef = useRef();

  const onCheckboxChange = (index, checked) => {
    if (!data[index].isLocked) {
      onCheckboxChangeCb(index, checked);
    } else {
      Toaster.error(
        "PO is Locked By HO Manager, Please Unlock PO Then Proceed" +
          data[index]._id
      );
    }
  };

  return (
    <>
      {loading ? <div>Loading...</div> : null}

      {!loading && !data.length ? (
        <div className="pb-2">
          <NoDataFound>
            {filterData.vendor?.length
              ? `No PO found for "${filterData.vendor[0].value.name}" `
              : "Please select vendor to see PO list"}{" "}
          </NoDataFound>
        </div>
      ) : null}

      {!loading && data.length ? (
        <>
          <div className="mt-2">Choose PO corresponding to the Vendor</div>

          <div className="row bg-white p-2">
            {data.map((x, i) => (
              <div
                className={classNames("col-2 mb-2", styles.poCard)}
                key={i + x._id}
              >
                {/* Checked icon */}
                {x?._checked ? (
                  <div
                    className={classNames(
                      "fs-3 text-right text-success",
                      styles.selectedCard
                    )}
                  >
                    <i
                      className={classNames(
                        "bi bi-check-circle-fill bg-white",
                        styles.selectedIcon
                      )}
                    ></i>
                  </div>
                ) : null}

                {/* Card  */}
                <div
                  className={classNames({
                    "p-3 bg-white border fs-val-sm": true,
                    "border-success border-2": x?._checked,
                  })}
                  tabIndex={-1}
                  role="button"
                  onClick={() => onCheckboxChange(i, !x?._checked)}
                >
                  {/* PO ID */}

                  <KeyVal label="PO " labelCol="col-5" contentCol="col-7">
                    <span>
                      : <span className="fw-semibold">{x._id}</span>
                    </span>
                  </KeyVal>

                  {/* PO Value */}
                  <KeyVal label="Value" labelCol="col-5" contentCol="col-7">
                    <span className="fw-semibold text-primary">
                      : <Amount value={x.poValue} decimalPlace={2} />
                    </span>
                  </KeyVal>

                  {/* QTY */}
                  <KeyVal label="Qty" labelCol="col-5" contentCol="col-7">
                    <span>
                      : <span className="fw-semibold">{x._totalPoQty}</span>
                    </span>
                  </KeyVal>

                  {/* Delivery Date */}
                  <KeyVal label="D. Date" labelCol="col-5" contentCol="col-7">
                    <span>
                      :
                      <span className="fw-semibold ps-1">
                        <DateFormatter
                          date={x.expectedAt}
                          format="dd MMM yyyy"
                        />
                      </span>
                    </span>
                  </KeyVal>

                  {/* Is Locked */}
                  <KeyVal label="PO Locked" labelCol="col-5" contentCol="col-7">
                    <span>
                      :
                      <span
                        className={classNames({
                          "text-center fw-semibold ms-1": true,
                          "text-danger": x?.isLocked,
                          "text-success": !x?.isLocked,
                        })}
                      >
                        {x?.isLocked ? "YES" : "NO"}
                      </span>
                    </span>
                  </KeyVal>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : null}
    </>
  );
};

export default memo(GrnSelectedPoCard);
