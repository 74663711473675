const BillToShipToDetails = ({ billTo, shipTo }) => {
  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row">
            {/* Franchise Details */}
            <div className="col-6 border-end">
              <div className="fw-semibold fs-val-lg mb-1">Shipping Address</div>

              <div className="fs-val-md text-primary mb-1">
                {shipTo?.name} - {shipTo?.id}
              </div>

              {/* Address */}
              <div className="mb-1 text-muted">
                {shipTo?.address?.door_no},{shipTo?.address?.street},{" "}
                {shipTo?.town},{shipTo?.district},{shipTo?.state}-
                {shipTo?.pincode ?? "N/A"}
              </div>

              <div className="fw-semibold">
                {shipTo.mobile ? (
                  <div className="d-inline-block me-2">
                    <i className="bi bi-telephone-fill"></i>
                    <span className="px-1">{shipTo?.mobile}</span>
                  </div>
                ) : null}

                {shipTo.email ? (
                  <div className="d-inline-block me-2">
                    <i className="bi bi-envelope"></i>
                    <span className="px-1">{shipTo?.email}</span>
                  </div>
                ) : null}

                <div className="d-inline-block me-2">
                  <span>GST No : </span>
                  <span className="px-1">{shipTo?.gstNo || "N/A"}</span>
                </div>
              </div>
            </div>

            {/* Vendor Details */}
            <div className="col-6 ps-4">
              <div className="fw-semibold fs-val-lg mb-1">Billing Address</div>

              <div className="fs-val-md text-primary mb-1">{billTo?.name}</div>

              {/* Address */}
              <div className=" mb-1 text-muted">
                {billTo?.address?.door_no},{billTo?.address?.street},
                {billTo?.town},{billTo?.district},{billTo?.state}-
                {billTo?.pincode}
              </div>
              <div className="fw-semibold">
                {/* Contact
                <div className="d-inline-block me-2">
                  <i className="bi bi-telephone-fill"></i>
                  <span className="px-1">{billTo?.mobile || "N/A"}</span>
                </div> */}

                {/* Email */}
                {billTo?.email ? (
                  <div className="d-inline-block me-2">
                    <i className="bi bi-envelope"></i>
                    <span className="px-1">{billTo?.email ?? "N/A"}</span>
                  </div>
                ) : null}

                {/* GST No */}
                <div className="d-inline-block me-2">
                  <span> GST No : </span>
                  <span className="px-1">{billTo?.gstNo}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillToShipToDetails;
